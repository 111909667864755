import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputHtml from '../components/html/InputHtml';

import * as Constants from './../store/index';
import * as Util from './../data/General';
import Axios from 'axios';
import Alert from '../components/html/Alert';
import SelectHtml from '../components/html/SelectHtml';
import RadioHtml from '../components/html/RadioHtml';
import UploadImages from '../components/html/UploadImages';
import Dinamics from './Dinamics';

class Preferences extends Component {

    state = { 
        shop: null, 
        shopEdit: false,
        errorText:"",
        newShop: {
            shopifyApiKey: "",
            myshopifyDomain: "",
            lastName: "",
            firstName: "",
            isValid: false,
            help: 3
        },
        shopAddresses: -1,
        isEditSelect : true,
        addressList:[],
        step: 0, 
        shopSelected: 0,
        successText: "",
        listShopName: null,
        isCreate: false,
        locationList:{},
        locationListSelect: 0,
        locationServer:null,
        locationTextUpdate: "",
        locationTextUpdateError: "",
        carriers: null,
        messageCarrier: "",
        messageCarrierError: ""
    };

    componentDidMount() {
        const { shopState, config, user } = this.props;
        if (shopState && shopState.length > 0){
            this.setState({
                shop: shopState[0],
                shopAddresses: config.addressesDefault,
                addressList: shopState[0].addresses
            });

        }
        if (shopState && shopState.length > 0 ){
            setTimeout(function(){
                Util.loadDataUsers(this.props.dispatch, false);

                setTimeout(function(){
                    this.addLogo();
                }.bind(this), 900);
                
            }.bind(this), 400);
        }
        if(shopState && shopState.length > 1){
            let listShopName = [];
            for (var i in shopState) {
                let element = shopState[i];
                listShopName[i]=element.name;
            }
            this.setState({listShopName: listShopName});
        }

        let linkages = localStorage.getItem("Linkages");
        if (linkages){
            let linkagesList =JSON.parse(linkages);
            let step = 0;
            if (shopState && shopState.length > 0){
                step = 1;
            }
            const { newShop } = this.state;
            newShop["myshopifyDomain"] = linkagesList.myshopifyDomain;
            newShop["shopifyApiKey"] = linkagesList.shopifyApiKey;
            newShop["firstName"] = user.profile.nickname;
            newShop["lastName"] = linkagesList.myshopifyDomain;
            newShop["help"] = 3;
            this.setState({
                newShop: newShop,
                step: 0,
                isCreate: true
            });
            localStorage.removeItem('Linkages');

            setTimeout(function(){
                this.handleCustomers();
            }.bind(this), 200);
            
        }

        setTimeout(function(){
            this.getLocations();
            Util.loadDataCarriers(shopState[0].id ,this.carriesOk);
          }.bind(this), 500);



    }

    carriesOk = (carriers) => {
        console.log(carriers);
        if (carriers){
            this.setState({carriers: carriers});
        }
    }

    handleChangeLocations = (id, name) => {
        const { locationServer } = this.state;
        if (locationServer[id].active){
            this.setState({ isSearch: true, locationListSelect: id});
            console.log(id);
            console.log(name);

            const { locationServer } = this.state;

            let selectLocation = locationServer[id];
            selectLocation.isDefault = true;

            const url = Constants.URL_API+"/shippings/locations/"+selectLocation.id;
            Axios.put(url, selectLocation, Constants.getHeaders()).then(response => response.data,error => {
                console.log(error.response);
                if (error.response && (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404)) {
                    this.setState({ errorText: Constants.getMessage(error.response.data.message), isSearch: false});
                }
                return error;
            }).then((data) => {
                console.log(data);
                if (data.isDefault){
                    this.setState({ isSearch: false, locationTextUpdate: "Su locación por defecto se actualizo correctamente", locationTextUpdateError: ""});

                    setTimeout(function(){
                        this.setState({ locationTextUpdate: ""});
                    }.bind(this), 6000);
                }else{
                    this.setState({ isSearch: false, locationTextUpdate: "Ocurrio un error"});
                }
            });
        }else{
            this.setState({ locationTextUpdateError: "Solo se puede seleccionar ubicaciones activas", locationTextUpdate: ""});

            setTimeout(function(){
                this.setState({ locationTextUpdateError: ""});
            }.bind(this), 6000);
        }
        return false;
    } 

    updateLocations = () => {
        this.setState({ isSearch: true, errorText: ""});
        const url = Constants.URL_API+"shippings/locations/sync";
        Axios.post(url, null, Constants.getHeaders()).then(response => response.data,error => {
            console.log(error.response);
            if (error.response && (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404)) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message), isSearch: false});
            }
            return error;
          }).then((data) => {
            console.log(data);
            setTimeout(function(){
                this.getLocations();
              }.bind(this), 100);
        });
        return false;
    }

    getLocations = () => {
        this.setState({ isSearch: true, errorText: ""});
        const url = Constants.URL_API+"shippings/locations";
        Axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            console.log(error.response);
            if (error.response && (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404)) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message), isSearch: false});
            }
            return error;
          }).then((data) => {
            console.log(data);
            let locations = {};
            let selected = 0;
            Object.keys(data).map((key)=>{
                console.log(key);
                let activeText = "Desactiva";
                if (data[key].active){
                    activeText = "Activa";
                }
                locations[key] = data[key].name + " (" +data[key].address1+ ") ["+activeText+"]";
                if(data[key].isDefault){
                    selected = key;
                }
            });
            this.setState({ isSearch: false, locationList: locations, locationListSelect: selected, locationServer:data});
        });
        return false;
    }


    addLogo = () => {
        const { shopState } = this.props;
        if (!shopState[0].logoUrl){
            let editShop = shopState[0];
            editShop.logoUrl=" ";
            Util.editShop(editShop, this.addLogoFinish, this.addLogoFinish);
        }
    }
    addLogoFinish = (data) => {
        console.log(data);
    } 
    
    addShop = () => {
        this.setState({
            step : 1,
        });
        Constants.scrollToWindow();
    }

    handleNew = () => {
        const { addressList } = this.state;
        let arrayAddress =  Array.from(addressList);
        console.log(arrayAddress);
        arrayAddress.push(Constants.addressNew);
        console.log(arrayAddress);
        console.log(arrayAddress.length - 1);
        this.setState({
            shopAddresses: (arrayAddress.length - 1),
            addressList: arrayAddress,
            shopEdit: true,
            isEditSelect : false,
            
        });
    }

    handleChange = (value, name) =>{
        const { shop } = this.state;
        let shopCopy = JSON.parse(JSON.stringify(shop));
        shopCopy[name] = value;
        this.setState({
            shop: shopCopy
        });
    }

    changeAddress = (value, name) =>{
        const { shopAddresses, addressList, shop } = this.state;
        if (parseInt(shopAddresses) !== -1){
            addressList[shopAddresses][name] = value;
        
            this.setState({
                addressList: addressList
            });
        }else{
            shop[name] = value;
        
            this.setState({
                shop: shop
            });
        }
    }

    handleNewShop = (value, name) =>{
        const { newShop } = this.state;
        newShop[name] = value;
        let valid = true;
        for (var i in newShop) {
            let element = newShop[i];
            if (element === ""){
                valid = false;
            }
        }
        newShop.isValid = valid;

        this.setState({
            newShop: newShop
        });
    }

    handleHelp = (value) =>{
        const { newShop } = this.state;
        newShop["help"] = value;
        this.setState({
            newShop: newShop
        });
    }
    handleCancel = () =>{
        const { shopState, config } = this.props;
        const { shopSelected } = this.state;
        console.log(shopState.addresses);
        this.setState({
            shop: shopState[shopSelected],
            shopEdit: false,
            isEditSelect: true,
            addressList: shopState[shopSelected].addresses,
            shopAddresses: config.addressesDefault,
        });
    }

    handleDelete = () =>{
        let { shopAddresses, shop, addressList } = this.state;
        const { config } = this.props;
        let shopAddress = [];
        Object.keys(addressList).map((key)=>{
            if(key !== shopAddresses){
                shopAddress.push(addressList[key]);
            }
        });
        console.log(shopAddress);

        this.setState({
            addressList: shopAddress,
            shopEdit: false,
            shopAddresses: config.addressesDefault,
        }, this.handleAcept);
    }

    handleAcept = () => {
        this.setState({ shopEdit: false, isSearch: true, isEditSelect: true});
        const { shop, addressList } = this.state;
        shop.addresses = addressList;
        /*this.props.dispatch({
            type: Constants.types.LOGIN_SHOP,
            data: shop
        });*/
        
        Util.editShop(shop, this.handleOK, this.handleError);
    }

    handleOK = (data) => {
        console.log(data);
        Constants.scrollToWindow();
        if (data.id){
            this.setState({ shop: data, isSearch: false, addressList: data.addresses, shopEdit: false, successText: "Tienda actualizadas correctamente"});
            Util.loadDataShop(this.props.dispatch, false);
        }else{
            this.setState({ errorText: data, isSearch: false});
        }
    }

    handleError = (text) => {
        this.setState({ errorText: text, isSearch: false});
    }

    handleCustomersShop = () => {
        this.setState({ isSearch: true, errorText: ""});
        const { newShop, step } = this.state;
        window.location="https://admin.triciclogo.com/shopify/?shop="+newShop.myshopifyDomain+"&url="+window.location.host;
    }

    handleCustomers = () => {
        this.setState({ isSearch: true, errorText: ""});
        const { newShop, step } = this.state;
        let url = Constants.URL_API+"customers";
        if (step === 1){
            url = Constants.URL_API+"customers/associate";
        }
        console.log(url);
        Constants.scrollToWindow();
        localStorage.removeItem('Linkages');
        let customers = {
            shopifyApiKey: newShop.shopifyApiKey,
	        shopifyPassword: "",
	        myshopifyDomain: newShop.myshopifyDomain + ".myshopify.com"
        }
        Axios.post(url, customers, Constants.getHeaders()).then(response => response.data,error => {
            //Constants.setErrors(error);
            console.log(error.response);
            /*if (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message), isSearch: false});
            }*/
            return error;
          }).then((data) => {
            console.log(data);
            if (data.id){
                let shop =[];
                shop.push(data);
                this.props.dispatch({
                    type: Constants.types.LOGIN_SHOP,
                    data: shop
                });
                this.props.dispatch({
                    type: Constants.types.LOGIN_TYPE,
                    data: data.type
                });
                if (step !== 1){
                    this.handleCreateUser(data, false); 
                } 
                setTimeout(function(){
                    //window.location="https://"+newShop.myshopifyDomain + ".myshopify.com/admin/apps";
                    window.location="/home";
                }.bind(this), 100);
            }
        });
        return false;
    }

    handleCreateUser = (data, isGoHome=true) => {
        this.setState({ isSearch: true, errorText: ""});
        const { newShop } = this.state;
        const { user } = this.props;
        const url = Constants.URL_API+"customers/users";
        console.log(url);
        Constants.scrollToWindow();
        let customers = {
            authUserId : user.profile.sub,
            email : user.profile.email,
            firstName : newShop.firstName,
            lastName : newShop.lastName,
            customerId : [
        		data.id
            ]
        }
        Axios.post(url, customers, Constants.getHeaders()).then(response => response.data,error => {
            console.log(error.response);
            if (error.response && (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404)) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message), isSearch: false});
            }
            return error;
          }).then((data) => {
            console.log(data);
            Constants.scrollToWindow();
            Util.loadDataUsers(this.props.dispatch, isGoHome);
        });
        return false;
    }

    handleChangePage = (page, name) => {

        this.handleChangeConfig(parseInt(page), name);
    } 
    handleChangeConfig = (value, key) => {
        this.setState({ isSearch: true});
        const { config } = this.props;
        config[key] = value;
        
        this.props.dispatch({
            type: 'CHANGE_CONFIG',
            config: config
        });
        this.setState({ isSearch: false});
    } 

    handleChangeAddress  = (value, name) =>{
        console.log(value);
        this.setState({
            shopAddresses: value
        });
    }

    handleChangeShop  = (value, name) =>{
        const { shopState } = this.props;
        console.log(value);
        this.setState({
            shop: shopState[value],
            addressList: shopState[value].addresses,
            shopSelected:value
        });
    }

    realodData = (data) => {
        if (data){
            const { shop } = this.state;
            shop.logoUrl = data.logoUrl;
            this.setState({shop: shop});
            Util.loadDataUsers(this.props.dispatch, false);  
        }
    }

    setDataCarriers = (carrierId, enabled, clean = false) => {
        const { carriers } = this.state;
        const { shopState } =this.props;

        let data = {};
        if (carriers[carrierId].carrierData && !clean){
            data = carriers[carrierId].carrierData;
        }

        let id = null;
        let visible = null;
        if (carriers[carrierId].id){
            id = carriers[carrierId].id;
            visible = carriers[carrierId].visible;
        }

        let body = {
            "id": id, // El id lo completo en BE concatenando customerId + carrierId
            "carrierId": carrierId,
            "customerId": shopState[0].id,
            "enabled": enabled, // Valor q indica si este cliente tiene habilitada la transportadora
            "carrierData": data, // Mandaremos tipo data los valores para cada transportadora, si no tiene transportadora propia mandar carrierData con null
          }

        if (enabled){
            body["visible"] = visible;
        }  
        const url = Constants.URL_API+"shippings/carriers/carrier-customer";
        console.log(url);
        Axios.post(url, body, Constants.getHeaders()).then(response => response.data,error => {
            //Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            const { carriers } = this.state;

            if (data && data.id){
                carriers[data.carrierId] = data;
                this.setState({carriers: carriers, messageCarrier: "Transportadora actualizada correctamente"});
            }else{
                this.setState({messageCarrierError: data});
            }

            setTimeout(function(){
                this.setState({messageCarrierError: "", messageCarrier: ""});
            }.bind(this), 3000);
        }); 
    }
     

    render() {
        const { shop, shopEdit,newShop, errorText, messageCarrier, messageCarrierError, shopAddresses, locationListSelect, locationTextUpdate, locationTextUpdateError, addressList, isEditSelect, step, successText, shopSelected, listShopName, isCreate, locationList, isSearch, carriers } = this.state;
        const { config, shopState } =  this.props;
        return (
            <div className="preferences orden">
                <div className="title">
                    <h1>
                        Preferencias
                    </h1>
                </div>
                <Alert errorText={errorText} successText={successText}/>
                {(shop && step === 0) ? (
                    <React.Fragment>
                        <div className="content-flex">
                            <div className="content-widget preferences-detail">
                                <h3>
                                    Tienda { shopSelected === 0 ? "Principal" : ""}
                                    {listShopName && (
                                        <SelectHtml 
                                            edit={true} 
                                            options={listShopName} 
                                            onChange={this.handleChangeShop} 
                                            name="shopSelected" 
                                            value={shopSelected} 
                                        />
                                    )} 
                                    <div style={{"float" : "right"}}>
                                        {!shopEdit ? (
                                            <div className="content-flex flex-end">
                                                <button onClick={() => this.setState({shopEdit: true, isEditSelect: false})} className="btn btn-secondary">Editar datos de la tienda</button>
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                <div className="content-flex flex-end">
                                                    <button onClick={() => this.handleCancel()} className="btn btn-link">Cancelar</button>
                                                    <button 
                                                        onClick={() => this.handleAcept()} 
                                                        disabled={(
                                                            (Util.getDataShop(shop, addressList, shopAddresses, "name") === "" || 
                                                            Util.getDataShop(shop, addressList, shopAddresses, "street1") === "" ||
                                                            Util.getDataShop(shop, addressList, shopAddresses, "city") === "" ||
                                                            Util.getDataShop(shop, addressList, shopAddresses, "zip") === "" ||
                                                            Util.getDataShop(shop, addressList, shopAddresses, "state") === "" ) ? true : false
                                                        )} 
                                                        className="btn triciclo">Guardar</button>
                                                </div>
                                            </React.Fragment>    
                                        )}
                                    </div>
                                </h3>
                                <hr></hr>
                                <h5>Detalles de la tienda Shopify</h5>
                                <div className="label">Nombre: <b>{shop.shopifyShopName}</b></div>
                                <div className="label">Dominio: <b><InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="shopifyDomain" value={shop.shopifyDomain}/></b></div>
                                <div className="label">Logo de la tienda:</div>
                                
                                <UploadImages value={shop.logoUrl} customerId={shop.id} upload={this.realodData}/>
                                <hr></hr>
                                <h5>Carta Porte <small>(Nuevo)</small></h5>
                                <div className="label">RFC: <InputHtml  type="text" edit={shopEdit} onBlur={this.handleChange} name="rfc" value={shop.rfc}/></div>
                                <div className="label">
                                    CLAVE SAT: 
                                    <InputHtml type="text"  edit={shopEdit} onBlur={this.handleChange} name="cartaPorteProductKey" value={shop.cartaPorteProductKey}/>
                                    <div><small>Escribe la CLAVE para todos o la mayoría de tus productos.</small></div>
                                    <a href="http://200.57.3.89/PyS/catPyS.aspx" title="Buscar" rel="noopener noreferrer" target="_blank">Buscar</a>
                                </div>
                                {(!shop.rfc || shop.rfc === "" ) && (!shop.cartaPorteProductKey || shop.cartaPorteProductKey === "") && (
                                    <div className="margin-1-0">
                                        <Alert warningText="Por favor ingrese su RFC y CLAVE SAT, para poder hacer envio que necesitan Carta Porte" />
                                    </div>
                                )}
                                <hr></hr>
                                <h5>Datos de envio</h5>
                                <div className="label">Título de la guia: <InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="titleGuide" value={shop.titleGuide}/></div>
                                <div className="label">Nombre de contacto: <InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="contactName" value={shop.contactName}/></div>
                                <div className="label">Ubicación predeterminada:
                                    <SelectHtml 
                                        edit={shopEdit} 
                                        options={locationList}
                                        onChange={this.handleChangeLocations} 
                                        name="location" 
                                        value={locationListSelect}
                                    />
                                </div>
                                {shopEdit && (
                                    <div className="content-flex flex-end">
                                        <button disabled={isSearch} onClick={() => this.updateLocations()} className="btn triciclo">Actualizar locaciones</button>
                                    </div>
                                )}
                                <div className="margin-1-0">
                                    <Alert successText={locationTextUpdate} errorText={locationTextUpdateError}/>
                                </div>
                                <hr></hr>
                                <h5>Direcciones</h5>
                                <div className="content-flex space-between direction-selected">
                                    <SelectHtml edit={isEditSelect} options={addressList} defaultText={`Principal: ${Util.getDataShop(shop, addressList, shopAddresses, "street1")}, ${Util.getDataShop(shop, addressList, shopAddresses, "city")}, ${Util.getDataShop(shop, addressList, shopAddresses, "zip")}  `} defaultValue={-1} required={true} onChange={this.handleChangeAddress} name="shopAddresses" value={shopAddresses} keyValue={Constants.addressSelect}/> 
                                    <button onClick={() => this.handleNew()} className="btn btn-success"><i className="icofont-direction-sign"></i> Nueva</button>
                                </div>
                                <div className="content-flex space-between">
                                    <div className="label">Nombre: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="name" value={Util.getDataShop(shop, addressList, shopAddresses, "name")}/></div>
                                    <button onClick={() => this.handleDelete()} disabled={(parseInt(shopAddresses) === -1 || !isEditSelect) ? true : false} className="btn btn-info"><i className="icofont-close"></i> Borrar</button>
                                </div>
                                <div className="label">Dirección: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="street1" value={Util.getDataShop(shop, addressList, shopAddresses, "street1")}/></div>
                                <div className="content-flex space-between">
                                    <div className="label">Colonia: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="street2" value={Util.getDataShop(shop, addressList, shopAddresses, "street2")}/></div>
                                    <div className="label">Teléfono: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="phone" value={Util.getDataShop(shop, addressList, shopAddresses, "phone")}/></div>
                                </div>
                                <div className="content-flex space-between">
                                    <div className="label">Ciudad: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="city" value={Util.getDataShop(shop, addressList, shopAddresses, "city")}/></div>
                                    <div className="label">Código postal: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="zip" value={Util.getDataShop(shop, addressList, shopAddresses, "zip")}/></div>
                                </div>
                                <div className="content-flex space-between">
                                    <div className="label">Estado: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="state" value={Util.getDataShop(shop, addressList, shopAddresses, "state")}/></div>
                                    <div className="label">País: <SelectHtml edit={shopEdit} options={Util.COUNTRY} required={true} onChange={this.changeAddress} name="countryCode" value={Util.getDataShop(shop, addressList, shopAddresses, "countryCode")}/></div>
                                </div>
                                <hr></hr>
                                <h5>Redes sociales</h5>    
                                <div className="label">Facebook: <b><InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="facebook" value={shop.facebook}/></b></div>
                                <div className="label">Instagram: <b><InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="instagram" value={shop.instagram}/></b></div>
                                <div className="label">Twitter: <b><InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="twitter" value={shop.twitter}/></b></div>
                                <div className="label">TikTok: <b><InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="tiktok" value={shop.tiktok}/></b></div>

                                <div style={{"float" : "right"}}>
                                        {!shopEdit ? (
                                            <div className="content-flex flex-end">
                                                <button onClick={() => this.setState({shopEdit: true, isEditSelect: false})} className="btn btn-secondary">Editar datos de la tienda</button>
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                <div className="content-flex flex-end">
                                                    <button onClick={() => this.handleCancel()} className="btn btn-link">Cancelar</button>
                                                    <button 
                                                        onClick={() => this.handleAcept()} 
                                                        disabled={(
                                                            (Util.getDataShop(shop, addressList, shopAddresses, "name") === "" || 
                                                            Util.getDataShop(shop, addressList, shopAddresses, "street1") === "" ||
                                                            Util.getDataShop(shop, addressList, shopAddresses, "city") === "" ||
                                                            Util.getDataShop(shop, addressList, shopAddresses, "zip") === "" ||
                                                            Util.getDataShop(shop, addressList, shopAddresses, "state") === "" ) ? true : false
                                                        )} 
                                                        className="btn triciclo">Guardar</button>
                                                </div>
                                            </React.Fragment>    
                                        )}
                                    </div>                            
                            </div>
                            <div className="content-widget client label">
                                <h3>Ejemplo de etiqueta</h3>
                                <hr></hr>
                                <div className="label-dhl-text">
                                    <div>{shop.titleGuide}</div>
                                    <div>{shop.contactName}</div>
                                    <div>{Util.getDataShop(shop, addressList, shopAddresses, "street1")}</div>
                                    <div>{Util.getDataShop(shop, addressList, shopAddresses, "zip")} {Util.getDataShop(shop, addressList, shopAddresses, "city")} {Util.getDataShop(shop, addressList, shopAddresses, "state")}</div>
                                    <div>{Util.getDataShop(shop, addressList, shopAddresses, "country")}</div>
                                </div>
                                <img className="label-dhl" alt="Ejemplo de etiqueta DHL" src="/img/dhl.png" /> 
                            </div>
                        </div> 
                        <div className="content-widget preferences-detail">
                            <h3>Configuración del sistema</h3>
                            <hr></hr>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            Elementos por pagina en ordenes:
                                        </td>
                                        <td>
                                            <SelectHtml 
                                                edit={true} 
                                                options={Util.pagination}
                                                onChange={this.handleChangePage} 
                                                name="sizeOrdens" 
                                                value={config.sizeOrdens}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Elementos por pagina en envios:
                                        </td>
                                        <td>
                                            <SelectHtml 
                                                edit={true} 
                                                options={Util.pagination}
                                                onChange={this.handleChangePage} 
                                                name="sizeshippings" 
                                                value={config.sizeshippings}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Usar peso de los productos:
                                        </td>
                                        <td>
                                            <RadioHtml 
                                                edit={true} 
                                                options={Util.yesNo}
                                                onChange={(e) => this.handleChangeConfig(e, "useWeight")} 
                                                name="useWeight" 
                                                value={config.useWeight}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Asegurar siempre la carga:
                                        </td>
                                        <td>
                                            <RadioHtml 
                                                edit={true} 
                                                options={Util.yesNo}
                                                onChange={(e) => this.handleChangeConfig(e, "insuredCargo")} 
                                                name="insuredCargo" 
                                                value={config.insuredCargo}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Luego de crear la orden:
                                        </td>
                                        <td>
                                            <RadioHtml 
                                                edit={true} 
                                                options={Util.pickupOptionsPreference}
                                                onChange={(e) => this.handleChangeConfig(e, "autoPickup")} 
                                                name="autoPickup" 
                                                value={config.autoPickup}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>   

                        {carriers && (
                            <div className="content-widget preferences-detail">
                                <h3>Configurar transportadora</h3>
                                <table>
                                        <tbody>
                                            {Object.keys(carriers).map((key, index) => (
                                                <React.Fragment>
                                                    <tr key={index}>
                                                        <td className="center">
                                                            <img src={`/img/${Util.CARRIERS_INFO[carriers[key].carrierId].img}`}></img>
                                                        </td>
                                                        <td>
                                                            <Dinamics 
                                                                listConfig={Util.CARRIERS_INFO[carriers[key].carrierId].config} 
                                                                edit={true} 
                                                                values={carriers[key].carrierData} 
                                                                onchange={this.editCarries}
                                                                keys={key}
                                                            /> 
                                                            <div className="content-flex flex-end spacing-small-top">
                                                                <button onClick={() => this.setDataCarriers(key, true, false)} className="btn triciclo">Guardar</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {(messageCarrierError !== "" || messageCarrier !== "") && (
                                                        <tr>
                                                            <td colSpan="2">
                                                                <Alert errorText={messageCarrierError} successText={messageCarrier}/>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                            </div>
                        )}
                        <div className="content-widget preferences-detail">
                            <h3>Tiendas conectadas</h3>
                            <hr></hr>
                            <table>
                                <thead>
                                    <tr>
                                       <th>
                                           Tienda
                                        </th>
                                        <th>
                                           Nombre
                                        </th>
                                        <th>
                                           URL
                                        </th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {shopState && shopState.length > 0 && shopState.map((shoping) => (
                                    <tr key={shoping.id}>
                                        <td>
                                            Shopify
                                        </td>
                                        <td>
                                            {shoping.shopifyShopName}
                                        </td>
                                        <td>
                                            {shoping.shopifyDomain}
                                        </td>  
                                        
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                            {/*<button 
                                onClick={() => this.addShop()} 
                                
                                className="btn triciclo right">
                                    Agregar tienda
                            </button>*/}
                        </div>   
                    </React.Fragment>    
                ) : (
                    <div className="content-flex">
                        <div className="content-widget preferences-detail">
                            <h3>{step === 0 ? "Crear tienda" : "Agregar tienda"}</h3>
                            <hr></hr>
                            
                            {newShop["shopifyApiKey"] !== "" && (
                                <Alert successText="Tu tienda en Triciclo GO esta vinculada correctamente con shopify"/>
                            )}
                            <p></p>
                            {isCreate ? (
                                <React.Fragment>
                                    <p></p>
                                    <div className="alert alert-success content-flex">
                                        <div className="preloaderHtml"></div>
                                        <p>Se esta creando la tienda en Triciclo GO, por favor espere unos segundos.</p>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Alert infoText="Para vincular tu tienda ingresa a la App Store e instala TricicloGO para completar la conexión con Shopify"/>
                                    <p></p>
                                    <a href="https://es.shopify.com/" title="Ir a shopify">Ir a shopify</a>
                                </React.Fragment>
                                
                            )}
                            
                        </div>
                        <div className="content-widget client label preferences-install-help">
                            <h3>Ayuda</h3>
                            <hr></hr> 
                            {newShop.help === 0 ? (
                                <div>
                                    <h5>Para generar la clave privada de la API, descarga el siguiente manual o por favor, siga los siguientes pasos:</h5>
                                    <div>
                                        <a href="/doc/TricicloGo - Guía de Integración con Shopify.pdf" target="_blank">
                                            <i className="icofont-book-alt"></i> Descargar manual
                                        </a>
                                    </div>    
                                    <p>1) Ingrese a la opción <b>Apps</b> del menú, de su tienda Shopify</p>
                                    <img src="/img/help/menu.png" />
                                    <p>2) Ingrese a la opción <b>Manage private apps</b></p>
                                    <img src="/img/help/manage.png" />
                                    <p>3) Complete los datos del formulario</p>
                                    <img src="/img/help/form.png" />
                                    <p>4) Conceda permiso de <b>lectura y escritura</b> a: </p>
                                    <ul>
                                        <li>Órdenes de cumplimiento asignadas (Assigned fulfillment orders)</li>
                                        <li>Clientes (Customers)</li>
                                        <li>Servicios de cumplimiento (Fulfillment services)</li>
                                        <li>Inventario (Inventory)</li>
                                        <li>Órdenes de cumplimiento gestionadas por comerciantes (Merchant-managed fulfillment orders)</li>
                                        <li>Pedidos (Orders)</li>
                                        <li>Listados de productos (Product listings)</li>
                                        <li>Productos (Products)</li>
                                        <li>Envío (Shipping)</li>
                                        <li>Órdenes de cumplimiento de terceros (Third-party fulfillment orders)</li>
                                    </ul>
                                    <p>5) Conceda permiso de <b>lectura</b> a: </p>
                                    <ul>
                                        <li>Ubicaciones (Locations)</li>
                                    </ul>
                                    <p>Para guardar los cambios debe hacer click en <b>Save</b></p>
                                    <img src="/img/help/permission.png" />
                                </div>
                            ) : newShop.help === 1 ? (
                                <div>
                                    <p>Copiar Shopify Api Key:</p>
                                    <img src="/img/help/help1.png" />
                                </div>
                            ) : newShop.help === 2 ? (
                                <div>
                                    <p>Copiar Shopify Password:</p>
                                    <img src="/img/help/help2.png" />
                                </div>
                            ) : newShop.help === 333 && newShop["shopifyApiKey"] === "" ? (
                                <div>
                                    <p>Copiar Dominio de Shopify:</p>
                                    <img src="/img/help/help3.png" />
                                </div>
                            ) : newShop.help === 3 && newShop["shopifyApiKey"] !== "" && (
                                <div>
                                    <p>Por favor solo ingrese su nombre y apellido para finalizar el proceso de creación de la tienda dentro de Triciclo GO</p>
                                </div>
                            )}   
                        </div>    
                    </div>
                )}
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    packageList: state.packageList,
    shopState: state.shop,
    user: state.user,
    ordens: state.ordens,
    shippings: state.shippings,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preferences);
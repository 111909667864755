import React, { Component } from 'react';
import  * as Util from './../../data/General';
import GetPackage from '../html/GetPackage';

class GetPackageShipping extends Component {

    state = {
        url: "",
        img: "",
        alt: "",
        name: "",
        packageLocal: null
    }
    componentDidMount() {
        const { shipping } = this.props;
        let { packageLocal } = this.state;
        let url = "";
        
        if (shipping.carrierId !== "LALAMOVE"){
            url = Util.CARRIERS_INFO[shipping.carrierId].url+shipping.tracker;
        }else{
            url = shipping.trackerUrl;
            if(shipping.deliveryType) {
                packageLocal = Util.LALAMOVE[shipping.deliveryType];
            }
        }
        let name = Util.CARRIERS_INFO[shipping.carrierId].name_show ? Util.CARRIERS_INFO[shipping.carrierId].name_show : Util.CARRIERS_INFO[shipping.carrierId].name;
        this.setState({
            url: url,
            img: "/img/"+ Util.CARRIERS_INFO[shipping.carrierId].img,
            alt: name,
            name: name,
            packageLocal: packageLocal
        });
    }

    render() {
        const { shipping } = this.props;
        const { url, img, alt, name, packageLocal } = this.state;

        if(shipping){
            return (
                <React.Fragment>
                    <img 
                        src={img} 
                        alt={alt} 
                        className="logo-carrier"
                    />&nbsp;
                    {name}
                    <div className="destination carrier">
                        <a href={url}  target="_blank">
                            {shipping.tracker}
                        </a>
                    </div>
                    <div className="destination carrier">
                        {shipping.package && (
                            <GetPackage packageGet={shipping.package}/> 
                        )}
                        {packageLocal ? packageLocal : ""}
                    </div>
                </React.Fragment>     
            );
        }else{
            return (<span></span>);
        }
  }
}

export default GetPackageShipping;
import React, { Component } from 'react';

class Alert extends Component {

    render() {
        const { errorText, successText, warningText, infoText, link, linkText, errorMessage, warningTextWOA, className=""} = this.props;

        return (
            <React.Fragment>
                {errorText && errorText !== "" && (
                    <div className="alert alert-danger">
                        <div><strong><i className="icofont-close-squared-alt"></i> Ocurrio un error:</strong> {errorText}</div>
                    </div>
                )}
                {successText && successText !== "" && (
                    <div className={"alert alert-success "+className}>
                        <div><strong><i className="icofont-checked"></i> Exito:</strong> {successText}</div>
                    </div>
                )}
                {warningText && warningText !== "" && (
                    <div className="alert alert-warning">
                        <div><strong><i className="icofont-checked"></i> Advertencia:</strong> {warningText}</div>
                    </div>
                )}
                {warningTextWOA && warningTextWOA !== "" && (
                    <div className="alert alert-warning">
                        <div>{warningTextWOA}</div>
                    </div>
                )}
                {infoText && infoText !== "" && (
                    <div className="alert alert-warning">
                        <div><strong><i className="icofont-checked"></i> </strong>  {infoText} <a href={link} target="_blank">{linkText}</a></div>
                    </div>
                )}
                {errorMessage && errorMessage !== "" && (
                    <div className="alert alert-danger">
                        <div>{errorMessage}</div>
                    </div>
                )}
            </React.Fragment>     
        );
  }
}

export default Alert;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from './../store/index';
import GetPackageShipping from '../components/widget/GetPackageShipping';
import { Link  } from 'react-router-dom';
import Page from '../components/html/Page';
import GetPackage from '../components/html/GetPackage';


class Print extends Component {

    state = {
        isSearch: false,
        isScroll: false,
        url: null
    };

    componentDidMount() {
        let url = window.location.pathname.replace("/print/", "");
        if ("multi-order"=== url){
            this.setState({url: "/multi-print"});
        }else if ("accuse"=== url){
            this.setState({url: "/accuse-print"});
        }else if ("order-accuse"=== url){
            this.setState({url: "/order-accuse-print"});
        }else{
            this.setState({url: "/"+url+window.location.search});
        }
    }
    
  
    render() {
        const { pickups, today, yesterday, lastWeek, lastMonth, shop } = this.props;
        const { url } = this.state;
        return (
            <div className="shippings pickups">
                <h1>Impresión <a href="/shippings" className="btn triciclo top-middle right"><i className="icofont-reply"></i> Volver</a></h1>
                <div className="content-widget">
                    {url && (
                        <iframe src={url} className="iframe-print"></iframe>
                    )}
                </div>
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    pickups: state.pickups,
    today: state.today, 
    yesterday: state.yesterday, 
    lastWeek: state.lastWeek, 
    lastMonth: state.lastMonth,
    shop: state.shop
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

// `connect` returns a new function that accepts the component to wrap:

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Print);

import React, { Component } from 'react';

import { connect } from 'react-redux';


class Avatar extends Component {

    state = { name: ""};

    componentDidMount() {
        const { nameUser } =this.props;
        let nameSplit = String(nameUser).toUpperCase().split(' ');
        let initials = "";
        if (nameSplit.length == 1) {
            initials = nameSplit[0] ? nameSplit[0].charAt(0):'?';
        } else {
            initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
        }
        
        
        
        this.setState({
            value: initials
        });
        
    }

    render() {
        const { value } = this.state;

        return (
            <span className="avatar">
                {/*<svg className="_1MSGQ" viewBox="0 0 40 40">
                    <text x="50%" y="50%" dy="0.35em" fill="currentColor" style={{fontSize:"20px",textAnchor:"middle"}}>
                        {value}
                    </text>
                </svg>*/}
                <svg width="48px" height="53px" viewBox="0 0 48 53">
                    <path d="M37.974,52.958 L9.997,52.958 C2.468,52.958 -2.11,44.621 2.206,38.449 C5.368,33.821 10.1,30.257 15.438,28.403 C17.949,29.862 20.864,30.712 23.986,30.712 C27.108,30.712 30.22,29.862 32.533,28.403 C37.970,30.257 42.603,33.821 45.765,38.449 C49.983,44.621 45.503,52.958 37.974,52.958 ZM23.986,26.967 C16.725,26.967 10.838,21.141 10.838,13.955 C10.838,6.768 16.725,0.943 23.986,0.943 C31.246,0.943 37.132,6.768 37.132,13.955 C37.132,21.141 31.246,26.967 23.986,26.967 Z"/>
                </svg>
            </span>
        );
  }
}

const mapStateToProps = (state) => ({
    nameUser: state.user.profile.nickname,
  });
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Avatar);
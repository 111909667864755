import React, { Component } from 'react';


class RadioHtml extends Component {
    state = {value: ""};

    componentDidMount() {
        const { value } = this.props;
        this.setState({value: value});
    }

    handleClick = (value) => {
        const { onChange } = this.props;
        
        if (onChange){
            onChange(value);
        }
        this.setState({value: value});
    }
  
    render() {
        const { edit, options, name } = this.props;
        const { value } = this.state;
        console.log(value);
        return (
            <div>
                {edit ? (
                    <div>
                        {options && Object.keys(options).map(key =>
                            <React.Fragment>
                                <div className="form-check" key={key}>
                                    <input className="form-check-input" type="radio" name={name} 
                                        id={options[key]} onClick={() => this.handleClick(key)} 
                                        value={key} checked={(value === key)}
                                    />
                                    <label className="form-check-label" htmlFor={options[key]}>
                                        {options[key]}
                                    </label>    
                                </div>   
                            </React.Fragment>     
                        )}
                    </div> 
            ) : (
                <div>
                    <label className="mode-edit">{options[value]}</label>  
                </div>     
            )}  
            </div>
        );
  }
}

export default RadioHtml;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from '../store/index';
import Page from '../components/html/Page';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    console.log(result);
    return result;
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
      
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#fff",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });

class Stages extends Component {

    state = {
        isSearch: true,
        url: "",
        filter: {
            from: 0,
            size: 10,
        },
        listRecharge: {},
        isScroll: false,
        items:[]
    };

    changeAmount = (value) =>{
        this.setState({ url: value});
    };   
    
    componentDidMount() {
        const { filter } = this.state;
        const { shop, stages } = this.props;
        this.loadData(filter);
        this.setState({items: stages.list});
    }

    goTo = (from) => {
        const { filter } = this.state;
        filter.from = from;
        this.loadData(filter);
    }

    handleChangePage = (page) => {
        const { filter } = this.state;
        filter.size = page;
        filter.from = 0;
        this.loadData(filter);
        return false;  
    }  

    loadData = (filter) => {
        this.setState({ isSearch: true});
        const { shop } = this.props;
        const url = Constants.URL_API+"orders/stages/"+Constants.getFilter(filter);
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            
            data.sort(function (a, b) {
                if (a.index > b.index) {
                  return 1;
                }
                if (a.index < b.index) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });

            this.props.dispatch({
                type: Constants.types.CLICK_STAGES,
                list: data
            });
            this.setState({isSearch: false, items: data});
        }); 
    }

    edit = (name) => {
        window.location.href = "/stage/"+name;
    }

    new = () => {
        window.location.href = "/stage-new/";
    }

    delete = (id) => {
        this.setState({ isSearch: true});
        const url = Constants.URL_API+"orders/stages/"+id;
        console.log(url);
        Constants.scrollToWindow();
        axios.delete(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            const { filter } = this.state;
            filter.size = 1;
            filter.from = 0;
            this.loadData(filter);
        }); 
    }

    handleChangeCheckbox = (name, isMail, stage) =>{
        if (isMail){
            stage.emailData[name] = !stage.emailData[name];
        }else{
            stage[name] = !stage[name];
        }
        this.save(stage);
    }

    save = (stage) => {
        console.log(stage);
        this.setState({isSearch : true});

        const url = Constants.URL_API+"orders/stages/"+stage.id;
        
        axios.put(url, stage, Constants.getHeaders()).then(response => response.data, error => {
            Constants.setErrors(error);
            this.setState({isSearch : false, message: error});
            return error;
        }).then((data) => {
            const { filter } = this.state;
            this.loadData(filter);
        });
    }

    orderItems = () => {
        let items = JSON.parse(JSON.stringify(this.state.items)); 
        var finalItems = JSON.parse(JSON.stringify(this.state.items)); 
        var i;
        for (i = 1; i < items.length-1 ; i++) {
            finalItems[i] = items[this.state.newWaypointOrder[i-1]+1]
        }
        this.setState({
            items : finalItems,
            isSearch: false,
            isOptimized : true
        })
    };

    handleChangeDestination(result) {
        const items = this.state.items
        if ((result.source.index == items.length-1) || (result.destination.index == items.length-1) ) {
                this.optimizeStops()
        }else {
            this.setState ({
                isOptimized: false
            })
        };
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          this.state.items,
          result.source.index,
          result.destination.index
        );
    
        this.setState({
          items, 
          step: 0
        });
        
        /*setTimeout(function(){
            if (items.length > 3) {
                this.handleChangeDestination(result)
            }
        }.bind(this), 500);]*/ 
    }

    saveNew = () =>{
        this.setState({isSearch : true});
        const { items } = this.state;
        let count = 0;
        items.map((item, index) => {
            if (index+1 !== item.index){
                //alert("cambiar el: "+(index+1));
                this.saveStage(item, (index+1));
                count++;
            }
        })
        if (count > 0){
            setTimeout(function(){
                window.location.reload();
                //alert("Reload");
            }.bind(this), (500*count));
        }else{
            this.setState({isSearch : false});
        }
    }

    saveStage = (stage, newIndex) => {
        
        
        stage.index = newIndex;
        
        const url = Constants.URL_API+"orders/stages/"+stage.id;
        axios.put(url, stage, Constants.getHeaders()).then(response => response.data, error => {
            Constants.setErrors(error);
            this.setState({isSearch : false, message: error});
            return error;
        }).then((data) => {
            if (data.id){
                console.log("correcto: "+ data.id);
            }
        });
    }


  
    render() {
        const { stages } = this.props;
        const { listRecharge, filter, isScroll, isSearch, items } = this.state;

        console.log(items);

        return (
            <div className="recharge orden">
                <div className="title content-flex space-between">
                    <h1>
                        Estados del pedido 
                    </h1>
                    <button 
                            onClick={() => this.new()} 
                            className="btn triciclo top-middle right" 
                            disabled={!isSearch ? false : true}
                            >
                            <i className="icofont-bag"></i>&nbsp;
                            Nuevo
                        </button>
                </div>
                <div className="content-flex">
                    <div className="content-widget">
                        <div>
                            <div className={`scroll-table ${isScroll ? "active" : ""}`} id="scroll-table">
                                <table>
                                    {isSearch && ( 
                                        <div className="center content-loading">
                                            <div className="loading">
                                                <div id="preloader">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div> 
                                                <span>Cargando Estados</span>
                                            </div>    
                                        </div>
                                    )}
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nombre</th>
                                            <th>Icono</th>
                                            <th>Estado</th>
                                            <th>Se envia correo</th>
                                            <th>Asunto</th>
                                            <th>Editar</th>
                                            <th>Borrar</th>
                                        </tr>    
                                    </thead>
                                    <tbody>
                                        {stages && stages.list.length > 0 && stages.list.map((stage, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {stage.index}
                                                </td>
                                                <td>
                                                    {stage.name}
                                                </td>
                                                <td className="nowrap icon-td" >
                                                    <img src={stage.icon} />
                                                </td>
                                                <td>
                                                    <label className="switchBtn">
                                                        <input 
                                                            type="checkbox" 
                                                            checked={stage.active} 
                                                            onChange={() => this.handleChangeCheckbox("active", false, stage)} 
                                                        />
                                                        <div className="slide round">
                                                            <span className="active">Activo</span>
                                                            <span className="disabled">Desactivo</span>
                                                        </div>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="switchBtn">
                                                        <input 
                                                            type="checkbox" 
                                                            checked={stage.emailData.send} 
                                                            onChange={() => this.handleChangeCheckbox("send", true, stage)} 
                                                        />
                                                        <div className="slide round">
                                                            <span className="active">Si</span>
                                                            <span className="disabled">No</span>
                                                        </div>
                                                    </label>
                                                </td>
                                                <td >
                                                    {stage.emailData.subject}
                                                </td>
                                                <td>
                                                <button 
                                                    onClick={() => this.edit(stage.id)} 
                                                    className="btn triciclo top-middle" 
                                                    disabled={!isSearch ? false : true}
                                                    >
                                                    <i className="icofont-ui-edit"></i>
                                                </button>
                                                </td>
                                                <td >
                                                <button 
                                                    onClick={() => this.delete(stage.id)} 
                                                    className="btn top-middle" 
                                                    disabled={!isSearch ? false : true}
                                                    >
                                                    <i className="icofont-ui-delete"></i>
                                                </button>
                                                </td>  
                                            </tr>
                                        ))}

                                
                                    </tbody>
                                </table>
                            </div>
                            <hr></hr>
                            <Page from={filter.from} size={filter.size ? filter.size : 0} elementSize={listRecharge.length} handleClick={this.goTo} handleChangePage={this.handleChangePage} />
                           
                        </div>
                    </div>
                    <div className="content-widget" style={{"margin-left": "0.5rem"}}>
                        <p>Orden de los estados en los correos</p>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                title="Arrastra para reordenar"
                                className="items-icon"
                                >
                                {items.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            >
                                                <div className="item-order">
                                                    <div className="address">
                                                        {item.index}: {item.name}
                                                    </div>
                                                    {item.icon && (
                                                        <img src={item.icon} />
                                                    )}
                                                    {index+1 !== item.index && (
                                                        <div className="number">
                                                            Nuevo indece: {index+1}
                                                        </div>
                                                    )}
                                                    <hr></hr>
                                                    
                                                </div>

                                            </div>
                                        )}
                                    </Draggable>
                                        
                                ))}
                                <button 
                                    onClick={() => this.saveNew()} 
                                    className="btn triciclo top-middle" 
                                    disabled={!isSearch ? false : true}
                                    >
                                    Guardar
                                </button>
                                {provided.placeholder}
                                </div>
                            )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>    
            </div>
        );
        }
    }
const mapStateToProps = (state) => ({
    shop: state.shop,
    stages: state.stages
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stages);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from '../store/index';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

class Stage extends Component {

    state = {
        isSearch: true,
        edit: true,
        stage: {
            "id":"",
            "name":"",
            "active":true,
            "emailData":{
                "send":true,
                "subject":"Felicitaciones {{customer.firstname}}, estamos preparando tu pedido",
                "body":'<h1 style="text-align: center;">¡Gracias por tu pedido!</h1><h4 style="text-align: center;">Hola&nbsp;{{customer.firstname}}&nbsp;{{customer.lastname}}</h4><div class="se-component se-image-container __se__float-center" contenteditable="false" style="width: 50%; min-width: 100%;"><figure style="margin: auto; width: 50%;"><img src="https://cdn.shopify.com/s/files/1/0229/0839/files/international-shipping_1.jpg?v=1584107700" alt="Envio" data-rotate="" data-proportion="true" data-size="50%," data-align="center" data-percentage="50," data-file-name="international-shipping_1.jpg?v=1584107700" data-file-size="0" data-origin="," style="width: 100%;" data-index="0"></figure></div><h3 style="text-align: center;">Recibimos tu pedido y ya estamos preparando tu envío.</h3><h3 style="text-align: center;">Recibirás un correo con los datos de tu guía en cuanta sea enviando.</h3><p style="text-align: center;"><br></p><p><br></p><p style="text-align: center;">Atentamente</p><p style="text-align: center;">{{shop.name}}</p>'
            }
        },
        listCode:[
            {key: "{{customer.firstname}}", value: "Nombre del cliente"},
            {key: "{{customer.lastname}}", value: "Apellido del cliente"},
            {key: "{{order.name}}", value: "Nombre de la orden"},
            {key: "{{shop.name}}", value: "Nombre de la tienda"},
        ],
        textCopy: "",
        message:"",
    };

    changeAmount = (value) =>{
        this.setState({ url: value});
    };   
    
    componentDidMount() {
        const { type } = this.props;
        if (type === "old"){
            var idStage = window.location.pathname.replace("/stage/", "");
            idStage = idStage.replace("%20", " ");
            this.loadData(idStage);
        }else{
            this.setState({ isSearch: false});
        }

        document.addEventListener('copy', this.copy);
    }

    copy = () => {
        var selection = window.getSelection().toString();
        this.setState({textCopy: selection});
    }
  

    loadData = (id) => {
        this.setState({ isSearch: true});
        const url = Constants.URL_API+"orders/stages/"+id;
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            this.setState({ isSearch: false, stage: data});
        }); 
    }

    edit = (name) => {

    }

    delete = (name) => {
        
    }

    handleChangeText = (content) => {
        console.log(content); //Get Content Inside Editor
        const { stage } = this.state; 
        stage.emailData.body = content; 
        this.setState({stage: stage});
    }

    handleCopy = (copy) => {
        var aux = document.createElement("input");
        aux.setAttribute("value", copy);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }
  
    handleChange = (value, name) =>{
        const { stage } = this.state;
        stage.emailData[name] = value;
        this.setState({
            stage: stage
        });
    }

    handleChangeVar = (value, name) =>{
        const { stage } = this.state;
        stage[name] = value;
        this.setState({
            stage: stage
        });
    }

    handleChangeCheckbox = (name, isMail) =>{
        const { stage } = this.state;
        if (isMail){
            stage.emailData[name] = !stage.emailData[name];
        }else{
            stage[name] = !stage[name];
        }
        this.setState({
            stage: stage
        });
    }

    cancel = () => {
        window.location.href = "/order-stage";
    }

    save = () => {
        const { stage } = this.state;
        this.setState({isSearch : true});

        stage.emailData.body = stage.emailData.body.replace(/"/g, "'");; 
        
        const url = Constants.URL_API+"orders/stages/"+stage.id;
        if (stage.id === ""){
            axios.post(url, stage, Constants.getHeaders()).then(response => response.data, error => {
                Constants.setErrors(error);
                return error;
            }).then((data) => {
                this.saveCallback(data);
            });
        }else{
            axios.put(url, stage, Constants.getHeaders()).then(response => response.data, error => {
                Constants.setErrors(error);
                this.setState({isSearch : false, message: error});
                return error;
            }).then((data) => {
                this.saveCallback(data);
            });
        }
    }

    saveCallback = (data) => {
        const { stage } = this.state;
        console.log(data);  
        if (data.id){
            let messageText = "El estado quedo guardado correctamente";
            if (stage.id === ""){
                messageText = "El estado se creo correctamente";
            }
            this.setState({isSearch : false, message: messageText});
        }else{
            this.setState({isSearch : false, message: "Ocurrio un error"});
        }
        Constants.scrollToWindow();
    }

    render() {
        const { isSearch, stage } = this.state;

        return (
            
            <div className="label">
                <div className="label">
                    Correo: 
                    {!isSearch ? (
                        <SunEditor 
                            onChange={this.handleChangeText}
                            setOptions={{
                                height: 600,
                                buttonList: [
                                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                    ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                                ]
                            }}
                            defaultValue={stage.emailData.body.replace(/'/g, '"')}
                        />
                    ): (
                        <div className="center content-loading">
                            <div className="loading">
                                <div id="preloader">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div> 
                                <span>Cargando Datos</span>
                            </div>    
                        </div>
                    )}
                </div>
            </div>
        );
        }
    }
const mapStateToProps = (state) => ({
    shop: state.shop,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stage);

import React, { Component } from 'react';
import GetName from './GetName';


class GetShipping extends Component {

    render() {
        const { customer } = this.props;

        return (
            <React.Fragment>
                {customer && (
                    <React.Fragment>
                        <i className="icofont-google-map"></i><GetName customer={customer} />
                        {customer.street ? (
                            <div className="destination">
                                {customer.street} {customer.number}
                            </div>
                        ) : (
                            <div className="destination">
                                {customer.address1}
                            </div>
                        )}
                        <div className="destination">
                            {customer.city} {customer.zipCode ? customer.zipCode : customer.zip}
                        </div>
                    </React.Fragment>    
                )} 
            </React.Fragment>     
        );
  }
}

export default GetShipping;
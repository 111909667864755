import React, { Component } from 'react';
import GetPackageShipping from '../widget/GetPackageShipping';
import GetCost from './GetCost';

class ShippingOrder extends Component {
    
  
    render() {
        const { shipping, Constants } = this.props;
        return (
            <React.Fragment>
                <div>
                    <i className="icofont-calendar"></i> <span>{Constants.getDate(shipping.createDate)}</span>
                </div>
                {Constants.status[shipping.status] ? (
                        <div><i className={Constants.status_icon[shipping.status]}></i> <span>{Constants.status[shipping.status]}</span></div>
                    ) : <div><i className="icofont-clock-time"></i> <span>Sin Estado</span></div>
                }
                {shipping.driver && (
                    <div>
                        <i className="icofont-motor-biker"></i>
                        {shipping.driver.name ? 
                            <React.Fragment> 
                                <span>{shipping.driver.name}</span> 
                                &nbsp;-&nbsp;<i className="icofont-phone-circle"></i> 
                                <span><a href={`tel:${shipping.driver.phone}`}>{shipping.driver.phone}</a></span>
                            </React.Fragment>
                            :
                            <span>Sin conductor asignado</span>
                        }
                    </div>    
                )}
                {shipping.status !== "CANCELED" && (
                    <GetPackageShipping shipping={shipping} />
                )}
                <div className="label">
                    <GetCost shipping={shipping}  withText={true} />
                </div>
            </React.Fragment>    
        );
  }
}

export default ShippingOrder;
import React, { Component } from 'react';
import GetName from './GetName';
import CurrencyFormat from 'react-currency-format';


class GetCost extends Component {

    render() {
        const { shipping , withText  } = this.props;

        return (
            <React.Fragment>
                {shipping && (
                    <React.Fragment>
                        {shipping.total ? (
                            <div >
                                {withText ? (
                                    <div>
                                       <i className="icofont-money"></i>    <CurrencyFormat value={shipping.total}  displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </div> 
                                ) : (
                                    <div>
                                        <CurrencyFormat value={shipping.total}  displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div >
                                -
                            </div>
                        )}
                    </React.Fragment>    
                )} 
            </React.Fragment>   
        );
  }
}

export default GetCost;
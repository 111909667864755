import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from '../components/html/Alert';

class ReturnShopify extends Component {

    state = {
        code: "",
        textCopy: ""
    };

    componentDidMount() {
        const { shopState } = this.props;
        this.setState({code: "<iframe src=\"https://returns.triciclogo.com/view/?customer="+shopState[0].id+"\" title=\"Devoluciones\" width=\"100%\" height=\"500px\" style=\"border: 1px solid #ccc;border-radius: 5px;\"><p>Your browser does not support iframes.</p></iframe>"});
        document.addEventListener('copy', this.copy);
    }

    copy = () => {
        var selection = window.getSelection().toString();
        this.setState({textCopy: selection});
    }

    handleCopy = (copy) => {
        var aux = document.createElement("input");
        aux.setAttribute("value", copy);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }

   
  
    render() {
        const { code, textCopy } = this.state;
        return (
            <div className="orden returns-shopify">
                <div className="content-flex">
                    <div className="content-widget">
                        <h1>Crear página de devoluciones</h1>
                        <Alert infoText="Siga los siguientes pasos para crear una pagina donde tus clientes puedan administrar sus devoluciones" />

                        <h3>1) Cree una nueva página </h3>
                        <img src="img/shopify/1.png" className="img-return-shopify" />
                        <p>Ingresen <b>CANALES DE VENTA</b> - <b>Tienda online</b> - <b>Páginas</b></p>
                        <img src="img/shopify/2.png" className="img-return-shopify" />
                        <p>Presione Agregar página</p>

                        <h3>2) Ingrese un título y presione <b>Mostrar HTML</b> </h3>
                        <img src="img/shopify/3.png" className="img-return-shopify" />

                        <h3>3) Pegar el siguiente código en <b>Contenido</b>  </h3>
                        <pre className="pre-shopify">
                            {code}
                        </pre>  
                        {textCopy !== "" && (
                            <div className="help-copy">
                                Código copiado al portapapeles
                            </div>
                        )} 
                        <button 
                            onClick={() => this.handleCopy(code)}
                            className="btn triciclo"
                        >
                            <i className="icofont-copy"></i> Copiar
                        </button>  
                        <h3>4) Presione <b>Guardar</b>  </h3>      
                        <img src="img/shopify/4.png" className="img-return-shopify" />  
                        <h3>5) Revisar que todo quedo OK!  </h3> 
                        <img src="img/shopify/5.png" className="img-return-shopify" />  
                        <p>Ingrese en el link "Ver en tu tienda online"</p>
                    </div>
                </div>
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    shopState: state.shop,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnShopify);

import React, { Component } from 'react';
import * as Constants from './../../store/index';
import Axios from 'axios';


class UploadImages extends Component {

    state = { 
        selectedFile: null, 
        dataImageNew : null,
        isSearch: false
    };

    componentDidMount() {
        
    }

    // On file select (from the pop up)
    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });

        var reader = new FileReader();
        reader.onload = function (e) {
            this.setState({dataImageNew : e.target.result});
        }.bind(this);
        reader.readAsDataURL(event.target.files[0]);
      
      };
      
      // On file upload (click the upload button)
      onFileUpload = () => {
        const { customerId, upload } = this.props;
        this.setState({isSearch: true});
        // Create an object of formData
        const formData = new FormData();
      
        // Update the formData object
        formData.append(
            "file",
            this.state.selectedFile,
            this.state.selectedFile.name,        
        );
      
        // Details of the uploaded file
        //console.log(this.state.selectedFile);
      
        // Request made to the backend api
        // Send formData object
        const url = Constants.URL_API+"customers/"+ customerId + "/logo";
        //Axios.post(url, formData);

        Axios.post(url, formData, Constants.getHeaders()).then(response => response.data,error => {
            console.log(error.response);
            if (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message), isSearch: false});
            }
            return error;
          }).then((data) => {
            //console.log(data);    
            if (upload){
                upload(data);
            }      
            setTimeout(function(){
                this.setState({isSearch: false, selectedFile: null, dataImageNew : null});
              }.bind(this), 500);
            
        });
      };

    render() {
        const { selectedFile, dataImageNew, isSearch } = this.state;
        const { value } = this.props;

        return (
            <div className="content-upload-image">
                {!isSearch ? (
                    <div className="content-flex flex-center-left">
                        
                        {(!value || value === "" || value === " ") ? (
                            <img className={`img ${!selectedFile && "no-border"}`} alt="default" src="/img/default_logo.png" />
                        ) : (
                            <img className={`img ${!selectedFile && "no-border"}`} alt="Logo" src={value + "?time="+Math.random()} />
                        )}

                        {dataImageNew && (
                            <React.Fragment>
                                <img className="img" src="/img/change.jpg" alt="Change"/>
                                <img className="img" src={dataImageNew} alt="Logo"/>
                            </React.Fragment>
                        )}

                        {!selectedFile && (
                            <div>
                                <label for="file" className="btn triciclo">
                                    Elegir archivo
                                </label>
                                <input type="file" id="file" style={{"display": "none"}} multiple accept="image/*" onChange={this.onFileChange}/>
                            </div>
                        )}
                    </div> 
                ): (
                    <div className="center content-loading">
                        <div className="loading">
                            <div id="preloader">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div> 
                            <span>Actualizando logo</span>
                        </div>    
                    </div>
                )}
                {selectedFile && ( 
                    <div className="button-center">
                        <button disabled={(isSearch) ? true : false} className="btn triciclo" onClick={() => this.onFileUpload()}>
                            Actualizar el logo de la tienda
                        </button>
                    </div>
                )}
            </div>
        );
  }
}

export default UploadImages;
import React, { Component } from 'react';



class InputHtml extends Component {

    state = { mError: "", isFocus: false };

    componentDidMount() {
        const { value } = this.props;
        this.onValid(value);
    }

    handleInputFocus = (e) => {
        this.setState({
            isFocus: true
        });
    }
    handleOnBlur = (e) => {

        const { update, name } = this.props;
        this.setState({
            isFocus: false
        });
        if (update){
            update(name);
        }
    }
    onChange = (evt, pattern) => {
        const { onBlur, name, preword, notWeekend } = this.props;
        let value = evt.target.value;
        if(pattern){
            value = (evt.target.validity.valid) ? evt.target.value : this.state.value;
        }
        if (preword){
            value = preword + value;
        }
        this.onValid(value);
        let isDayValid = true;
        if (notWeekend){
            let day = new Date(value).getUTCDay();
            if([6,0].includes(day)){
                this.setState({
                    mError: "Fines de semana no permitidos"
                });
                isDayValid = false;
            }
        }
        
        if (onBlur && isDayValid){
            onBlur(value, name);
        }
        
    }
    onValid = (value) => {
        const { required, maxlength } = this.props;
        const { mError } = this.state;
        if (required && value === ""){
            this.setState({
                mError: "Este campo no puede estar vacio"
            });
        }else if (value && maxlength && value.length > maxlength){
            this.setState({
                mError: "Supera por "+(value.length - maxlength)+" caracteres, el máximo permitido de "+maxlength+" caracteres."
            });
        } else if (mError !== ""){
            this.setState({
                mError: ""
            });
        }
    }
    
  
    render() {
        const { pre, type, readonly, placeholder, max, pattern, post, edit, value, className, min, onClick, placeholderEmpty } = this.props;
        const {mError} = this.state;
        return (
            <React.Fragment>
                <div className={` ${(!edit) && 'readonly'} ${className && className}`}>
                    {pre && (
                        <div className="input-group-prepend">
                            <span className="input-group-text">{pre}</span>
                        </div>
                    )}
                    {edit ? (
                        <input className="input-form" type={type} 
                        value={value} onChange={e => this.onChange(e, pattern)} 
                        onBlur={this.handleOnBlur} onFocus={this.handleInputFocus} onClick={onClick} readOnly={readonly ? true : false} 
                        placeholder={placeholder} 
                        max={max ? max : ''}
                        min={min ? min : ''}
                        pattern={pattern ? pattern : ''}/>
                    ) : (
                        <span className="text">{(value && value !== "") ? value : placeholderEmpty}</span>
                    )}
                    {post && (
                        <div className="input-group-append">
                            <span className="input-group-text">{post}</span>
                        </div>
                    )}
                    {(mError !== "") && (<div className="error">{mError}</div>)}
                </div>    
            </React.Fragment>     
        );
  }
}

export default InputHtml;
import React, { Component } from 'react';
import * as Constants from '../../store/index';

class ShowCancelButton extends Component {
   
    cancelShip = (shipping) => {
        const {cancelShippingCallback} = this.props;
        if(window.confirm('¿Confirma la cancelacion la orden ' + shipping.orderName +'?')){
            cancelShippingCallback(shipping);
        }
    }


    render() {
        const { shipping } = this.props;
        let isCancelOrder = false;
        if (shipping.status === "PAYMENT_CONFIRMED" || shipping.status === "PICKUP"){
            isCancelOrder = true;
        }

        if (shipping.carrierId === "T1ENVIOS_DHL"){
            isCancelOrder = false;
        }
        /*if (shipping.status === "PAYMENT_CONFIRMED"){
            if (shipping.carrierId === "DHL_EXPRESS"){
                isCancelOrder = Constants.isCancelDateForDay(shipping.createDate, 8);
            }else if (shipping.carrierId === "FEDEX"){
                isCancelOrder = true;
            }else if (shipping.carrierId === "ESTAFETA"){
                isCancelOrder = Constants.isCancelDateForDay(shipping.createDate, 6);
            }else if (shipping.carrierId === "PAQUETEXPRESS"){
                isCancelOrder = true;
            }else if (shipping.carrierId === "REDPACK"){
                isCancelOrder = true;
            }
        }*/
        
        return (
            <React.Fragment>
            {isCancelOrder && (
                <div className="nowrap">
                    <i className="icofont-ban text-danger  btn link-small" ></i>
                    <button className="text-danger btn link-small" onClick={() => this.cancelShip(shipping)}>Cancelar Guia</button>
                </div>
            )}
            </React.Fragment>     
        );
  }
}

export default ShowCancelButton;
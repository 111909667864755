import React, { Component } from 'react';

class LocalGetShipping extends Component {

    render() {
        const { address, address2, name, phone, city, zip } = this.props;

        return (
            <React.Fragment>
                <div>
                    <b>{address}</b> {address2}
                </div>
                <div>
                    <b>{city}</b> {zip}
                </div>
                <div>
                    <b>{name}</b> {phone}
                </div>
            </React.Fragment>     
        );
  }
}

export default LocalGetShipping;
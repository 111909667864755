import React, { Component } from 'react';
import { Route } from 'react-router-dom';

class ExternalLayout extends Component {
  render() {
    const { component: ReceivedComponent } = this.props;
    return (
        <Route>
            <ReceivedComponent {...this.props} />
        </Route>
    );
  }
}

export default ExternalLayout;

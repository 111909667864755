import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import { connect } from 'react-redux';

class OutServer extends Component {
  componentDidMount() {
    localStorage.clear();
    /*setTimeout(function(){
      window.location.href = '/preferences';
    }.bind(this), 200);*/
  }

  handleClick = () => {
    window.location.href = '/';
  }

  render() {
    return (
      <div className="login">
          <h2 className="frame-1">Triciclo Go.</h2>
          <h2 className="frame-5">
            <span>Logistica</span>
            <span>&</span>
            <span>Envios</span>
          </h2>
          <div className="success">
            <h3 className="title">Estamos actualizando la aplicación</h3>
            <h3>Estaremos de vuelta en unos segundos</h3>
            <button className="btn btn-dark" onClick={() => this.handleClick()}>Reintentar</button>
          </div>  
          <img src="/img/shopify.png" alt="logo shopify" />
          
  </div>
    );
  }
}
const mapStateToProps = (state) => ({
    user : state.user,
});
  
const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(OutServer));

import React, { Component } from 'react';



class TextAreaHtml extends Component {

    state = { mError: "", isFocus: false };

    componentDidMount() {
        const { value } = this.props;
        this.onValid(value);
    }

    handleInputFocus = (e) => {
        this.setState({
            isFocus: true
        });
    }
    handleOnBlur = (e) => {
        const { update, name } = this.props;

        this.setState({
            isFocus: false
        });

        if(update){
            update(name);
        }
    }
    onChange = (evt, pattern) => {
        const { onBlur, name, preword } = this.props;
        let value = evt.target.value;
        if(pattern){
            value = (evt.target.validity.valid) ? evt.target.value : this.state.value;
        }
        if (preword){
            value = preword + value;
        }
        if (onBlur){
            onBlur(value, name);
        }
        this.onValid(value);
    }
    onValid = (value) => {
        const { required, maxlength } = this.props;
        const { mError } = this.state;
        console.log(maxlength);
        console.log(value);
        if (value){
        console.log(value.length);
        }
        if (required && value === ""){
            this.setState({
                mError: "Este campo no puede estar vacio"
            });
        }else if (value && maxlength && value.length > maxlength){
            this.setState({
                mError: "Supera por "+(value.length - maxlength)+" caracteres, el máximo permitido de "+maxlength+" caracteres."
            });
        } else if (mError !== ""){
            this.setState({
                mError: ""
            });
        }
    }
    
  
    render() {
        const { pre, readonly, placeholder, max, pattern, post, edit, value, className, min, onClick } = this.props;
        const {mError} = this.state;
        return (
            <React.Fragment>
                <div className={` ${(!edit) && 'readonly'} ${className && className}`}>
                    {pre && (
                        <div className="input-group-prepend">
                            <span className="input-group-text">{pre}</span>
                        </div>
                    )}
                    {edit ? (
                        <textarea className="input-form" onChange={e => this.onChange(e, pattern)} 
                        onBlur={this.handleOnBlur} onFocus={this.handleInputFocus} onClick={onClick} readOnly={readonly ? true : false} 
                        placeholder={placeholder} 
                        max={max ? max : ''}
                        min={min ? min : ''}
                        pattern={pattern ? pattern : ''}>
                            {value}
                        </textarea>
                    ) : (
                        <span className="text">{value}</span>
                    )}
                    {post && (
                        <div className="input-group-append">
                            <span className="input-group-text">{post}</span>
                        </div>
                    )}
                    {(mError !== "") && (<div className="error">Error: {mError}</div>)}
                </div>    
            </React.Fragment>     
        );
  }
}

export default TextAreaHtml;
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import 'react-dates/initialize';
import { isInclusivelyBeforeDay } from 'react-dates';
import 'moment/locale/es';
import InputHtml from './InputHtml';
import SelectHtml from './SelectHtml';
import  * as Util from '../../data/General';

class Filter extends Component {

    state = {
        isOpen: false,
        startDate: null,
        endDate: moment(),
        focusedInput: null,
        timeInitial: "T00:00:00-05:00",
        timeFinish: "T23:59:59-05:00",
        selectedDate: null,
        sort: "",
        sortText: "",
        sortValue: "",
        customerId: "",
        carrier: "0",
        idCarrier:""

    };
    componentDidMount() {
        
        const { timeInitial=false, timeFinish=false, initialDate, finishDate } = this.props;
        
        if(timeInitial !== false && timeFinish !== false){
            console.log("Entro");
            this.setState({timeInitial: timeInitial, timeFinish: timeFinish});
        }
        
        moment().locale('es');

        if(initialDate && finishDate){
            this.setSortOrder(initialDate, finishDate, false);
        }

    }

    setSortBy = (sort) => {
        const { handleSort } = this.props;

        let sortValue = sort;
        let sortText = sort;

        if(sort !== ""){
            let value = sort.split("-");
            sortValue = value[0];
            sortText = value[1];
        }

        if(handleSort){
            handleSort(sortValue);
        }
        this.setState({sort: sortValue, sortText : sortText, sortValue: sort});
    }

    setTranspoter = (sort) => {
        const { handleCarrier } = this.props;

        if(handleCarrier){
            handleCarrier(sort);
        }
        this.setState({idCarrier: sort});
    }

    clearSort = () => {
        this.setSortBy("");
    }

    clearSetDate = () => {
        this.setSortOrder(null, moment());
    }

    setDate = (initial, finish) => {
       if (initial && finish){
        this.setSortOrder(initial.format("YYYY-MM-DD"), finish.format("YYYY-MM-DD"));
       }else{
        this.setState({startDate: initial, endDate: finish});
       }
    }

    setSortOrder = (initial, finish, isCallback=true) => {
        const { handleSortDate } = this.props;
        const { timeFinish, timeInitial } = this.state;
        if(handleSortDate && initial !== null && isCallback){
            const sort = `${initial}${timeInitial}*${finish}${timeFinish}`;
            handleSortDate(sort);
        }else if(handleSortDate && initial === null && isCallback){
            handleSortDate("");
        }
        if(initial){
            this.setState({selectedDate: initial, startDate: moment(initial, "YYYY-MM-DD"), endDate: moment(finish, "YYYY-MM-DD")});
        }else{
            this.setState({selectedDate: null, startDate: null, endDate: moment(finish, "YYYY-MM-DD")});
        }
        setTimeout(function(){
            const { handleSortDateText } = this.props;
            if (handleSortDateText){
                handleSortDateText(this.getText());
            }
          }.bind(this), 500);
    }

    getText = () => {
        const {  today, yesterday, lastWeek, lastMonth } = this.props;
        const {  startDate, endDate, selectedDate } = this.state;
        let text = "desde el inicio";
        if (selectedDate === today) {
            text = "hoy";
        } else if (selectedDate === yesterday) {
            text = "ayer";
        } else if (selectedDate === lastWeek) {
            text = "en la última semana";
        } else if (selectedDate === lastMonth) {
            text = "en el última mes";
        } else if (startDate && endDate){
            text = `${startDate.calendar()} - ${endDate.calendar() === "Today at 12:00 AM" ? "Hoy" : endDate.calendar()}`;
        }
        return text;
    }

    updateCustomer = (value, name) =>{
        this.setState({customerId: value});
    }

    handleFilterCustomerId = ( name ) => {
        const { handleFilterAdmin } = this.props;
        const { customerId } = this.state;
        if(handleFilterAdmin){
            handleFilterAdmin(customerId, name);
        }
    }

    handleChange = (value, name) => {
        const { handleFilterAdmin } = this.props;
        this.setState({carrier: value});
        if(handleFilterAdmin){
            handleFilterAdmin(value, name);
        }
    }

    render() {
        const { optionsSortBy, today, yesterday, lastWeek, lastMonth, user, isHome=false, handleCarrier=false } = this.props;
        const { isOpen, startDate, endDate, selectedDate, sort, sortText, sortValue, customerId, carrier, idCarrier } = this.state;
        return (
                <>
                    <div className="filters-content content-flex flex-center-left">
                        <button onClick={() => this.setState({isOpen : !isOpen})} className="btn triciclo btn-link">
                            <i className="icofont-settings"></i> Filtrar
                        </button>
                        <div>
                            {endDate && startDate && (
                                <div className="filters-apllied" onClick={() => this.clearSetDate()}>
                                    <i className="icofont-calendar"></i>
                                    <>
                                        {selectedDate === today ? (
                                            <span>Hoy</span>
                                        ) : selectedDate === yesterday ? (
                                            <span>Ayer</span>
                                        ) : selectedDate === lastWeek ? (
                                            <span>Última semana</span>
                                        ) : selectedDate === lastMonth ? (
                                            <span>Última mes</span>
                                        ) : (
                                            <span>{startDate.calendar()} - {endDate.calendar() === "Today at 12:00 AM" ? "Hoy" : endDate.calendar()}</span>
                                        )}
                                    </> 
                                    <i className="icofont-close-squared-alt"></i>
                                </div>   
                            )}
                        </div>
                        <div>
                            {sort !== "" && (
                                <div className="filters-apllied" onClick={() => this.clearSort()}>
                                    <i className="icofont-navigation"></i> {sortText}
                                    <i className="icofont-close-squared-alt"></i>
                                </div>  
                            )}
                        </div>
                        {user.type === "ADMIN" && isHome && (
                            <React.Fragment>
                                <div className="content-input-filter">
                                    <InputHtml type="search" edit={true} name="customerId" value={customerId} onBlur={this.updateCustomer} placeholder="Ingrese customerId para filtrar" update={this.handleFilterCustomerId}/>
                                </div>
                                <div className="content-select-filter">
                                    <SelectHtml edit={true} options={Util.CARRIERS_FILTER} required={true} onChange={this.handleChange} name="carrierId" value={carrier}/>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    {isOpen && (
                        <div className="filter">
                            <h1>Filtrar por <button onClick={() => this.setState({isOpen : !isOpen})} className="btn btn-link"><i className="icofont-close-circled close-icon"></i></button></h1>
                            <hr></hr>
                            <h5>Fecha</h5>   
                            <div>
                                <button onClick={() => this.setSortOrder(today, today)} className="btn btn-link no-left">Hoy</button>       
                            </div> 
                            <div>
                                <button onClick={() => this.setSortOrder(yesterday, yesterday)} className="btn btn-link no-left">Ayer</button>       
                            </div> 
                            <div>
                                <button onClick={() => this.setSortOrder(lastWeek, today)} className="btn btn-link no-left">Última semana</button>       
                            </div> 
                            <div>
                                <button onClick={() => this.setSortOrder(lastMonth, today)} className="btn btn-link no-left">Último mes</button>       
                            </div>   
                            <div className="filter-date">
                                <DateRangePicker
                                    focusedInput={this.state.focusedInput}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    onDatesChange={({ startDate, endDate }) => this.setDate(startDate, endDate)}
                                    numberOfMonths={1}
                                    startDateId="your_unique_start_date_id"
                                    endDateId="your_unique_end_date_id"
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    showDefaultInputIcon 
                                    displayFormat="DD/MM/YYYY"
                                    minDate={moment().subtract(24, 'months').startOf('month')}
                                    maxDate={moment()}
                                    keepOpenOnDateSelect={true}
                                    isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                                    startDatePlaceholderText= "Fecha inicial"
                                    endDatePlaceholderText= "Fecha final"
                                    />

                                    
                            </div>
                            <hr></hr>
                            {optionsSortBy && (
                                <>
                                    <h5>Ordenar</h5>
                                    <select className="select-text" value={sortValue} onChange={e => this.setSortBy(e.target.value)}>
                                        <option value="">Ordenar</option>
                                        {optionsSortBy && optionsSortBy.map(
                                            (sort, index) => (
                                                <option key={index} value={`${sort.value}-${sort.title}`}>{sort.title}</option>
                                            )
                                        )}
                                    </select>
                                    <hr></hr>
                                </> 
                            )}
                            {handleCarrier && (
                                <>
                                    <div>
                                        <h5>Transportadora</h5>
                                    </div>
                                    <div>
                                        <select className="" value={idCarrier} onChange={e => this.setTranspoter(e.target.value)}>
                                            <option value="">Seleccione</option>
                                            {Object.keys(Util.CARRIERS_INFO).map(
                                                (sort, index) => (
                                                    <option key={index} value={`${sort}`}>{Util.CARRIERS_INFO[sort].name}</option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <hr></hr>
                                </>
                            )}
                        </div>
                    )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    today: state.today, 
    yesterday: state.yesterday, 
    lastWeek: state.lastWeek, 
    lastMonth: state.lastMonth,
    user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filter);

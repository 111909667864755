import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Util from './../data/General';

class RechargePopup extends Component {

    state = {
        isSearch: true,
        url: "",
        filter: {
            from: 0,
            size: 10,
        },
        listRecharge: {},
        isScroll: false,
        listAmount: [
            {value:"30335575949417:1", label: "$1,000"},
            {value:"30335577981033:1", label: "$2,000"},
            {value:"30335575949417:3", label: "$3,000"},
            {value:"30335577981033:2", label: "$4,000"},
            {value:"30335578865769:1", label: "$5,000"},
            {value:"30335577981033:4", label: "$8,000"},
            {value:"31669168406633:1", label: "$10,000"},
            {value:"30335578865769:3", label: "$15,000"},
            {value:"32331168546921:1", label: "$20,000"},
            {value:"31669168406633:3", label: "$30,000"},
            {value:"39491459547334:1", label: "$50,000"},
            {value:"32331168546921:4", label: "$80,000"},
            {value:"39491459547334:2", label: "$100,000"},
        ],
        urlCar: "https://triciclo.myshopify.com" 
    };

    changeAmount = (value) =>{
        this.setState({ url: value});
    };   
    
    componentDidMount() {
        const { filter } = this.state;
        setTimeout(function(){
            Util.loadDataUsers(this.props.dispatch, false);
          }.bind(this), 500);

        if (window.url_server === "https://dev.triciclogo.com/api/"){
          this.setState({
            listAmount: [
                {value:"39607849812173:1", label: "$1,000"},
                {value:"39607850074317:1", label: "$2,000"},
                {value:"39607849812173:3", label: "$3,000"},
                {value:"39607850074317:2", label: "$4,000"},
                {value:"39607850369229:1", label: "$5,000"},
                {value:"39607850008781:1", label: "$10,000"},
                {value:"39607850369229:3", label: "$15,000"},
                {value:"39607850107085:1", label: "$20,000"},
                         ],
            urlCar: "https://triciclogo-pay.myshopify.com/" 
          })
        } 
    } 
    
    closedPopup = () => {
        if (window.opener){
            window.opener.closePopup();
        }
    }
      
  
    render() {
        const { shop } = this.props;
        const { url, listAmount, urlCar } = this.state;
        // 500: 30335507923049
        // 1,000: 30335575949417
        // 2,000: 30335577981033
        // 5,000: 30335578865769
        // 10,000: 31669168406633
        // 20,000: 32331168546921
        return (
            <div className="recharge orden">
                <div className="content-popup">
                    <h1>
                        Recargar Saldo
                    </h1>
                    <div>
                        <select className="select-text" onChange={e => this.changeAmount(e.target.value)}>
                            <option value="">Seleccione el monto a cargar</option>
                            {listAmount && listAmount.length > 0 && listAmount.map((amount, index) => (
                                <option key={index} value={amount.value}>{amount.label}</option>
                            ))}
                        </select>
                    </div>    
                    <div>
                        <a className={`btn triciclo m-left ${url === "" ? "disabled" : ""}`} onClick={() => this.closedPopup()} href={`${urlCar}/cart/${url}?attributes[company]=${shop[0].shopifyShopName}&attributes[customerId]=${shop[0].id}`} target="_blank" rel="noopener noreferrer">
                            Recargar <i className="icofont-dollar-plus"></i>
                        </a> 
                    </div>  
                </div>    
            </div>
        );
        }
    }
const mapStateToProps = (state) => ({
    shop: state.shop,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RechargePopup);

import React, { Component } from 'react';
import InputHtml from '../html/InputHtml';
import RadioHtml from '../html/RadioHtml';
import SelectSearch from '../html/SelectSearch';
import Alert from '../html/Alert';
import  * as Util from './../../data/General';

class PackageDimensions extends Component {
    state = {
        editInsured: false,
        insuredCargo: "false",
        pickupOption: null
    }
    componentDidMount() {
        const { config, updatePickupOption } = this.props;
        let autoPickup = "true";
        if (config.autoPickup){
            autoPickup = config.autoPickup;
            updatePickupOption(autoPickup);
        }
        this.setState({
            insuredCargo: config.insuredCargo,
            pickupOption: autoPickup
        });
        
    }
    render() {
        const { type, pickup, isSearch, countryCode, quotations, packageList, packageListProps, config, updateInsuredAmount, newShipping, shippingTG, weight, orderEdit, personalizad, insuredAmount, minDate, handlePersonalizad, handlePickup, handleClickPackage, pickups, handleChangeIA, updatePickupOption } = this.props;
        const { insuredCargo, pickupOption } = this.state;

        console.log(pickupOption);

        return (
            <React.Fragment>
                {(type !== "order" && pickup && pickup.date) ? (
                    <React.Fragment>
                        <h4>Recolecciones</h4>
                        <hr></hr>
                        <label>Dimensiones del paquete</label>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <h4>Dimensiones del paquete</h4>
                        <hr></hr>
                    </React.Fragment>
                )}
                {packageList && (
                    <SelectSearch rows={packageList.list} value={packageListProps.selected.id} onChange={handleClickPackage}/>
                )}    
                <React.Fragment>
                    {packageListProps && packageListProps.selected.id !== 0 ? (
                        <React.Fragment>
                            <div className="content-package">
                                <p className="specification"><span>Alto:</span> {packageListProps.selected.height} CM</p>
                            </div>
                            <div className="content-flex flex-center cont-width">
                                <p className="specification"><span>Ancho:</span> {packageListProps.selected.width} CM</p>
                                <p className="specification"><span>Largo:</span> {packageListProps.selected["length"]} CM</p>
                            </div>
                            <div className="content-flex flex-center cont-weight">
                                <p className="specification">
                                    <span>Peso:</span>
                                    {(config.useWeight === "true" && !newShipping && !shippingTG ) ? (
                                        <React.Fragment>
                                            {" "}{weight} KG
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {" "}{packageListProps.selected.weight} KG
                                        </React.Fragment>
                                    )}      
                                    </p>
                            </div>
                        </React.Fragment>    
                    ) : (
                        <React.Fragment>
                            <div className="content-package">
                                <div className="specification"><span>Alto:</span> <InputHtml type="number" edit={true} onBlur={handlePersonalizad} name="length" value={personalizad["length"]}/> CM</div>
                            </div>
                            <div className="content-flex flex-center cont-width">
                                <div className="specification"><span>Largo:</span> <InputHtml type="number" edit={true} onBlur={handlePersonalizad} name="height" value={personalizad.height}/> CM</div>
                                <div className="specification"><span>Ancho:</span> <InputHtml type="number" edit={true} onBlur={handlePersonalizad} name="width" value={personalizad.width}/> CM</div>
                            </div>
                            <div className="content-flex flex-center cont-weight">
                                <div className="specification"><span>Peso:</span> 
                                {(config.useWeight === "true" && !newShipping && !shippingTG ) ? (
                                        <React.Fragment>
                                            {" "}{weight}
                                        </React.Fragment>
                                    ) : (
                                        <InputHtml type="number" edit={true} onBlur={handlePersonalizad} name="weight" value={personalizad.weight}/> 
                                    )}
                                KG</div>    
                            </div>
                        </React.Fragment>    
                    )} 
                </React.Fragment> 
                {(type !== "order" && type !== "shipping" && pickup && pickup.date) ? (
                    <React.Fragment>
                        <p>
                            <label>Fecha cuando se requiere la recolección:</label>
                            <div className="form-group">
                                <div className="input-group date datePicker">
                                    <InputHtml type="date" edit={true} name="date" notWeekend={true} min={minDate} value={pickup.date} onBlur={handlePickup} />
                                    <i className="icofont-ui-calendar"></i>
                                </div>
                            </div>
                            <div className="alert alert-warning">
                                <strong>Pasadas las 13:00 Hs. solo se podrá pautar la recolección para el siguiente día</strong>
                            </div>
                        </p>
                        <p>
                            <label>Hora en la cual el envío esta listo:</label>
                            <div className="form-group">
                                <div className="input-group date datePicker">
                                    <InputHtml type="time" edit={true} name="readyByTime" value={pickup.readyByTime} onBlur={handlePickup} />
                                    <i className="icofont-clock-time"></i>
                                </div>
                            </div>
                        </p>
                        <p>
                            <label>Hora de cierre de la tienda:</label>
                            <div className="form-group">
                                <div className="input-group date datePicker">
                                    <InputHtml type="time" edit={true} name="closeTime" value={pickup.closeTime} onBlur={handlePickup} />
                                    <i className="icofont-clock-time"></i>
                                </div>
                            </div>
                        </p>
                        <div className="content-flex flex-end">
                            <button onClick={() => pickups()} className="btn triciclo top-middle" disabled={isSearch ? true : false}>Pautar fecha</button>
                        </div> 

                    </React.Fragment>
                ) : !orderEdit && (
                    <div className="content-flex space-between">
                        <div className="content-flex flex-center">
                            <div className="content-flex insured">
                                
                                <div>¿Asegurar envío? <br></br><small>(Fedex y DHL)</small></div>
                                <RadioHtml 
                                    edit={true} 
                                    options={Util.yesNo}
                                    onChange={(e) => {this.setState({insuredCargo : e}); updateInsuredAmount(e)}} 
                                    name="insuredCargo" 
                                    value={insuredCargo}
                                />

                            </div>
                        </div>
                        <div>
                            <div className="content-flex flex-center content-pickup">
                                <div className="content-flex insured">
                                    {pickupOption && (
                                        <RadioHtml 
                                            edit={true} 
                                            options={Util.pickupOptions}
                                            onChange={(e) => {this.setState({pickupOption : e}); updatePickupOption(e)}} 
                                            name="pickupOption" 
                                            value={pickupOption}
                                        />
                                    )}
                                </div>
                            </div>
                            {pickupOption === "true" && pickup && pickup.date ? (
                                <div className="form-group">
                                    <div className="input-group date datePicker content-pickup-datePicker">
                                        <InputHtml type="date" edit={true} name="date" notWeekend={true} min={minDate} value={pickup.date} onBlur={handlePickup} />
                                        <i className="icofont-ui-calendar"></i>
                                    </div>
                                </div>
                            ) : (
                                <div className="input-ghost"></div>
                            )}
                        </div>
                        {countryCode === "MX" ? (
                            <button onClick={() => quotations()} className="btn triciclo" disabled={isSearch ? true : false}>Continuar</button>
                        ) : ( 
                            <div>
                                <Alert warningText="Solo se pueden hacer envíos dentro de México" />
                                <Alert warningTextWOA="Por favor edite la dirección del destinatario" />
                            </div>
                        )}
                    </div> 
                )}
                {insuredCargo === "true" && (
                    <div className="label">
                        Valor del seguro (0 es igual a no asegurar): <InputHtml type="number"  edit={true} onBlur={handleChangeIA} name="insuredAmount" value={insuredAmount}/>
                    </div>
                )}
                {pickupOption === "true" && (
                    <div className="alert alert-warning">
                        <strong>La recolección se realizará en el transcurso del día indicado, en un horario de 12:00 a 18:00 hrs</strong>
                    </div>
                )}
            </React.Fragment>  
        );
  }
}

export default PackageDimensions;

import React from 'react';
import { Switch } from 'react-router-dom';

import DefaultLayout from './components/layout/DefaultLayout';
import ExternalLayout from './components/layout/ExternalLayout';
import Dashboard from './pages/Dashboard';
import ListOrders from './pages/ListOrders';
import ListShops from './pages/ListShops';
import ListReturns from './pages/ListReturns';
import Order from './pages/Order';
import Pickup from './pages/Pickup';
import ListShipping from './pages/ListShipping';
import Callback from './components/auth/Callback';
import AuthSopify from './components/auth/AuthSopify';
import Login from './pages/Login';
import TC from './pages/TC';
import Recharge from './pages/Recharge';
import RechargePopup  from './pages/RechargePopup';
import Billing from './pages/Billing';
import ServerError from './pages/ServerError';
import Preferences from './pages/Preferences';
import ListPackings from './pages/ListPackings';
import Packing from './pages/Packing';
import ListPickups from './pages/ListPickups';
import Shop from './pages/Shop';
import MultipleOrdersList from './pages/MultipleOrdersList';
import LocalOrders from './pages/LocalOrders';
import MultiPrint from './pages/MultiPrint';
import Print from './pages/Print';
import Accuse from './pages/Accuse';
import Success from './pages/Success';
import OutServer from './pages/OutServer';
import Stages from './pages/Stages';
import Stage from './pages/Stage';
import Help from './pages/Help';
import Config from './pages/Config';
import Guides from './pages/Guides';
import Return from './pages/Return';
import ReturnShopify from './pages/ReturnShopify';

const AppRoutes = () => 

    <Switch>
        <DefaultLayout exact path="/multi-orders" component={MultipleOrdersList} />
        <DefaultLayout exact path="/order-stage" component={Stages} />
        <DefaultLayout exact path="/stage-new/" component={Stage} type="new" />
        <DefaultLayout exact path="/stage/:id" component={Stage} type="old"/>
        <DefaultLayout exact path="/print/:type" component={Print} />
        <ExternalLayout exact path="/multi-print-order" component={MultiPrint} type="order" />
        <ExternalLayout exact path="/multi-print" component={MultiPrint} type="shipping" />
        <ExternalLayout exact path="/local-accuse" component={Accuse} type="special" />
        <ExternalLayout exact path="/accuse-print" component={Accuse} type="shipping" />
        <ExternalLayout exact path="/order-accuse-print" component={Accuse} type="order" />
        <DefaultLayout exact path="/local-orders" component={LocalOrders} />
        <DefaultLayout exact path="/orders" component={ListOrders} />
        <DefaultLayout exact path="/order/:id" component={Order} type="order" /> 
        <DefaultLayout exact path="/order-shopify/" component={Order} type="order-shopify" /> 
        
        <DefaultLayout exact path="/new-pickup/:id" type="pickup-id" component={Pickup} />    
        <DefaultLayout exact path="/new-pickup/" type="pickup" component={Pickup} />
        <DefaultLayout exact path="/pickup-local/:id" component={Order} type="pickup-local" />
        <DefaultLayout exact path="/pickup/:id/:idShipping" component={Order} type="pickup" />
        
        
        <DefaultLayout exact path="/new-shipping" component={Order} type="new" />
        <DefaultLayout exact path="/order-shipping/:id" component={Order} type="shipping" />    
        <DefaultLayout exact path="/shipping-tg" component={Order} type="new-id" />         
        <DefaultLayout exact path="/shippings/paid" component={ListShipping} type="PAYMENT_CONFIRMED"/>
        <DefaultLayout exact path="/shippings" component={ListShipping} type="" />
        <DefaultLayout exact path="/shop/:id" component={Shop} />
        <DefaultLayout exact path="/packings" component={ListPackings} />
        <DefaultLayout exact path="/packing/:id" component={Packing} />
        <DefaultLayout exact path="/pickups" component={ListPickups} />
        <DefaultLayout exact path="/returns" component={ListReturns} />
        <DefaultLayout exact path="/return/:id" component={Return} />
        <DefaultLayout exact path="/return-shopify" component={ReturnShopify} />
        <ExternalLayout exact path="/success" component={Success} />
        <ExternalLayout exact path="/out-server" component={OutServer} />
	    <DefaultLayout exact path="/help" component={Help} />
        <DefaultLayout exact path='/callback' component={Callback}/>
        <DefaultLayout exact path='/auth-shopify/:shop' component={AuthSopify}/>
        <DefaultLayout exact path="/home" component={Dashboard} />
        <DefaultLayout exact path="/recharge" component={Recharge} />
        <DefaultLayout exact path="/billing" component={Billing} />
        <ExternalLayout exact path="/recharge-popup" component={RechargePopup} />
        <DefaultLayout exact path="/preferences" component={Preferences} />


        <DefaultLayout exact path="/shops" component={ListShops} />
        <DefaultLayout exact path="/config" component={Config} />
        <DefaultLayout exact path="/guides-loading" component={Guides} />
        <DefaultLayout exact path="/server-error" component={ServerError} />
        
        <DefaultLayout exact path="/tc" component={TC} />
        <ExternalLayout exact path="/" component={Login} />
        <ExternalLayout path="*" component={Login} />
    </Switch>;

export default AppRoutes;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from '../store/index';
import InputHtml from '../components/html/InputHtml';
import 'suneditor/dist/css/suneditor.min.css';
import Alert from '../components/html/Alert';
import StageManual from './StageManual';
import Stagepredetermined from './Stagepredetermined';
import SelectImage from '../components/html/SelectImage';
import * as Util from './../data/General';


class Stage extends Component {

    state = {
        isSearch: true,
        edit: true,
        stage: {
            "id":"",
            "name":"",
            "active":true,
            "emailData":{
                "send":true,
                "subject":"Felicitaciones {{customer.firstname}}, estamos preparando tu pedido",
                "body":''
            },
            index: null,
            icon:"",
        },
        listCode:[
            {key: "{{customer.firstname}}", value: "Nombre del cliente"},
            {key: "{{customer.lastname}}", value: "Apellido del cliente"},
            {key: "{{order.name}}", value: "Nombre de la orden"},
            {key: "{{shop.name}}", value: "Nombre de la tienda"},
            {key: "{{stages.icons}}", value: "Flujo de estados de pedidos representados con iconos"},
        ],
        textCopy: "",
        message:"",
        view: 0,
        isDataCheck: false
    };

    changeAmount = (value) =>{
        this.setState({ url: value});
    };   
    
    componentDidMount() {
        const { type, stage } = this.props;
        if (type === "old"){
            var idStage = window.location.pathname.replace("/stage/", "");
            idStage = idStage.replace("%20", " ");
            this.loadData(idStage);
        }else{
            this.setState({ isSearch: false, isDataCheck: true});
        }

        document.addEventListener('copy', this.copy);
    }

    copy = () => {
        var selection = window.getSelection().toString();
        this.setState({textCopy: selection});
    }
  

    loadData = (id) => {
        this.setState({ isSearch: true});
        const url = Constants.URL_API+"orders/stages/"+id;
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            this.setState({ isSearch: false, stage: data, isDataCheck: true});
        }); 
    }

    edit = (name) => {

    }

    delete = (name) => {
        
    }

    handleChangeText = (content) => {
        console.log(content); //Get Content Inside Editor
        const { stage } = this.state; 
        stage.emailData.body = content; 
        this.setState({stage: stage});
    }

    handleCopy = (copy) => {
        var aux = document.createElement("input");
        aux.setAttribute("value", copy);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }
  
    handleChange = (value, name) =>{
        const { stage } = this.state;
        stage.emailData[name] = value;
        this.setState({
            stage: stage
        });
    }

    handleChangeVar = (value, name) =>{
        const { stage } = this.state;
        stage[name] = value;
        this.setState({
            stage: stage
        });
    }

    handleChangeCheckbox = (name, isMail) =>{
        const { stage } = this.state;
        if (isMail){
            stage.emailData[name] = !stage.emailData[name];
        }else{
            stage[name] = !stage[name];
        }
        this.setState({
            stage: stage
        });
    }

    changeIcon = (icon) =>{
        const { stage } = this.state;
        
        stage["icon"] = icon;
        console.log(stage);
        this.setState({
            stage: stage
        });
    }

    cancel = () => {
        window.location.href = "/order-stage";
    }

    save = () => {
        const { stage } = this.state;
        this.setState({isSearch : true});

        stage.emailData.body = stage.emailData.body.replace(/"/g, "'"); 
        console.log(stage);
        const url = Constants.URL_API+"orders/stages/"+stage.id;
        if (stage.id === ""){
            axios.post(url, stage, Constants.getHeaders()).then(response => response.data, error => {
                Constants.setErrors(error);
                return error;
            }).then((data) => {
                this.saveCallback(data);
            });
        }else{
            axios.put(url, stage, Constants.getHeaders()).then(response => response.data, error => {
                Constants.setErrors(error);
                this.setState({isSearch : false, message: error});
                return error;
            }).then((data) => {
                this.saveCallback(data);
            });
        }
    }

    saveCallback = (data) => {
        const { stage } = this.state;
        console.log(data);  
        if (data.id){
            let messageText = "El estado quedo guardado correctamente";
            if (stage.id === ""){
                messageText = "El estado se creo correctamente";
            }
            this.setState({isSearch : false, message: messageText});
        }else{
            this.setState({isSearch : false, message: "Ocurrio un error"});
        }
        Constants.scrollToWindow();
    }

    getImage = () => {
        const { stages } = this.props; 
        const { stage } = this.state;
        
        let firstTr = "";
        let secondTr = "";
        Object.keys(stages.list).map((key, index) => {
            var stageList = stages.list[key];
            if (stageList.active){
                if (firstTr !== ""){
                    firstTr+= "<td style='opacity: 0.5;width: 2rem;'> --- </td>";
                    secondTr+= "<td></td>";
                }else{
                    firstTr = "<tr>"; 
                    secondTr = "<tr>";
                }
                var style = "opacity: 0.3;";
                var image = stageList.icon;
                var name = stageList.name;
                if (stageList.id === stage.id){
                    style= "";
                    image = stage.icon;
                    name = stage.name;
                }


                firstTr += "<td style='width: 6rem'><img style='width: 48px;margin: 5px;"+style+"' src='"+image+"' /></td>";
                secondTr += "<td style='font-size: 14px;width: 6rem;"+style+"'>"+name+"</td>";
            }
        });
        firstTr+= "</tr>"+secondTr+"</tr>"; 

        if (stage.index === null){
            stage.index = Object.keys(stages.list).length + 1;
            this.setState({stage: stage});
        }
        return  firstTr;
        
    } 

    updateBody = (body) => {
        const { stage } = this.state;
        
        stage.emailData.body = body.replace(/"/g, "'");
        console.log(stage);
        this.setState({stage: stage});
    }

    render() {
        const { message, textCopy, isSearch, edit, listCode, stage, view, isDataCheck } = this.state;
        const { type } =this.props;

        return (
            <div className="recharge orden">
                <div className="title content-flex space-between">
                    <h1>
                        Estado: <b>{stage.name}</b> 
                    </h1>
                </div>
                <div className="content-flex">
                    <div className="content-flex space-between-start">
                        <div className="email-template">
                            <Alert successText={message} />
                            <div className="label">
                                Nombre del estado: 
                                <InputHtml 
                                    type="text" 
                                    edit={edit} 
                                    onBlur={this.handleChangeVar} 
                                    name="name" 
                                    value={stage.name}
                                />
                            </div>
                            <div className="content-flex">
                                <div className="label label-check">
                                    Estado: 
                                    <label className="switchBtn">
                                        <input 
                                            type="checkbox" 
                                            checked={stage.active} 
                                            onChange={() => this.handleChangeCheckbox("active", false)} 
                                        />
                                        <div className="slide round">
                                            <span className="active">Activo</span>
                                            <span className="disabled">Desactivo</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="label label-check">
                                    Se envia correo: 
                                    <label className="switchBtn">
                                        <input 
                                            type="checkbox" 
                                            checked={stage.emailData.send} 
                                            onChange={() => this.handleChangeCheckbox("send", true)} 
                                        />
                                        <div className="slide round">
                                            <span className="active">Si</span>
                                            <span className="disabled">No</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="label label-check">
                                    Icono: 
                                    {!isSearch && (
                                        <SelectImage 
                                            options={Util.icons} 
                                            value={stage.icon} 
                                            onChange={this.changeIcon} 
                                            prefijo ="/img/icon/"
                                        />
                                    )}
                                </div>
                            </div>
                            {stage.emailData.send && (
                                <React.Fragment>
                                    <Alert  infoText={`Defina el correo que se enviará automáticamente cuando el pedido pase a ${stage.name}`} />
                                    
                                    <div className="label">
                                        Asunto: 
                                        <InputHtml 
                                            type="text" 
                                            edit={edit} 
                                            onBlur={this.handleChange} 
                                            name="subject" 
                                            value={stage.emailData.subject}
                                        />
                                    </div>
                                    <div className="content-flex tabs">
                                        <div className={`tab pred${view}`} onClick={() => this.setState({view: 0})}>Predeterminado</div>
                                        {/*<div className={`tab manual${view}`} onClick={() => this.setState({view: 1})}>Manual</div>*/}
                                    </div>
                                    {isDataCheck ? (
                                        <div className="tabs-content">
                                            {view === 0 ? (
                                                <Stagepredetermined icons={this.getImage()} updateBody={this.updateBody} stage={stage} type={type}/>
                                                
                                            ) : (
                                                <StageManual icons={this.getImage()} updateBody={this.updateBody} stage={stage} type={type} />
                                            )}
                                        </div>
                                    ) : (
                                        <div className="tabs-content">
                                            <div className="center content-loading witch-table">
                                                <div className="loading">
                                                    <div id="preloader">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div> 
                                                    <span>Cargando Template</span>
                                                </div>    
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                            <div className="content-flex flex-end">
                                <button onClick={() => this.cancel()} className="btn btn-link">Volver</button>
                                <button onClick={() => this.save()} className="btn triciclo top-middle" disabled={isSearch ? true : false}>
                                    {stage.id === "" ? "Crear estado" : "Guardar"}
                                </button>
                            </div> 

                            
                        </div>
                        <div className="email-help">
                            <h2>Personalice su Email</h2>
                            <hr></hr>
                            <p>
                                A continuación se listará las etiquetas predefinidas que podrá utilizar en el asunto o en el cuerpo del correo, posteriormente las misma serán reemplazadas por su valor correspondiente 
                            </p>
                            <hr></hr>
                            {listCode && listCode.length > 0 && listCode.map((code, index) => (
                                <div className="shortcode">
                                    <label>{code.value}</label>
                                    <p className="content-flex space-between-start" onClick={() => this.handleCopy(code.key)}>
                                        <span>{code.key}</span>
                                        <span>Copiar</span>
                                    </p>
                                    {textCopy === code.key && (
                                        <div className="help-copy">
                                            Texto "{code.key}" copiado al portapapeles
                                        </div>
                                    )}
                                    <hr></hr>
                                </div>
                            ))}
                            
                        </div>
                    </div>
                </div>    
            </div>
        );
        }
    }
const mapStateToProps = (state) => ({
    shop: state.shop,
    stages: state.stages
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stage);

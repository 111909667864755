import React, { Component } from 'react';


class GetName extends Component {

    render() {
        const { customer } = this.props;

        return (
            <React.Fragment>
                {customer && customer.firstName ? (
                    <span>
                        {customer.firstName+" "+customer.lastName}
                    </span>
                ) : (customer && customer.defaultAddress) ? (
                    <span>
                        { customer.defaultAddress.firstName+" "+customer.defaultAddress.lastName}
                    </span>
                ) : (<span></span>)}
            </React.Fragment>     
        );
  }
}

export default GetName;
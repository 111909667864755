import React, { Component } from 'react';



class SelectHtml extends Component {
    state = { value: "", mError:"" };

    componentDidMount() {
        
    }

    onChange = (value) => {
        const { name, onChange } = this.props;
        
        if (onChange){
            onChange(value, name);
        }
        
    }

    getLabel = (list, index) => {
        const { keyValue, addText } = this.props;
        let textReturn = "";
        if (list){
            Object.keys(keyValue).map((key)=>{
                if(textReturn !== "" && list[key] !== ""){
                    textReturn = textReturn + ", ";
                }
                textReturn = textReturn + list[key];
            });
        }
        if (addText && index === 0){
            textReturn = addText + textReturn;
        }
        return textReturn;
    }
  
    render() {
        const { options, value, edit, keyValue, defaultValue, defaultText, className="" , loading } = this.props;
        const { mError } = this.state;
        return (
            <div className={className}>
                {edit ? (
                    <div>
                        {loading && (<div className="preloaderHtml"></div>)}
                        <select className={`form-control ${mError !== "" && 'error'}`} value={value} onChange={e => this.onChange(e.target.value)} >
                            {defaultText && (
                                <option value={defaultValue}>
                                    {defaultText}
                                </option>
                            )}
                            {options && Object.keys(options).map((key, index) =>
                                <option key={index} value={key}>
                                    {keyValue ? 
                                        this.getLabel(options[key], index)
                                    : 
                                        options[key]
                                    }
                                </option>
                            )}
                        </select>
                        {mError !== "" && (<div className="error">{mError}</div>)}
                    </div>    
                ) : (
                    <label className="mode-edit">
                        {defaultText && value === defaultValue && defaultText}
                        
                        {keyValue ? 
                             this.getLabel(options[value], value)
                        : 
                            options[value]
                        }
                    </label>
                )}  
            </div> 
        );
  }
}

export default SelectHtml;
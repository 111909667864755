import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from '../store/index';
import InputHtml from '../components/html/InputHtml';
import 'suneditor/dist/css/suneditor.min.css';
import TextAreaHtml from '../components/html/TextAreaHtml';
import ReactDOMServer from 'react-dom/server';


class Stagepredetermined extends Component {

    state = {
        isSearch: true,
        edit: true,
        mail:{
            header: "¡GRACIAS POR SU COMPRAR!",
            regards: "Hola {{customer.firstname}}",
            introduction: "Recibimos tu pago y ya estamos preparando tu pedido",
            closed: "Te recordamos que todos nuestros muebles se entregan desarmados y en caja, si necesitas ayuda con el armado, por favor pregunta por este servicio a nuestro equipo. Recibirás un correo con los datos de tu guía cuando sea enviado.",
            backgroundColor: "#404040",
            colorTextFooter: "#ffffff",
            socialIcon: "9"
        },
        listCode:[
            {key: "{{customer.firstname}}", value: "Nombre del cliente"},
            {key: "{{customer.lastname}}", value: "Apellido del cliente"},
            {key: "{{order.name}}", value: "Nombre de la orden"},
            {key: "{{shop.name}}", value: "Nombre de la tienda"},
        ],
        textCopy: "",
        message:"",
        seeIcon: true,
        styleContent: {"textAlign": "center", "fontFamily": "Arial","backgroundColor":"#ffffff"},
        styleHeader: {"fontSize": "22px","lineHeight": "28px", "fontWeight": "bold", "color": "#333", "fontFamily": "Arial", "marginBottom": "20px", "padding": "0rem 8px"},
        styleRegards:{"fontSize": "16px", "color": "#444", "paddingBottom": "18px", "fontFamily": "Arial", "padding": "3px 8px", "margin":"0rem"},
        styleIntroduction:{"fontSize": "16px", "color": "#444", "paddingBottom": "18px", "fontFamily": "Arial", "padding": "3px 8px", "margin":"0rem"},
        styleClosed:{"fontSize": "16px", "color": "#444", "paddingBottom": "18px", "fontFamily": "Arial", "padding": "3px 8px", "margin": "0px 0px 40px 0px"},
        styleFooterTD:{"padding":"20px","textAlign":"center","fontSize":"12px","color":"#CCCCCC;"},
        styleTable:{"margin": "36px auto"},
        styleContentTable: {"width":"100%", "textAlign":"center"},
        styleContentTableView:{"margin": "36px auto", "textAlign":"center", "display": "flex","justifyContent": "center"},
        iconHtml: null,
        styleFooter: {"margin": "24px 0px 0px 0px", "width": "100%" },
        isDataCheck: false

    };

    changeAmount = (value) =>{
        this.setState({ url: value});
    };   
    
    componentDidMount() {
        const { type } = this.props;
        if (type === "old"){
            setTimeout(function(){
                this.handleSetValue();
              }.bind(this), 200);

              setTimeout(function(){
                this.update("");
              }.bind(this), 500);  
        }else{
            this.setState({ isSearch: false, isDataCheck: true});
        }
    }

    handleSetValue = () => {
        try{
            const { mail } = this.state;
            mail.header = document.getElementById("header").innerText;
            mail.regards = document.getElementById("regards").innerText;
            mail.introduction = document.getElementById("introduction").innerText;
            mail.closed = document.getElementById("closed").innerText;
            mail.backgroundColor = document.getElementById("backgroundColor").innerText;
            mail.colorTextFooter = document.getElementById("colorTextFooter").innerText;
            mail.socialIcon = document.getElementById("socialIcon").innerText;
            this.setState({ isSearch: false, mail: mail, isDataCheck: true});
        }catch (e){
            console.log(e);
            this.setState({ isSearch: false, isDataCheck: true});
        }
    }
  
    handleChange = (value, name) =>{
        const { mail } = this.state;
        mail[name] = value;
        this.setState({
            mail: mail
        });
    }

    cancel = () => {
        window.location.href = "/order-stage";
    }


    update = (name) =>{
        const { updateBody } = this.props;

        const body = ReactDOMServer.renderToStaticMarkup(<div>{this.getBody(2)}</div>);

        console.log(body);

        if (updateBody){
            updateBody(body);
        }

    }


    getBody = (type) =>{

        const { mail, styleContent, styleHeader, styleContentTableView, styleRegards, seeIcon, styleContentTable, styleIntroduction, styleClosed, styleFooter, styleTable, styleFooterTD } = this.state;
        const { shop, icons } = this.props;

        let styleFooterTDCopy = {
            ...styleFooterTD,
            "backgroundColor": mail.backgroundColor
        };
        let heightImagen = "100px";
        if (type !== 1){
            heightImagen = "50px"
        }
        

        return (
            <div style={styleContent}>
                <img src={shop[0].logoUrl+"?time="+Date.now()} style={{"height": heightImagen, "padding": "20px 0px"}} alt={`logo de la tienda ${shop[0].name}`}/>
                <h1 style={styleHeader} id="header">{mail.header}</h1>
                <p style={styleRegards} id="regards">{mail.regards}</p>

                <p style={styleIntroduction} id="introduction">{mail.introduction}</p>
                
                {seeIcon && (
                    <div style={styleContentTable}>
                        {type === 1 ? (
                            <table style={styleTable} dangerouslySetInnerHTML={{__html:icons}}></table>
                        ) : (
                            <div style={styleContentTableView}>
                                <table style={{"margin":"0 auto"}}>
                                    {`{{stages.icons}}`}
                                </table>
                            </div>
                        )}
                    </div>
                )}
                
                
                <p style={styleClosed} id="closed">{mail.closed}</p>

                <table style={styleFooter}>
                    <tr>
                        <td style={styleFooterTDCopy}>
                            <p style={{"margin":"0 0 8px 0"}}>
                                {shop[0].facebook && (
                                    <a href={shop[0].facebook} style={{"textDecoration":"none"}}>
                                        <img src={`https://app.triciclogo.com/img/facebook_${mail.socialIcon}.png`} alt="Logo de facebook" width="40" height="40" alt="f" style={{"display":"inline-block","color":"#cccccc", "margin": "0 5px"}} />
                                    </a>
                                )}
                                {shop[0].twitter && (
                                    <a href={shop[0].twitter} style={{"textDecoration":"none"}}>
                                        <img src={`https://app.triciclogo.com/img/twitter_${mail.socialIcon}.png`} alt="Logo de twitter" width="40" height="40" alt="f" style={{"display":"inline-block","color":"#cccccc", "margin": "0 5px"}} />
                                    </a>
                                )}
                                {shop[0].instagram && (
                                    <a href={shop[0].instagram} style={{"textDecoration":"none"}}>
                                        <img src={`https://app.triciclogo.com/img/instagram_${mail.socialIcon}.png`} alt="Logo de instagram"  width="40" height="40" alt="f" style={{"display":"inline-block","color":"#cccccc", "margin": "0 5px"}} />
                                    </a>
                                )}
                                {shop[0].tiktok && (
                                    <a href={shop[0].tiktok} style={{"textDecoration":"none"}}>
                                        <img src={`https://app.triciclogo.com/img/tiktok${mail.socialIcon}.png`}  alt="Logo de tiktok"  width="40" height="40" alt="f" style={{"display":"inline-block","color":"#cccccc", "margin": "0 5px"}} />
                                    </a>
                                )}
                            </p>
                            <p style={{"margin":"0","fontSize":"14px","lineHeight":"20px"}}>
                                <a href={"https://"+shop[0].myshopifyDomain} target="_blank" title={"Link a la tienda"+shop[0].name} style={{"textDecoration":"none","color": mail.colorTextFooter}}>
                                    {shop[0].name}
                                </a>
                            </p>
                        </td>
                    </tr>
                </table>
                <p style={{"display":"none"}} id="colorTextFooter">{mail.colorTextFooter}</p>
                <p style={{"display":"none"}} id="backgroundColor">{mail.backgroundColor}</p>
                <p style={{"display":"none"}} id="socialIcon">{mail.socialIcon}</p>
            </div>
        );
    }

    getStyles = (style) => {
        let styleReturn = "";
        Object.keys(style).map(( id, index ) => {
            styleReturn+=this.getStyleCorrect(id)+":"+style[id]+";";
        });
        //alert(styleReturn);
        return styleReturn;
    }

    isUpperCase = (letra) =>{
        return letra === letra.toUpperCase();
    }

    getStyleCorrect = (text) => {
        for(var index = 0; index < text.length; index++){
                var letraActual = text.charAt(index);
                if(this.isUpperCase(letraActual)){
                    //var regEx = new RegExp(letraActual, "ig");
                    var replaceMask = "-"+letraActual.toLowerCase();

                    var result = text.replace(letraActual, replaceMask);

                    return result;
                }     
            }
    }


    render() {
        const { mail, seeIcon, isDataCheck } = this.state;
        const { type, stage } = this.props;

        return (
            
            <div className="content-flex space-between-initial">
                {isDataCheck && (
                    <div className="definition-simulate-email">
                        <div className="label">
                            Encabezado: 
                            <InputHtml 
                                type="text" 
                                edit={true} 
                                onBlur={this.handleChange} 
                                name="header" 
                                value={mail.header}
                                update={this.update}
                            />
                        </div>
                        <div className="label">
                            Saludo: 
                            <InputHtml 
                                type="text" 
                                edit={true} 
                                onBlur={this.handleChange} 
                                name="regards" 
                                value={mail.regards}
                                update={this.update}
                            />
                        </div>
                        <div className="label">
                            Introducción: 
                            <TextAreaHtml 
                                type="text" 
                                edit={true} 
                                onBlur={this.handleChange} 
                                name="introduction" 
                                value={mail.introduction}
                                className="text-area"
                                update={this.update}
                            />
                        </div>
                        <div className="label label-check">
                            Mostrar iconos: 
                            <label className="switchBtn">
                                <input 
                                    type="checkbox" 
                                    checked={seeIcon} 
                                    onChange={() => this.setState({seeIcon: !seeIcon})} 
                                />
                                <div className="slide round">
                                    <span className="active">Si</span>
                                    <span className="disabled">No</span>
                                </div>
                            </label>
                        </div>
                        <div className="label">
                            Cierre: 
                            <TextAreaHtml 
                                type="text" 
                                edit={true} 
                                onBlur={this.handleChange} 
                                name="closed" 
                                value={mail.closed}
                                className="text-area"
                                update={this.update}
                            />
                        </div>
                        <div className="label">
                            Estilo de los iconos sociales:
                            <img className={`select-icon ${mail.socialIcon === "9" ? "active" : ""}`} onClick={() => this.handleChange("9", "socialIcon")} src="/img/facebook_9.png" />
                            <img className={`select-icon ${mail.socialIcon === "10" ? "active" : ""}`} onClick={() => this.handleChange("10", "socialIcon")} src="/img/facebook_10.png" />
                        </div>
                        <div className="label">
                            Color fondo del footer: 
                            <InputHtml 
                                type="color" 
                                edit={true} 
                                onBlur={this.handleChange} 
                                name="backgroundColor" 
                                value={mail.backgroundColor}
                                update={this.update}
                            />
                        </div>
                        <div className="label">
                            Color de la letra del footer: 
                            <InputHtml 
                                type="color" 
                                edit={true} 
                                onBlur={this.handleChange} 
                                name="colorTextFooter" 
                                value={mail.colorTextFooter}
                                update={this.update}
                            />
                        </div>
                    </div>
                )}
                {isDataCheck ? (
                    <div className="simulate-email">
                        {this.getBody(1)}
                    </div>
                ) : (type === "old") && (
                    <div dangerouslySetInnerHTML={{__html:stage.emailData.body}} style={{"display":"none"}}></div>
                )}
            </div>
        );
        }
    }
const mapStateToProps = (state) => ({
    shop: state.shop,
    stages: state.stages
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stagepredetermined);

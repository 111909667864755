import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Constants from './../store/index';
import axios from "axios";
import  * as Util from './../data/General';
import CurrencyFormat from 'react-currency-format';


class MultiPrint extends Component {

    state = {
        isSearch: false,
        isScroll: false,
        packageList:null,
        carrier: null,
        carrierKey: "",
        list_shippings: null,
        step: 0,
        totalQuotion: 0,
        shopAddresses: -1,
        shop: null,
        processed: false,
        count_processed: false,
    };

    componentDidMount() {
        const { shippings, shopState, type, ordens } = this.props;
        
        let shop = shopState[0];
        let list_shippings= [];
        console.log(type);
        if ("shipping" === type){
            shippings.list.map((order)=>{
                if (order.selected){
                    if (order.customerId !== 0){
                        shop = Util.getShop(shopState, order.customerId);
                    }
                    list_shippings.push(order);
                    this.getOrder(order.orderId);
                }
            });
        }else{
            ordens.list.map((order)=>{
                if (order.selected){
                    if (order.customerId !== 0){
                        shop = Util.getShop(shopState, order.customerId);
                    }
                    list_shippings.push(order);
                    this.getOrder(order.id);
                }
            });
        }

        if(shop === null){
            shop = shopState[0]; 
        }
        console.log(list_shippings);
        this.setState({ list_shippings: list_shippings, shop: shop});
    }

    getOrder = (idOrden) => {
        const { count_processed } = this.state;
        const url = Constants.URL_API+"orders/"+idOrden;
        
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            const { list_shippings } = this.state;
            const { type } = this.props;

            list_shippings.map((shipping)=>{
                if ((type === "shipping" && shipping.orderId === data.id) || (type === "order" && shipping.id === data.id)){
                    shipping.order = data;
                }
            });
            const { count_processed } = this.state;
            let processed = false;
            
            if (count_processed + 1 === list_shippings.length){
                processed = true;
                Util.printPage();
            }
            this.setState({list_shippings: list_shippings, count_processed: count_processed + 1, processed: processed});
        }); 
        return false;
    }

    getFecha = (num) => {
        if (num > 9){
          return num;
        }else{
          return "0"+num;
        }
      }
   
    render() {
        const { isSearch, isScroll, packageList, carrierKey, list_shippings, step, totalQuotion, processed, shop } = this.state;
        
        let day= new Date();
        let today = this.getFecha(day.getDate()) +"-"+this.getFecha(day.getMonth()+1)+"-"+ day.getFullYear();
        return (
            
            <div className="print-page">
                    
                    <div >
                    {list_shippings && list_shippings.length > 0 && processed ? (
                        <div >
                            {list_shippings.map((shipping, index) => (
                                <div className="page-print" key={index}>
                                    {shipping.order && (
                                        <React.Fragment>
                                            <div className="content-flex space-between">
                                                <div className="header-print">
                                                    <div><b>Pedido:</b> {shipping.order.name}</div>
                                                    <div><b>Fecha:</b> {today}</div>
                                                    <div><b>Pago:</b> {shipping.order.gateway}</div>
                                                    {shipping.order.shippingLines && shipping.order.shippingLines.length > 0 && (
                                                        <div><b>Envio:</b> {shipping.order.shippingLines[0].title}</div>
                                                    )}
                                                </div>
                                                {shop.logoUrl && shop.logoUrl !== "" && (
                                                    <div className="print-logo">
                                                        <img src={shop.logoUrl} alt={shop.name} />
                                                    </div>
                                                )}
                                            </div>
                                            <p></p>
                                            <div className="content-flex space-between-start print-destination">
                                                <div>Cliente</div>
                                                {shipping.destination ? (
                                                    <div>
                                                        <div>{shipping.destination.firstName} {shipping.destination.lastName}</div>
                                                        <div>{shipping.destination.street1} {shipping.destination.street2}</div>
                                                        <div>{shipping.destination.city}</div>
                                                        <div>{shipping.destination.zipCode} {shipping.destination.state}</div>
                                                        <div>{shipping.destination.phone}</div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div>{shipping.shippingAddress.firstName} {shipping.shippingAddress.lastName}</div>
                                                        <div>{shipping.shippingAddress.address1} {shipping.shippingAddress.address2}</div>
                                                        <div>{shipping.shippingAddress.city}</div>
                                                        <div>{shipping.shippingAddress.zipCode} {shipping.shippingAddress.state}</div>
                                                        <div>{shipping.shippingAddress.phone}</div>
                                                    </div>
                                                )}
                                                <div>Enviar a</div>
                                                {shipping.destination ? (
                                                    <div>
                                                        <div>{shipping.destination.firstName} {shipping.destination.lastName}</div>
                                                        <div>{shipping.destination.street1} {shipping.destination.street2}</div>
                                                        <div>{shipping.destination.city}</div>
                                                        <div>{shipping.destination.zipCode} {shipping.destination.state}</div>
                                                        <div>{shipping.destination.phone}</div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div>{shipping.shippingAddress.firstName} {shipping.shippingAddress.lastName}</div>
                                                        <div>{shipping.shippingAddress.address1} {shipping.shippingAddress.address2}</div>
                                                        <div>{shipping.shippingAddress.city}</div>
                                                        <div>{shipping.shippingAddress.zipCode} {shipping.shippingAddress.state}</div>
                                                        <div>{shipping.shippingAddress.phone}</div>
                                                    </div>
                                                )}
                                            </div>
                                            <p></p>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Descripción</th>
                                                        <th className="text-right">SKU</th>
                                                        <th className="text-right">Precio unitario</th>
                                                        <th className="center">Cantidad</th>
                                                        <th className="text-right">Precio total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {shipping.order.lineItems.map((item, index2) => (
                                                    <tr key={index2}>
                                                        <td>{item.title}</td>
                                                        <td className="text-right">{item.sku}</td>
                                                        <td className="text-right"><CurrencyFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                                        <td className="center">{item.quantity}</td>
                                                        <td className="text-right"><CurrencyFormat value={item.price * item.quantity} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>

                                            <div className="content-flex flex-end table-subtotal">
                                                {shipping.order.note && (
                                                    <div className="print-note">
                                                        <b>Notas:</b> {shipping.order.note}
                                                    </div>
                                                )}
                                                <div>
                                                    <div className="content-flex space-between-start min-width-12 print-total"><b>Subtotal:</b> <CurrencyFormat value={shipping.order.subtotalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                                                    {shipping.order.shippingLines && shipping.order.shippingLines.length > 0 && (
                                                        <div className="content-flex space-between-start min-width-12 subtotal"><b>Envio:</b> <CurrencyFormat value={shipping.order.shippingLines[0].price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                                                    )}
                                                    <div className="content-flex space-between-start min-width-12 print-total total"><b>Total:</b> <CurrencyFormat value={shipping.order.totalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                                                </div>    
                                            </div>

                                            <div className="center">
                                                <h3>¡Gracias por tu compra!</h3>
                                                {/*<h4>Si tienes preguntas sobre tupedido, escribe a info@*****</h4>*/}
                                                <div><b>{shop.name}</b></div>
                                                <div>{shop.country} {shop.state} {shop.zip}</div>
                                                <div>{shop.shopifyDomain}</div>
                                            </div>
                                        </React.Fragment>  
                                    )}  
                                    {index < (list_shippings.length - 1) && (
                                        <p className="page-break"></p>
                                    )}
                                </div>
                            ))}
                         </div>   
                    ) : (!processed) ? (
                        <div className="center content-loading">
                            <div className="loading">
                                <div id="preloader">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div> 
                                <span>Procesando</span>
                            </div>    
                        </div>
                    ) : (
                        <div className="no-data">
                            <div className="content-flex flex-center">
                                <i className="icofont-gears"></i>
                            </div>    
                            <div className="content-flex flex-center">
                                <h2>Buscando</h2>
                            </div>      
                        </div>
                    )
                }
                </div>
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    shippings: state.shippings,
    ordens: state.ordens,
    shopState: state.shop,
    config: state.config,
    today: state.today
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiPrint);

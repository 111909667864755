import React, { Component } from 'react';


class Promo extends Component {

    
    componentDidMount() {
        
    }

    render() {
        
        const { img = "/img/cintillo-revie-2.png", url="https://apps.shopify.com/revie" } = this.props;
        return (
            <div className='promo'>
                <a href={url} target="_blank">
                    <img src={img} />
                </a>
            </div>
        );
  }
}

export default Promo;
import React, {Component} from 'react';

class Help extends Component {
  componentDidMount() {
    
  }

  render() {
    return (
      <div className="">
          <iframe src="https://triciclo.zendesk.com/hc/es-419" className="iframe-external"/>
      </div>
    );
  }
}

export default (Help);

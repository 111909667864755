import React, { Component } from 'react';

class FilterLink extends Component {

   
    render() {
        const { varFilter, onClick, filterSet, title } = this.props;
        return (
                <li>
                    <a className={`${varFilter === filterSet ? "active" :""}`} role="tab" aria-label={title} onClick={ () => onClick(filterSet)}>
                        {title}
                    </a>
                </li>
        );
    }
}

export default FilterLink;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputHtml from '../components/html/InputHtml';

import * as Constants from './../store/index';
import * as Util from './../data/General';
import Axios from 'axios';
import Alert from '../components/html/Alert';
import Dinamics from './Dinamics';

class Config extends Component {

    state = { 
        carriersList:[],
        configList: null,
        errorText: "",
        successText: "",
        isSearch: false,
        carriersSelected: -1

    };

    componentDidMount() {

        this.getCarriers();
        this.getConfig();
    }

    getCarriers = () => {
        this.setState({ isSearch: true, errorText: ""});
        const url = Constants.URL_API+"shippings/carriers/data";
        Axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            console.log(error.response);
            if (error.response && (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404)) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message), isSearch: false});
            }
            return error;
          }).then((data) => {
            console.log(data);
            this.setState({carriersList: data, isSearch: false});
            
        });
        return false;
    }

    getConfig = () => {
        this.setState({ isSearch: true, errorText: ""});
        const url = Constants.URL_API+"customers/settings";
        Axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            console.log(error.response);
            if (error.response && (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404)) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message), isSearch: false});
            }
            return error;
          }).then((data) => {
            console.log(data);
            this.setState({configList: data, isSearch: false});
            
        });
        return false;
    }

    setDataConfig = () => {
        const { configList } = this.state;
        this.setState({ isSearch: true, errorText: ""});
        

        let body = configList;
         
        const url = Constants.URL_API+"customers/settings";
        console.log(url);
        Axios.post(url, body, Constants.getHeaders()).then(response => response.data,error => {
            //Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            if (data.id){
                this.setState({successText: "Se actualizo con exito la configuración ", isSearch: false});
                this.getCarriers();
            }else{
                this.setState({errorText: data, isSearch: false});
            }
        }); 
    }

    editConfigList = (value, key) => {
        const { configList } = this.state;
        console.log(value);
        configList[key] = value[key];

        console.log(configList);
        this.setState({configList: configList});
    }

    editConfig = (key, value) => {
        const { carriersList, carriersSelected } = this.state;
        if (value){
            if (carriersSelected !== -1){
                carriersList[carriersSelected]["edit"] = false;
            }
        }
        carriersList[key]["edit"] = value;
        this.setState({carriersList: carriersList, carriersSelected: key});
    }

    handleChange = (value, name) =>{
        const { carriersList, carriersSelected } = this.state;
        carriersList[carriersSelected][name] = value;
        this.setState({
            carriersList: carriersList
        });
    }

    editCarries = (values, keys) => {
        const { carriersList, carriersSelected } = this.state;
        carriersList[carriersSelected].data = values;
        console.log(carriersList[carriersSelected]);
        this.setState({carriersList: carriersList});
    }

    setDataCarriers = () => {
        const { carriersList, carriersSelected } = this.state;
        this.setState({ isSearch: true, errorText: ""});
        

        let body = carriersList[carriersSelected];
         
        const url = Constants.URL_API+"shippings/carriers/"+body.id+"/data";
        console.log(url);
        Axios.put(url, body, Constants.getHeaders()).then(response => response.data,error => {
            //Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            if (data.id){
                this.setState({successText: "Se actualizo con exito la transportadora " + Util.CARRIERS_INFO[data.id].name, isSearch: false});
                this.getCarriers();
            }else{
                this.setState({errorText: data, isSearch: false});
            }
        }); 
    }


    render() {
        const { errorText, carriersList, successText, isSearch, configList } = this.state;
        const { config, shopState } =  this.props;
        return (
            <div className="preferences orden">
                <div className="title">
                    <h1>
                        Preferencias
                    </h1>
                </div>
                <Alert errorText={errorText} successText={successText}/>
                
                <div className="content-flex">
                    <div className="content-widget preferences-detail">
                        <h3>Configuración de transportadoras</h3>
                        <hr></hr>
                        {isSearch && ( 
                            <div className="center content-loading witch-table">
                                <div className="loading">
                                    <div id="preloader">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div> 
                                    <span>Cargando transportadoras</span>
                                </div>    
                            </div>
                        )}
                        <table>
                            <tbody>
                                {carriersList && carriersList.map((data, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td className="center">
                                                <img src={`/img/${Util.CARRIERS_INFO[data.id].img}`} className="load-img-carriers"></img>
                                            </td>
                                            <td>
                                                {Util.CARRIERS_INFO[data.id].name}
                                            </td>
                                            <td>
                                                <div className="label">
                                                    {data.edit && "Comisión:"} 
                                                    <InputHtml 
                                                        required={true} 
                                                        type="text" 
                                                        edit={data.edit} 
                                                        onBlur={this.handleChange} 
                                                        name="commission" 
                                                        value={data.commission}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                {!data.edit && (
                                                    <button className="btn btn-light" onClick={() => this.editConfig(index, true)}>Editar</button>
                                                )}
                                            </td> 
                                        </tr>
                                        {data.edit && (
                                            <tr className="no-background">
                                                <td colSpan="4">
                                                    <Dinamics 
                                                        listConfig={Util.CARRIERS_INFO[data.id].configAdmin} 
                                                        edit={true} 
                                                        values={data.data} 
                                                        onchange={this.editCarries}
                                                        keys={index}
                                                    /> 
                                                    <div className="content-flex flex-end spacing-small-top">
                                                        <button onClick={() => this.editConfig(index, false)} className="btn btn-link">Cancelar</button>
                                                        <button onClick={() => this.setDataCarriers()} className="btn triciclo">Guardar</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        
                    </div>
                    <div className="content-widget client label preferences-install-help">
                        <h3>Configuración</h3>
                        <hr></hr> 
                        {configList && (
                            <Dinamics 
                                listConfig={Util.configAdmin} 
                                edit={true} 
                                values={configList} 
                                onchange={this.editConfigList}
                                keys="initialQuota"
                            /> 
                        )}

                        <div className="content-flex flex-end spacing-small-top">
                            <button onClick={() => this.setDataConfig()} className="btn triciclo">Guardar</button>
                        </div>
                    </div>    
                </div>
            </div>
            );
        }
    }

    
const mapStateToProps = (state) => ({
    packageList: state.packageList,
    shopState: state.shop,
    user: state.user,
    ordens: state.ordens,
    shippings: state.shippings,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Config);
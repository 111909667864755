import React, { Component } from 'react';
import SelectHtml from './SelectHtml';
import axios from "axios";
import * as Constants from './../../store/index';
import Alert from '../../components/html/Alert';

class OrderStages extends Component {

    state = {
        value: "-1",
        message: "",
        error: "",
        loading: false
    }

    componentDidMount() {
        const { value } = this.props;
        if (value && value.id){
            this.setState({value: value.id});
        }
    }

    setStages = (value, name) => {
        const { order, options } = this.props;
        this.setState({ loading: true});
        const url = Constants.URL_API+"orders/"+order.id+"/stages";
        let stage = {
            "id": value,
            "name": options[value]
        };
        console.log(url);
        axios.post(url, stage, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            this.setState({error: error, loading: false});
            return error;
          }).then((data) => {
            console.log(data);
            if (data && data.stage && data.stage.id){
                this.setState({value: data.stage.id, message: "Estado actualizado", loading: false});
            }
        }); 
    }

    render() {
        const { options } = this.props;
        const { value, loading, message, error } = this.state;
        
        return (
            <div className="stage">
                <SelectHtml edit={true} options={options} onChange={this.setStages} name="type" value={value} loading={loading}/>    
                <Alert successText={message} error={error} />
            </div>       
        );
  }
}

export default OrderStages;
import React, { Component } from 'react';



class SelectImage extends Component {
    state = { value: "no-photo.png", mError:"", visible: false };

    componentDidMount() {
        const { value, prefijo } = this.props;
        if (value){
            this.setState({
                value: value.replace(prefijo, '')
            });
        }
    }


    onChange = (value) => {
        const { onBlur, onChange, prefijo } = this.props;
        this.setState({
            value: value, visible: false
        });
        if (onBlur){
            onBlur(value);
        }

        if (onChange){
            onChange(prefijo+value);
        }
        
    }
  
    render() {
        const { options, prefijo } = this.props;
        const { mError, visible, value } = this.state;

        return (
            <div className="select-image">
                <label className="select-image-icon">
                    <img src={`${prefijo}${value}`} />
                </label>
                {visible && (
                    <div className="select-image-list">
                        {options && options.map(
                            (option, index) => 
                                <div key={index} className={`${value === option ? "active" : ""}`} onClick={() => this.onChange(option)}>
                                    <img src={prefijo+option} />
                                </div>    
                            )}
                        {mError !== "" && (<div className="error">{mError}</div>)}
                    </div>    
                )}  
                <button onClick={() => this.setState({visible: !visible})} className="btn triciclo" disabled={visible ? true : false}>
                    Cambiar
                </button>
            </div> 
        );
  }
}

export default SelectImage;
import React, { Component } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

import Geocode from "react-geocode";

const style = {
    width: '100%',
    height: '100%',
    position: "relative"
  }

class LocalMap extends Component {
    state = {
        listPoints: []
    }

    componentDidMount() {
        const { points } = this.props;
        Geocode.setApiKey("AIzaSyC4j-H-7go151nnW0Qn9xwcL3014mT_iNo");
        console.log(points);
        /*for (var i in points) {
            let point = points[i];
            console.log(point);
            console.log(point.address);
            Geocode.fromAddress(point.address).then(
                response => {
                    console.log(response.results[0].geometry.location);
                  const { lat, lng } = response.results[0].geometry.location;
                  const { listPoints } =this.state;
                  listPoints.push({lat: lat, lng: lng});
                  console.log(lat, lng);
                  console.log(listPoints);
                  this.setState({listPoints: listPoints});
                },
                error => {
                  console.error(error);
                }
              );
        }*/
        this.getLatLng(points, 0);
    }

    getLatLng = (list, id ) => {
        if (list.length > id){
            let point = list[id];
            Geocode.fromAddress(point.address).then(
                response => {
                    console.log(response.results[0].geometry.location);
                  const { lat, lng } = response.results[0].geometry.location;
                  const { setPoints } = this.props;
                  setPoints(id, lat, lng);
                  console.log(lat, lng);
                  //console.log(listPoints);
                  console.log(id);
                  id = id + 1;
                  this.getLatLng(list, id);
                },
                error => {
                  console.error(error);
                }
              );
        }
    }

    render() {
        const { points } = this.props;
        /*var points = [
            { lat: 42.02, lng: -77.01 },
            { lat: 42.03, lng: -77.02 },
            { lat: 41.03, lng: -77.04 },
            { lat: 42.05, lng: -77.02 }
        ]
        var bounds = new this.props.google.maps.LatLngBounds();
        for (var i = 0; i < listPoints.length; i++) {
          bounds.extend(listPoints[i]);
        }*/
        console.log(points);
        return (
            <div className="map">
                {points.length > 0 && (
                    <Map 
                        google={this.props.google} 
                        zoom={11}
                        style={style}
                        initialCenter={{
                            lat: "19.390519",
                            lng: "-99.2837307"
                        }}
                        onClick={this.onMapClicked}
                    >
                        {points.map((item, index) => (
                                
                                    <Marker
                                        title={'The marker`s title will appear as a tooltip.'}
                                        name={index}
                                        position={{lat: item.lat, lng: item.lng}} 
                                        icon= {`http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${index+1}|FE6256|000000`}
                                        
                                    />
                            ))}
                        {/*<Marker
                            title={'The marker`s title will appear as a tooltip.'}
                            name={'SOMA'}
                            position={{lat: 37.778519, lng: -122.405640}} />
                        <Marker
                            name={'Dolores park'}
                            position={{lat: 37.759703, lng: -122.428093}} />
                        <Marker />
                        <Marker
                            name={'Your position'}
                            position={{lat: 37.762391, lng: -122.439192}}
                        />/*}
        
                        {/*<Marker onClick={this.onMarkerClick}
                                name={'Current location'} />
                
                        <InfoWindow onClose={this.onInfoWindowClose}>
                            <div className="map2">
                            <h1>Titulo</h1>
                            </div>
                    </InfoWindow>*/}
                    </Map>  
                )}
            </div>
        );
  }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyC4j-H-7go151nnW0Qn9xwcL3014mT_iNo")
  })(LocalMap)
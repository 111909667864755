import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from './../store/index';
import Page from '../components/html/Page';
import CurrencyFormat from 'react-currency-format';
import * as Util from './../data/General';

class Billing extends Component {

    state = {
        
    };

    
    componentDidMount() {
        
    } 
  
    render() {
        
        return (
            <div className="recharge orden">
                <div className="content-popup">
                    <iframe src="https://triciclogo.com/pages/genera-tu-factura" /> 
                </div>    
            </div>
        );
        }
    }
const mapStateToProps = (state) => ({
    shop: state.shop,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Billing);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';

import axios from "axios";

import * as Constants from './../store/index';
import Alert from '../components/html/Alert';
import * as Util from './../data/General';
import DashboardCards from '../components/widget/DashboardCards';
import Filter from '../components/html/Filter';
import UploadCSV from '../components/html/UploadCSV';

import InputHtml from '../components/html/InputHtml';
import SelectHtml from '../components/html/SelectHtml';
import Promo from '../components/html/Promo';


class Dashboard extends Component {
  
  state = {
    isSearch: false,
    filter : {
      fromDate: "",
      toDate: "",
    },
    textFilter:"",
    step: 0,
    isFilterSategs: false,
    costCarrierSum: -1,
    carriersStatistics: null,
    errorText: "",
    successText: "",
    customerId:"",
  };

  componentDidMount() {
    const { filter } = this.state;
    const { lastWeek, today, shop, user } = this.props;
    filter.fromDate = lastWeek;
    filter.toDate = today;
    this.loadData(filter);
    this.loadDataDonut(filter);
    this.loadCardsData(filter);
    this.loadExtraData();
    this.loadStagesData(filter);
    this.checkPermission(shop);
    

    setTimeout(function(){
      Util.loadDataUsers(this.props.dispatch, false);
      if (shop && shop.length > 0 && shop[0].id){
        Util.checkSeeBalance(shop[0].id);
      }
    }.bind(this), 300);

    if (user.type === "ADMIN"){
      this.loadDataCarrier(filter);
    }

  }

  checkPermission = (shop) => {
    if(shop && shop.length > 0 && shop[0].myshopifyDomain){
      let check = "checkPermission_fulfillment_orders"+shop[0].myshopifyDomain;
      let install = localStorage.getItem(check);
      if (install && install === "true"){
        console.log("checkPermission",install);
      }else{
          this.setState({ isSearch: true});
          const url = Constants.URL_API+"/orders/permissions?permission=fulfillment_orders";
          console.log(url);
          axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          })
          .then((data) => {
            console.log("checkPermission",data);
            if(data && data.enabled){
              localStorage.setItem(check, "true");
            }else{
              let text = "Para continuar funcionando correctamente, debemos actualizar los permisos de la aplicación en Shopify";
              if (window.confirm(text) == true) {
                window.location = "https://shopify.triciclogo.com/update/?shop="+shop[0].myshopifyDomain;
              }
            }
            
          }).catch(error => {
            console.log(error);
          }); 
        }
    }
  };

  loadStagesData = (filter) => {
    this.setState({ isSearch: true});
    const url = Constants.URL_API+"orders/stages/stagestatistics"+Constants.getFilter(filter);
    console.log(url);
    axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      Constants.setErrors(error);
      return error;
    })
    .then((data) => {
      console.log(data);
      let labels = [];
        let series = [];
        let count = 0;
        for (let i = 0; i < data.length; i++) {
          let element = data[i];
          labels.push(element.key);
          series.push(element.count);
          count = count + element.count;
        }
        let dataChart = {
          data: series
        };
        let seriesChart=[];
        seriesChart.push(dataChart);
        this.props.dispatch({
            type: 'CHANGE_CHART_STAGES',
            labels: labels,
            series: seriesChart
        }); 

        this.setState({isFilterSategs: (count === 0 ? false : true)});
    }).catch(error => {
      console.log(error);
    }); 
  };
  

  getStep = () =>{
    const { shop, packageList } = this.props;
    let step = -1;
    if (!Array.isArray(shop)){
      step = 0;
    }else{
      if (shop.length > 0 && (!shop[0].logoUrl || shop[0].logoUrl === "")){
        step = 3;
      }else if (!packageList || !packageList.list || packageList.list.length === 0 ){
        step = 4;
      }else if (shop[0].quota === 0){
        step = 5;
      }
    }
    console.log(step);
    this.setState({ isSearch: false});
    return step;
  }

  loadExtraData = () =>{
    this.setState({ isSearch: true});
    const { packageList } = this.props;
    setTimeout(function(){
      Util.loadLayouts(this.props.dispatch, Constants.getFilter(packageList.filter) ,this.loadExtraDataFinish);
    }.bind(this), 50);
  }
  loadExtraDataFinish = () => {
    setTimeout(function(){
      this.setState({step : this.getStep()});;
    }.bind(this), 200);
  }

  setSortOrderText = ( text ) => {
    this.setState({textFilter : text});
  }
  setSortOrder = (sort) => {
    const { filter } = this.state;
    if (sort !== ""){
        let date = sort.split("*");
        filter.fromDate = date[0];
        filter.toDate = date[1];
        this.sort(filter);
    }else{
        filter.fromDate = "";
        filter.toDate = "";
        this.sort(filter);
    }
}

sort = (filter) =>{
  const { user } = this.props;
  this.loadData(filter);
  this.loadCardsData(filter);
  this.loadDataDonut(filter);
  this.loadStagesData(filter);
  if (user.type === "ADMIN"){
    this.loadDataCarrier(filter);
  }
}

  loadData = (filter) => {
      this.setState({ isSearch: true});
      const url = Constants.URL_API+"shippings/intervalstatistics"+Constants.getFilter(filter);
      console.log(url);
      Constants.scrollToWindow();
      axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
        Constants.setErrors(error);
        return error;
      })
      .then((data) => {
          console.log(data);
          let labels = [];
          let series = [];
          for (let i = (data.length-1); i >= 0; i--) {
            let element = data[i];
            labels.unshift(Constants.getDateWithoutTime(element.key));
            series.unshift(element.count);
          }
          this.props.dispatch({
              type: 'CHANGE_CHART_BAR',
              labels: labels,
              series: series
          });
          this.setState({ isSearch: false});
      }).catch(error => {
        console.log(error);
    }); 
  }

  loadDataCarrier = (filter) => {
    this.setState({ isSearch: true});
    const url = Constants.URL_API+"shippings/carrierstatistics"+Constants.getFilter(filter);
    console.log(url);
    Constants.scrollToWindow();
    axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      Constants.setErrors(error);
      return error;
    })
    .then((data) => {
        console.log(data);

        let labels = [];
        let series = [];
        for (let i = (data.length-1); i >= 0; i--) {
          let element = data[i];
          labels.push(element.key);
          series.push(element.count);
        }

        let carriersStatistics={
          options: {
            chart: {
              id: 'chart'
            },
            xaxis: {
              categories: labels
            }
          },
          series: [{
            name: 'Ordenes',
            data: series
          }],
        };
        console.log(carriersStatistics);

        this.setState({carriersStatistics: carriersStatistics});
        
    }).catch(error => {
      console.log(error);
  }); 
}

  loadCardsData = (filter) => {
    this.setState({ isSearch: true});
    const url = Constants.URL_API+"shippings/statistics"+Constants.getFilter(filter);
    console.log(url);
    Constants.scrollToWindow();
    axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      Constants.setErrors(error);
      return error;
    })
    .then((data) => {
      console.log(data);
      this.costAvg = data.costAvg;
      this.props.dispatch({
        type: 'CHANGE_DASHCARDS',
        costAvg : data.costAvg,
        deliveryDaysAvg : data.deliveryDaysAvg,
        count : data.count,
        costSum : data.costSum
      });

      if (data.costCarrierSum){
        this.setState({costCarrierSum: data.costCarrierSum});
      }
  }).catch(error => {
    console.log(error);
}); 

}
  handleFilter = (value, name)=> {
    const { dashFilter } = this.state;
    dashFilter[name] = value;
    this.setState({
      dashFilter: dashFilter
    });
    console.log(dashFilter);
  }

  loadDataDonut = (filter) => {
    this.setState({ isSearch: true});

    var url = Constants.URL_API+"shippings/statusstatistics"+Constants.getFilter(filter);
    console.log(url);
    axios.get(url, Constants.getHeaders()).then(response => response.data)
    .then((data) => {
        console.log(data);

        let labels = [];
        let series = [];
        for (let i = 0; i < data.length; i++) {
          let element = data[i];
          labels.push(Constants.status[element.key]);
          series.push(element.count);
        }
        let dataChart = {
          data: series
        };
        let seriesChart=[];
        seriesChart.push(dataChart);
        this.props.dispatch({
            type: 'CHANGE_CHART_DONUT',
            labels: labels,
            series: seriesChart
        });
        this.setState({ isSearch: false});
    }); 
  
}


downloadCSV = () => {
  this.setState({ isSearch: true});
  var url = "";
  url = Constants.URL_API+"shippings/shippings/downloadcsv";
  
  console.log(url);
  Constants.scrollToWindow();
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
    Constants.setErrors(error);
    return error;
  })
  .then((data) => {
    console.log(data);
    this.costAvg = data.costAvg;
    this.setState ({
      costAvg : data.costAvg,
      deliveryDaysAvg : data.deliveryDaysAvg,
      count : data.count,
      costSum : data.costSum
    })
    this.props.dispatch({
      type: 'CHANGE_DASHCARDS',
      costAvg : data.costAvg,
      deliveryDaysAvg : data.deliveryDaysAvg,
      count : data.count,
      costSum : data.costSum
    });
}).catch(error => {
  console.log(error);
}); 
}


  donwload = () => {
    this.setState({ isSearch: true});
    const { filter } = this.state;
    const url = Constants.URL_API+"shippings/downloadcsv"+Constants.getFilter(filter);
    axios.get(url, Constants.getHeaders({"Content-Type": "application/octet-stream", Accept: "application/json, text/plain, */*; charset=ISO-8859-1"})).then(response => response.data)
    .then((data) => {
        var universalBOM = "\uFEFF";
        var a = window.document.createElement('a');
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+data));
        a.setAttribute('download', `export${filter.fromDate}-${filter.toDate}.csv`);
        window.document.body.appendChild(a);
        a.click();
        this.setState({ isSearch: false});
        
    }); 
  }

  cleanSuccess = () => {
    setTimeout(function(){
      this.setState({ successText: ""});
    }.bind(this), 6000);
  }

  donwloadCost = () => {
    this.setState({ isSearch: true});
    const { filter } = this.state;
    const url = Constants.URL_API+"customers/shopreport"+Constants.getFilter(filter);
    axios.post(url, {}, Constants.getHeaders()).then(response => response.data,error => {
      Constants.setErrors(error);
      this.setState({error: error, loading: false});
      return error;
    }).then((data) => {
      console.log(data);
      this.setState({ successText: "Este proceso demora unos minutos, al finalizar te enviaremos el documento al correo: go@triciclo.mx", isSearch: false});
      this.cleanSuccess();
    }); 

    //axios.get(url, Constants.getHeaders({"Content-Type": "application/octet-stream", Accept: "application/json, text/plain, */*; charset=ISO-8859-1"})).then(response => response.data)
    /*.then((data) => {
        var universalBOM = "\uFEFF";
        var a = window.document.createElement('a');
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+data));
        a.setAttribute('download', `export${filter.fromDate}-${filter.toDate}.csv`);
        window.document.body.appendChild(a);
        a.click();
        this.setState({ isSearch: false});
        
    }); */
  }

  donwloadCorriers = () => {
    this.setState({ isSearch: true});
    const { filter } = this.state;
    const url = Constants.URL_API+"shippings/downloadcarriercsv"+Constants.getFilter(filter);
    axios.get(url, Constants.getHeaders({"Content-Type": "application/octet-stream", Accept: "application/json, text/plain, */*; charset=ISO-8859-1"})).then(response => response.data)
    .then((data) => {
        var universalBOM = "\uFEFF";
        var a = window.document.createElement('a');
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+data));
        a.setAttribute('download', `export${filter.fromDate}-${filter.toDate}.csv`);
        window.document.body.appendChild(a);
        a.click();
        this.setState({ isSearch: false});
        
    }); 
  }

  preferences = () => {
    window.location.href = "/preferences";
  };

  packings = () => {
    window.location.href = "/packings";
  };

  recharge = () => {
    window.location.href = "/recharge";
  };

  orders = () => {
    window.location.href = "/orders";
  };

  upload = (data) => {
    this.setState({successText: "", errorText: ""});
    if (data === ""){
        this.setState({successText: "Archivo subido correctamente."});
        this.cleanSuccess();
    }else{
        this.setState({errorText: data});
    }
  }

  handleFilterAdmin = (value, name) =>{
    const { filter } = this.state;
    filter[name] = value;
    this.setState({filter: filter});
    this.sort(filter);
  }

  handleChangeCancel = (value, name) => {
    this.setState({carrierCancel: value});

  }

  updateCustomer = (value, name) =>{
    this.setState({guiaCancel: value});
}

cancelOrder = () => {
  this.setState({ isSearch: true, errorText: "", successText: ""});
  const { guiaCancel, carrierCancel } = this.state;
  const url = Constants.URL_API+"shippings/canceladmin";
  let data={
    "tracker": guiaCancel,
    "carrierId": carrierCancel
  };
  axios.post(url, data, Constants.getHeaders()).then(response => response.data,error => {
    console.log(error.response);
    if (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404) {
        this.setState({ errorText: Constants.getMessage(error.response.data.message), isSearch: false});
    }
    return error;
  }).then((data) => {
    this.setState({ successText: "Guía cancelada", isSearch: false});
    this.cleanSuccess();
    
});
}


  render() {
    const { isSearch, textFilter, step, isFilterSategs, costCarrierSum, guiaCancel, carriersStatistics, errorText,  successText, carrierCancel } = this.state;
    const { chart_bar, chart_donut, lastWeek, today , dashData, chart_stages, user } =this.props;
    return (
      <div className="about">
        {isSearch && ( 
            <div className="center content-loading">
                <div className="loading">
                    <div id="preloader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div> 
                    <span>Cargando datos</span>
                </div>    
            </div>
        )} 
        {step > 2 && (
          <div className="content-widget space-between">
            <div className="dashboard-video">
              <iframe 
                src="https://player.vimeo.com/video/586121965?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=7cb026190c" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                title="TricicloGO - &amp;iquest;Como generar tu primer envío? 2">
                </iframe>
                <div>
                  <h1>Bienvenido a TricicloGO</h1>
                  <p>A partir de ahora, tendrás acceso a:</p>
                  <p>• Tarifas de envío preferenciales con Fedex, DHL, Estafeta y Redpack</p>
                  <p>• Envíos última milla en CDMX desde $35</p>
                  <p>• Recolecciones en tu domicilio</p>
                  <p>• Seguimiento en tiempo real y seguro de protección de tus envíos</p>
                  <p>• Notificaciones automáticas para tus clientes sobre el estado de su pedido</p>
                  <h5><b>Activa tu cuenta y genera tu primer envío</b></h5>
                  {/*<button 
                    onClick={() => this.orders()} 
                    className="btn triciclo"
                    >
                      <i className="icofont-box"></i>&nbsp; 
                      Ir a Ordenes
        </button>*/}
                <a className="btn triciclo" href='https://wa.me/524491112091?text=Quiero%20activar%20mi%20cuenta%20de%20GO%20Shipping' target="_blank" >Solicitar Activación</a>
                </div>
              </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        )}
        <Promo />
        { false && (
          <div className="content-widget">
            <h3>
              Instrucciones de configuración:
            </h3>
            <h5>Empieza a utilizar la plataforma en 5 sencillos pasos</h5>
            <div>
              <ul className="steps is-narrow is-medium">
                <li className="steps-segment">
                  <div className="steps-content top">
                    <p className="is-size-4">Paso 1</p>
                  </div>
                  <a href="#" className="steps-marker"><i className="icofont-user"></i></a>
                  <div className="steps-content">
                    <p>Crear usuario.</p>
                  </div>
                </li>
                <li className={`steps-segment ${step === 3 ? "is-active" : ""}`}>
                  <div className="steps-content top">
                    <p className="is-size-4">Paso 2</p>
                  </div>
                  <a href="#" className="steps-marker"><i className="icofont-grocery"></i></a>
                  <div className="steps-content">
                    <p>Vincular tienda con shopify.</p>
                  </div>
                </li>
                <li className={`steps-segment ${step === 4 ? "is-active" : (step === 3) && "is-current"}`}>
                  <div className="steps-content top">
                    <p className="is-size-4">Paso 3</p>
                  </div>
                  <span className="steps-marker is-current"><i className="icofont-settings"></i></span>
                  <div className="steps-content">
                    <p>Ingresa en la <a href="/preferences" title="sección Preferencias">sección Preferencias</a>, agrega el logo de tu tienda y personaliza tu configuración.</p>
                  </div>
                </li>
                <li className={`steps-segment ${step === 5 ? "is-active" : (step === 4) && "is-current"}`}>
                  <div className="steps-content top">
                    <p className="is-size-4">Paso 4</p>
                  </div>
                  <span className="steps-marker"><i className="icofont-box"></i></span>
                  <div className="steps-content">
                    <p>En la <a href="/packings" title="sección Empaques">sección Empaques</a>, agrega la medida y peso de los empaques que más utilizas.</p>
                  </div>
                </li>
                {Util.isSeeBalance() && (
                  <li className={`steps-segment ${step === 6 ? "is-active" : (step === 5) && "is-current"}`}>
                    <div className="steps-content top">
                      <p className="is-size-4">Paso 5</p>
                    </div>
                    <span className="steps-marker"><i className="icofont-dollar-plus"></i></span>
                    <div className="steps-content">
                      <p>En la <a href="/recharge" title="sección Recarga de Saldo">sección Recarga de Saldo</a>, realiza tu primer pago para empezar a generar guías de envío.</p>
                    </div>
                  </li>
                )}
              </ul>
            </div>   
            {step === 3 ? (         
              <button 
                onClick={() => this.preferences()} 
                className="btn triciclo"
                >
                  <i className="icofont-settings"></i>&nbsp; 
                  Ir a preferencias
              </button>
            ) : step === 4 ? (         
              <button 
                onClick={() => this.packings()} 
                className="btn triciclo"
                >
                  <i className="icofont-box"></i>&nbsp; 
                  Ir a empaques
              </button>
            ) : step === 5 && (         
              <button 
                onClick={() => this.recharge()} 
                className="btn triciclo"
                >
                  <i className="icofont-dollar-plus"></i>&nbsp; 
                  Ir a recarga de saldo
              </button>
            )}

          </div>
        )}
        
        <DashboardCards textFilter={textFilter} count= {dashData.count} costSum = {dashData.costSum} costAvg = {dashData.costAvg} deliveryDaysAvg = {dashData.deliveryDaysAvg} costCarrierSum={user.type === "ADMIN" ? costCarrierSum : -1} />
        
        <div className="content-widget">
          <h2>
            <i className="icofont-grocery"></i> Envios
            {user.type === "ADMIN" && (
              <button 
                onClick={() => this.donwloadCost()} 
                className="btn triciclo right"
              ><i className="icofont-download"></i> Descargar CSV de Costos</button> 
            )}
          </h2>
          <Filter handleSortDateText={this.setSortOrderText} isHome={true} handleFilterAdmin={this.handleFilterAdmin} handleSortDate={this.setSortOrder} timeInitial="" timeFinish="" initialDate={lastWeek} finishDate={today}/>
          <div className="content-flex flex-center flex-two-element">
            <div className="chart-donut">
              <Chart options={chart_donut.options} series={chart_donut.series} type="bar" height={300} />
            </div>
            <Chart options={chart_bar.options} series={chart_bar.series} type="bar" height={300} />
          </div>
          {carriersStatistics && (
            <div className="content-flex flex-center flex-two-element">
              <div className="chart-donut">
                <Chart options={carriersStatistics.options} series={carriersStatistics.series} type="bar" height={300} />
              </div>
            </div>
          )}

          <button 
              onClick={() => this.donwload()} 
              className="btn triciclo right"
          ><i className="icofont-download"></i> Descargar CSV</button>
        </div>  
        {isFilterSategs && (
          <div className="content-widget">
            <h2><i className="icofont-bag"></i> Estados</h2>
            <div className="content-flex flex-center flex-two-element">
              <div className="chart-donut">
                <Chart options={chart_stages.options} series={chart_stages.series} type="bar" height={300} />
              </div>
            </div>
          </div>  
        )}

        {user.type === "ADMIN" && (
          <div className="content-widget preferences-detail">
            <h1>Guías</h1>
            <Alert errorText={errorText} successText={successText} className="alert-fixed"/>
            <div className="content-flex space-between">
              <div className="content-widget preferences-detail">
                  <h3>Subir costo de Guías</h3>
                  <hr></hr>

                  <UploadCSV upload={this.upload} />
                  
                  
              </div>
              <div className="content-widget preferences-detail">
                  <h3>Descargue el CSV</h3>
                  <hr></hr>
                  <button 
                    onClick={() => this.donwloadCorriers()} 
                    className="btn triciclo right"
                  >
                    <i className="icofont-download"></i> 
                    Descargar CSV 
                  </button> 
              </div>
              <div className="content-widget preferences-detail">
                  <h3>Cancelar Guía</h3>
                  <hr></hr>
                  <div>
                      <SelectHtml edit={true} options={Util.CARRIERS_FILTER} required={true} onChange={this.handleChangeCancel} name="carrierId" value={carrierCancel}/>
                  </div>
                  <p></p>
                  <div>
                      <InputHtml type="search" edit={true} name="guiaCancel" value={guiaCancel} onBlur={this.updateCustomer} placeholder="Número de Guía"/>
                  </div>
                  <p></p>
                  <button 
                    onClick={() => this.cancelOrder()} 
                    className="btn triciclo right"
                  >
                    <i className="icofont-close-squared"></i> 
                    Cancelar
                  </button> 
              </div>
            </div>   
          </div>
        )}
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  chart_bar : state.chart_bar,
  chart_donut: state.chart_donut,
  shop: state.shop,
  lastWeek: state.lastWeek, 
  today: state.today,
  dashData : state.dashData,
  ordens: state.ordens,
  packageList: state.packageList,
  chart_stages: state.chart_stages,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

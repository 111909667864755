import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from './../store/index';
import FilterLink from "./../components/html/FilterLink";
import { Link  } from 'react-router-dom';
import GetShipping from '../components/html/GetShipping';
import ShippingInformation from '../components/html/ShippingInformation';
import GetUser from '../components/html/GetUser';
import Page from '../components/html/Page';
import CurrencyFormat from 'react-currency-format';
import CheckboxUnit from '../components/html/CheckboxUnit';
import Filter from '../components/html/Filter';
import * as Util from './../data/General';
import OrderStages from '../components/html/OrderStages';
import SelectHtml from '../components/html/SelectHtml';
import Promo from '../components/html/Promo';

class ListOrders extends Component {

    state = {
        isSearch: true,
        isScroll: false,
        totalSelected: 0,
        totalLocalSelected : 0,
        sortList:[
            {value:"id*desc", title:"ID Desendente"},
            {value:"id*asc", title:"ID Ascendente"},
            {value:"name*desc", title:"Nombre Desendente"},
            {value:"createdAt*desc", title:"Creación Desendente"},
            {value:"createdAt*asc", title:"Creación Ascendente"},
            {value:"updatedAt*desc", title:"Actualización Desendente"},
            {value:"totalPrice*desc", title:"Total Desendente"},
            {value:"totalPrice*asc", title:"Total Ascendente"},
            {value:"totalDiscounts*desc", title:"Descuento Desendente"},
            {value:"totalDiscounts*asc", title:"Descuento Ascendente"},
            {value:"shippingAddress.zip*desc", title:"Codigo Postal Desendente"},
            {value:"shippingAddress.zip*asc", title:"Codigo Postal Ascendente"}

        ],
        shopsCustomerId: null,
        optionsStages: null,
        stagesOptionsFilter: null,
        filterStages: -1,
        myTimeout: null
    };

    componentDidMount() {
        const { ordens, shop } = this.props;

        this.loadData(Constants.filterOrdens);
        if (ordens && ordens.list && ordens.list.length > 0){
            document.getElementById('scroll-table').addEventListener("scroll", this.updateScroll);
        }
        this.setState({shopsCustomerId: Util.getListCustomerId(shop)}); 

        setTimeout(function(){
            this.loadDataStages();
          }.bind(this), 200);
    }

    updateScroll = () =>{
        const { isScroll } =this.state;
        if (document.getElementById('scroll-table').scrollLeft > 0){
            this.setState({ isScroll: true});
        }else if(isScroll){
            this.setState({ isScroll: false});
        }
    }

    loadData = (filter) => {
        this.setState({ isSearch: true});
        const { config } = this.props;
        filter.size = config["sizeOrdens"];

        const url = Constants.URL_API+"orders"+Constants.getFilter(filter);
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            this.props.dispatch({
                type: 'CHANGE_LIST_ORDENS',
                orders: data,
                filter: filter
            });
            this.setState({ isSearch: false});
        }); 
    }

    loadDataStages = () => {
        this.setState({ isSearch: true});
        
        const url = Constants.URL_API+"orders/stages/summary";
        console.log(url);
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
                console.log(data);
                if (data && data.length > 0){
                    let stagesOptions = null;
                    let stagesOptionsFilter = null;
                    
                    for (var i = 0; i < data.length; i++) {
                        let stage = data[i];
                        if (stage.active == true){
                            if (stagesOptions === null){
                                stagesOptions= {};
                                stagesOptions["-1"] = "Sin estado";
                            }
                            stagesOptions[stage.id] = stage.name;
                        }
                    }
                    if (stagesOptions !== null){
                        stagesOptionsFilter = Object.assign({} , stagesOptions);
                        stagesOptionsFilter["-1"] = "Estados"; 
                    }
                    this.setState({ optionsStages: stagesOptions, stagesOptionsFilter: stagesOptionsFilter, isSearch: false});
                }else{
                    this.setState({ isSearch: false});
                }
            //this.setState({ optionsStages: data, isSearch: false});
        }); 
    }

    changePage = (page) => {
        this.loadData(page);
    }
    
    setText = (e) => {
        const { ordens } = this.props;
        ordens.filter = this.clearFilter(ordens.filter);
        let value = e.target.value;
        ordens.filter.text = value;
        
        let { myTimeout } = this.state;
        if (myTimeout) {
            clearTimeout(myTimeout);
        }
        myTimeout = setTimeout(function(){
            this.loadData(ordens.filter);
        }.bind(this), 800);
        this.setState({myTimeout: myTimeout});
    }
    
    setSortOrder = (sort) => {
        const { ordens } = this.props;
        ordens.filter = this.clearFilter(ordens.filter);
        if (sort !== ""){
            let date = sort.split("*");
            ordens.filter.fromDate = date[0];
            ordens.filter.toDate = date[1];
            this.loadData(ordens.filter);
        }else{
            ordens.filter.fromDate = "";
            ordens.filter.toDate = "";
            this.loadData(ordens.filter);
        }
    }

    setSortBy = (sort) => {
        const { ordens } = this.props;
        ordens.filter = this.clearFilter(ordens.filter);
        if (sort !== ""){
            let by = sort.split("*");
            ordens.filter.sortBy = by[0];
            ordens.filter.sortOrder = by[1];
            this.loadData(ordens.filter);
        }else{
            ordens.filter.sortBy = "createdAt";
            ordens.filter.sortOrder = "desc";
            this.loadData(ordens.filter);
        }
    }

    setDate = (sort) => {
        const { ordens } = this.props;
        ordens.filter = this.clearFilter(ordens.filter);
        ordens.filter.sortOrder = sort;
        this.loadData(ordens.filter);
    }

    setFulfillmentStatus = (status) => {
        const { ordens } = this.props;
        ordens.filter = this.clearFilter(ordens.filter);
        ordens.filter.fulfillmentStatus = status;
        ordens.filter.provinceCode = "";
        let totalSelected = 0;
        let totalLocalSelected = 0;
        this.setState({totalSelected: totalSelected , totalLocalSelected : totalLocalSelected});
        this.loadData(ordens.filter);
    }

    clearFilter = (filter) => {
        filter.from = 0;
        filter.provinceCode = "";
        filter.isLalamoveArea = false;
        return filter;
    }

    setProvinceFilter = (province) => {
        const { ordens } = this.props;
        ordens.filter.isLalamoveArea = true;
        ordens.filter.fulfillmentStatus = "unshipped";
        ordens.filter.from = 0;
        let totalSelected = 0;
        let totalLocalSelected = 0;
        this.setState({totalSelected: totalSelected , totalLocalSelected : totalLocalSelected});
        this.loadData(ordens.filter);
    }

    handleClickOrden = (orden) => {
        this.props.dispatch({
            type: Constants.types.CLICK_ORDEN,
            orden: orden
        });
        return false;  
    }
    
    handleChangePage = (page) => {

        const { ordens, config } = this.props;
        config["sizeOrdens"] = page;
        
        this.props.dispatch({
            type: 'CHANGE_CONFIG',
            config: config
        });
        this.loadData(ordens.filter);

        return false;  
    }  

    goTo = (from) => {
        const { ordens } = this.props;
        ordens.filter.from = from;
        this.loadData(ordens.filter);
    }

    getFilterDate(){
    
    }

    selected = (value, id) =>{
        const { ordens ,shop} = this.props;
        let { totalSelected, totalLocalSelected } = this.state;
        var isLocal =  false;
        ordens.list.map((orden)=>{
            if(orden.id === id){
                orden.selected = value;
                isLocal = Util.isLocalShipping(orden, shop);
            }
        });

        this.props.dispatch({
            type: 'CHANGE_LIST_ORDENS',
            orders: ordens.list,
            filter: ordens.filter
        });

        if (value){
            if(isLocal){
                totalLocalSelected ++;
            }else {
                totalSelected++;
            }
            
        }else{
            if(isLocal){
                totalLocalSelected --;
            }else {
                totalSelected--;
            }
        }
        this.setState({totalSelected: totalSelected, totalLocalSelected : totalLocalSelected});
    }
    
    multi = () =>{
        window.location.href = "/multi-orders";
        return false;
    }
    
    localShipping = () => {
        window.location.href = "/local-orders";
        return false;
    }

    print = () => {
        window.location.href = "/print/multi-print-order";
    }

    changeFilterStages = (value, name) => {
        const { ordens } = this.props;
        
        this.setState({filterStages: value});
        if (value == -1){
            ordens.filter.stage = "";
        }else{
            ordens.filter.stage = value;

        }
        ordens.filter.from = 0;
        this.loadData(ordens.filter);
    }

    handleClickOrder = (orderId) => {
        window.location= "/order/"+orderId;
    }

    sync = () => {
        this.setState({ isSearch: true});
        const url = Constants.URL_API+"orders/sync";
        let stage = {};
        console.log(url);
        axios.post(url, stage, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            this.setState({error: error, isSearch: false});
            return error;
          }).then((data) => {
            console.log(data);
            const { ordens } = this.props;
            ordens.filter = this.clearFilter(ordens.filter);
            this.loadData(ordens.filter);
        }); 
    }

    render() {
        const { ordens, shop, config } = this.props;
        
        const { isSearch, isScroll, totalSelected, sortList, totalLocalSelected, shopsCustomerId, optionsStages, filterStages, stagesOptionsFilter } = this.state;
        /*para hacer generico el Envios locales dependiendo de la direccion pincpical de la tienda
         var state = Util.getDataShop (shop, shop.addresses ,-1, "state)" */
        return (
            <div className="ordens">
                <Promo />
                <h1>
                    Ordenes
                    <Link className="btn triciclo top-middle right" to={`/new-shipping`} >
                        <i className="icofont-truck-loaded"></i> Nuevo envio
                    </Link>
                    <button 
                        onClick={() => this.print()} 
                        className="btn triciclo top-middle right print" 
                        disabled={isSearch || totalLocalSelected > 0 ? false : true}
                        >
                        <i className="icofont-printer"></i>&nbsp;
                        Imprimir Pedido
                    </button>
                </h1>
                <div className="content-widget">
                    <div className="content-flex space-between content-button">
                        <ul role="tablist" className="filter-list">
                            <FilterLink 
                                varFilter={ordens.filter.fulfillmentStatus} 
                                onClick={this.setFulfillmentStatus} 
                                title="Todos"
                                filterSet=""
                            />
                            <FilterLink 
                                varFilter={ordens.filter.fulfillmentStatus} 
                                onClick={this.setFulfillmentStatus} 
                                title="Sin enviar"
                                filterSet="unshipped"
                            />
                            <FilterLink 
                                varFilter={ordens.filter.fulfillmentStatus} 
                                onClick={this.setFulfillmentStatus} 
                                title="Parciales"
                                filterSet="partial"
                            />
                            <FilterLink 
                                varFilter={ordens.filter.isLalamoveArea} 
                                onClick={this.setProvinceFilter} 
                                title="Envíos CDMX"
                                filterSet = {true} 
                                /*para hacer generico el Envios locales dependiendo de la direccion pincpical de la tienda
                                filterSet="state"*/
                            />
                            <FilterLink 
                                varFilter={ordens.filter.fulfillmentStatus} 
                                onClick={this.setFulfillmentStatus} 
                                title="Enviados"
                                filterSet="shipped"
                            />
                            {stagesOptionsFilter && (
                                <li className={`${filterStages != -1 && 'active'}`}>
                                    <SelectHtml edit={true} options={stagesOptionsFilter} onChange={this.changeFilterStages} name="type" value={filterStages} loading={isSearch}/> 
                                </li>
                            )}

                        </ul>
                        <div className="content-flex">
                            <button 
                                onClick={() => this.localShipping()} 
                                className="btn triciclo top-middle" 
                                disabled={isSearch || totalLocalSelected  < 1 || totalSelected > 0 ? true : false}
                                >
                                <i className="icofont-map"></i>&nbsp;
                                Generar envíos CDMX
                            </button>
                            &nbsp;
                            <button 
                                onClick={() => this.multi()} 
                                className="btn triciclo top-middle" 
                                disabled={isSearch || ((totalSelected + totalLocalSelected) < 2) ? true : false}
                                >
                                <i className="icofont-listing-number"></i>&nbsp;
                                Generar multiples guías
                            </button>
                        </div>
                    </div>
                    
                    <div className="filter-table">
                        <div className="content-input-search">
                            <input type="search" value={ordens.filter.text} onChange={e => this.setText(e)}  placeholder="Buscar orden" />
                            <span><a href="#" onClick={() => this.sync()}>Sincronizar órdenes manualmente</a></span>
                        </div>
                        <Filter handleSortDate={this.setSortOrder} optionsSortBy={sortList} handleSort={this.setSortBy}/>
                    </div>
                    
                {ordens && ordens.list && ordens.list.length > 0 ? (
                    <div className={`scroll-table ${isScroll ? "active" : ""}`} id="scroll-table">
                        <table>
                            {isSearch && ( 
                                <div className="center content-loading">
                                    <div className="loading">
                                        <div id="preloader">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div> 
                                        <span>Cargando Ordenes</span>
                                    </div>    
                                </div>
                            )}
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Orden</th>
                                    {shop.length > 1 && (<th>Tienda</th>)}
                                    <th>Cliente</th>
                                    <th>Destino</th>
                                    <th className="width-20">Envio</th>
                                    {optionsStages && (
                                        <th>Estados del pedido</th>
                                    )}
                                    <th>Pedido</th>
                                    <th></th>
                                </tr>    
                            </thead>
                            <tbody>
                                {ordens.list.map((orden) => (
                                    <tr key={orden.id}>
                                        <td>
                                            <CheckboxUnit 
                                                edit={true} 
                                                id={orden.id} 
                                                name="list" 
                                                selected={orden.selected ? orden.selected : false} 
                                                onBlur={this.selected}
                                                disabled={!orden.customer || orden.fulfillmentStatus ? true : false}
                                            />
                                        </td>
                                        <td className="nowrap" onClick={() => this.handleClickOrden(orden)}>
                                            <i className="icofont-grocery shopify"></i>&nbsp;
                                            {orden.customer ? (
                                                <Link to={`/order/${orden.id}`} >
                                                    {orden.name}
                                                </Link>
                                            ) : (
                                                <span>{orden.name}</span>
                                            )}
                                            <div className="destination orden-info">
                                                <i className="icofont-calendar"></i> {Constants.getDate(orden.createdAt)}
                                            </div>
                                            <div className="destination orden-info">
                                                <span dangerouslySetInnerHTML={Constants.status_order[orden.financialStatus]}></span>
                                            </div>
                                        </td>
                                        {shop.length > 1 && shopsCustomerId && shopsCustomerId[orden.customerId] && (
                                            <td>{shopsCustomerId[orden.customerId].name}</td>
                                        )}
                                        <td>
                                            <GetUser customer={orden.customer} />
                                        </td>
                                        <td>
                                            <GetShipping customer={orden.shippingAddress} />
                                        </td> 
                                        <td>
                                            <ShippingInformation fulfillmentStatus={orden.fulfillmentStatus} shippingLines={orden.shippingLines} fulfillments={orden.fulfillments} />
                                        </td>
                                        {optionsStages && (
                                            <td className="td-stage">
                                                {orden.fulfillmentStatus === null ? (
                                                    <OrderStages options={optionsStages} value={orden.stage} order={orden} />
                                                ) : (
                                                    <span>Enviada</span>
                                                )}
                                            </td>
                                        )}
                                        <td>
                                            <div>
                                                {orden.lineItems.length === 1 ? "1 producto" : `${orden.lineItems.length} productos`} 
                                            </div>
                                            <div>
                                                <CurrencyFormat value={orden.totalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            </div>
                                            <div>
                                                {shopsCustomerId && shopsCustomerId[orden.customerId] && shopsCustomerId[orden.customerId].shopifyDomain && (
                                                    <a href={`https://${shopsCustomerId[orden.customerId].shopifyDomain}/admin/orders/${orden.id}`} className="ver-shopify-btn" target="_blank">
                                                        Ver en <i className="icofont-grocery"></i>
                                                    </a>
                                                )}
                                            </div>
                                        </td>
                                        <td className="nowrap" onClick={() => this.handleClickOrden(orden)}>
                                            {orden.customer ? (
                                                <button 
                                                    onClick={() => this.handleClickOrder(orden.id)} 
                                                    className="btn triciclo top-middle"
                                                    >
                                                    Crear Envío
                                                </button>
                                            ) : (
                                                <span>{orden.name}</span>
                                            )}
                                            
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <hr></hr>
                    <Page from={ordens.filter.from} size={config["sizeOrdens"] ? config["sizeOrdens"] : 0} elementSize={ordens.list.length} handleClick={this.goTo} handleChangePage={this.handleChangePage}/>
                    </div>
                ) : (!isSearch) ? (
                    <div className="no-data">
                        <div className="content-flex flex-center">
                            <i className="icofont-search-2"></i>
                        </div>    
                        <div className="content-flex flex-center">
                            <h2>No hay pedidos</h2>
                        </div>      
                    </div>
                ) : (
                    <div className="no-data">
                        <div className="content-flex flex-center">
                            <i className="icofont-gears"></i>
                        </div>    
                        <div className="content-flex flex-center">
                            <h2>Buscando</h2>
                        </div>      
                    </div>
                )
                }
                </div>
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    ordens: state.ordens,
    today: state.today, 
    yesterday: state.yesterday, 
    lastWeek: state.lastWeek, 
    lastMonth: state.lastMonth,
    shop: state.shop,
    config: state.config 
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListOrders);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from './../store/index';
import { Link  } from 'react-router-dom';
import Page from '../components/html/Page';
import CurrencyFormat from 'react-currency-format';


class ListShops extends Component {

    state = {
        isSearch: false,
    };

    componentDidMount() {
        this.loadData(Constants.initialState.shops.filter);
    }

    loadData = (filter) => {
        this.setState({ isSearch: true});
        const url = Constants.URL_API+"customers"+Constants.getFilter(filter);
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            this.props.dispatch({
                type: 'CHANGE_LIST_SHOPS',
                shops: data,
                filter: filter
            });
            this.setState({ isSearch: false});
        }); 
    }
    changePage = (page) => {
        this.loadData(page);
    }
    
    setText = (e) => {
        const { shops } = this.props;
        let value = e.target.value;
        shops.filter.from = 0;
        shops.filter.text = value;
        this.loadData(shops.filter);
    }
    setSortOrder = (sort) => {
        const { shops } = this.props;
        shops.filter.from = 0;
        shops.filter.sortOrder = sort;
        this.loadData(shops.filter);
    }

    setFulfillmentStatus = (status) => {
        const { shops } = this.props;
        shops.filter.from = 0;
        shops.filter.fulfillmentStatus = status;
        this.loadData(shops.filter);
    }

    handleClickOrden = (orden) => {
        this.props.dispatch({
            type: Constants.types.CLICK_ORDEN,
            orden: orden
        });
        return false;  
    }  
    goTo = (from) => {
        const { shops } = this.props;
        shops.filter.from = from;
        this.loadData(shops.filter);
    }

    handleChangePage = (page) => {

        const { shops } = this.props;
        shops.filter.size = page;
        shops.filter.from = 0;
        this.loadData(shops.filter);

        return false;  
    } 
    
    setSortBy = (sort) => {
        const { shops } = this.props;
        shops.filter.from = 0;
        if (sort !== ""){
            let by = sort.split("*");
            shops.filter.sortBy = by[0];
            shops.filter.sortOrder = by[1];
            this.loadData(shops.filter);
        }else{
            shops.filter.sortBy = "createdAt";
            shops.filter.sortOrder = "desc";
            this.loadData(shops.filter);
        }
    }
    render() {
        const { shops } = this.props;
        const { isSearch } = this.state;
        return (
            <div className="ordens">
                <h1>Tiendas</h1>
                <div className="content-widget">
                    <div className="filter-table">
                        <input type="search" value={shops.filter.text} onChange={e => this.setText(e)}  placeholder="Buscar tienda" />
                        <select onChange={e => this.setSortBy(e.target.value)}>
                            <option value="">Ordenar</option>
                            <option value="id*desc">ID DESC</option>
                            <option value="createdAt*desc">Creación DESC</option>
                            <option value="updatedAt*desc">Actualización DESC</option>
                        </select>
                    </div>
                {shops && shops.list && shops.list.length > 0 && (
                    <table>
                        {isSearch && ( 
                            <div className="center content-loading">
                                <div className="loading">
                                    <div id="preloader">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div> 
                                    <span>Cargando Tiendas</span>
                                </div>    
                            </div>
                        )}
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Saldo</th>
                                <th>Tipo</th>
                                <th>Info</th>
                            </tr>    
                        </thead>
                        <tbody>
                            {shops.list.map((shop) => (
                                <tr key={shop.id}>
                                    <td className="nowrap" onClick={() => this.handleClickOrden(shop)}>
                                        <i className="icofont-grocery shopify"></i>&nbsp;
                                        <Link to={`/shop/${shop.id}`} >
                                            {shop.name}
                                        </Link>
                                        <div className="destination orden-info">
                                            <i className="icofont-calendar"></i> {Constants.getDate(shop.createDate)}
                                        </div>
                                    </td>
                                    <td>
                                        <CurrencyFormat value={shop.quota} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </td>
                                    <td> 
                                        {shop.type}
                                    </td> 
                                    <td>
                                        <div>{shop.shopifyShopName}</div>
                                        <div>{shop.phone}</div>
                                    </td>   
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                <hr></hr>
                <Page from={shops.filter.from} size={shops.filter.size ? shops.filter.size : 0} elementSize={shops.list.length} handleClick={this.goTo} handleChangePage={this.handleChangePage} />
                </div>
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    shops: state.shops
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

// `connect` returns a new function that accepts the component to wrap:

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListShops);

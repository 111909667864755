import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';


class DashboardCards extends Component {


    render() {
        const { count, costSum, costAvg, deliveryDaysAvg, textFilter, costCarrierSum } = this.props;
        return(
            <div className="card-container-polaris content-flex space-between">
                <div className="Polaris-Card_yis1o">
                    <div className="_3r3-r">

                        {(costCarrierSum !== -1) ? (
                            <div className="space-between content-flex">
                                <div className="_3WdC8">
                                    <div className="_1VUFI">
                                        <h3 aria-label="Total de sesiones" className="Polaris-Subheading_syouu">
                                            Costo total
                                        </h3>
                                    </div>
                                    <div className="Polaris-Stack_32wu2 Polaris-Stack--spacingExtraTight_gv6hw Polaris-Stack--distributionEqualSpacing_x9cqm Polaris-Stack--alignmentBaseline_aupj5 Polaris-Stack--noWrap_vecks">
                                        <div className="Polaris-Stack__Item_yiyol">
                                            <p className="_3vPiE">
                                                <CurrencyFormat value={costSum} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="_3WdC8">
                                        <div className="_1VUFI">
                                            <h3 aria-label="Total de sesiones" className="Polaris-Subheading_syouu">
                                                Costo Total Triciclo
                                            </h3>
                                        </div>
                                        <div className="Polaris-Stack_32wu2 Polaris-Stack--spacingExtraTight_gv6hw Polaris-Stack--distributionEqualSpacing_x9cqm Polaris-Stack--alignmentBaseline_aupj5 Polaris-Stack--noWrap_vecks">
                                            <div className="Polaris-Stack__Item_yiyol">
                                                <p className="_3vPiE">
                                                    <CurrencyFormat value={costCarrierSum} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        ) : (
                            <div className="_3WdC8">
                                    <div className="_1VUFI">
                                        <h3 aria-label="Total de sesiones" className="Polaris-Subheading_syouu">
                                            Costo total
                                        </h3>
                                    </div>
                                    <div className="Polaris-Stack_32wu2 Polaris-Stack--spacingExtraTight_gv6hw Polaris-Stack--distributionEqualSpacing_x9cqm Polaris-Stack--alignmentBaseline_aupj5 Polaris-Stack--noWrap_vecks">
                                        <div className="Polaris-Stack__Item_yiyol">
                                            <p className="_3vPiE">
                                                <CurrencyFormat value={costSum} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                        )}
                        <hr className="_3r5Vb"/>
                        <div className="Polaris-Stack_32wu2 Polaris-Stack--spacingExtraTight_gv6hw Polaris-Stack--distributionEqualSpacing_x9cqm Polaris-Stack--alignmentCenter_1rtaw">
                            <div className="Polaris-Stack__Item_yiyol">
                                <div className="Polaris-Stack_32wu2 Polaris-Stack--spacingNone_1b3d3">
                                    <div className="Polaris-Stack__Item_yiyol">
                                        <span className="Polaris-TextStyle--variationSubdued_1segu">
                                            {count} guías generadas {textFilter}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Polaris-Card_yis1o">
                    <div className="_3r3-r">
                        <div className="_3WdC8">
                            <div className="_1VUFI">
                                <h3 aria-label="Total de sesiones" className="Polaris-Subheading_syouu">
                                    Costo promedio
                                </h3>
                            </div>
                            <div className="Polaris-Stack_32wu2 Polaris-Stack--spacingExtraTight_gv6hw Polaris-Stack--distributionEqualSpacing_x9cqm Polaris-Stack--alignmentBaseline_aupj5 Polaris-Stack--noWrap_vecks">
                                <div className="Polaris-Stack__Item_yiyol">
                                    <p className="_3vPiE">
                                        {costAvg > 0  ? (
                                            <CurrencyFormat value={costAvg} displayType={'text'} thousandSeparator={true}  prefix={'$ '} decimalScale={2} />
                                        ):
                                        (
                                            <span>
                                                $ 0
                                            </span>
                                        )
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr className="_3r5Vb"/>
                        <div className="Polaris-Stack_32wu2 Polaris-Stack--spacingExtraTight_gv6hw Polaris-Stack--distributionEqualSpacing_x9cqm Polaris-Stack--alignmentCenter_1rtaw">
                            <div className="Polaris-Stack__Item_yiyol">
                                <div className="Polaris-Stack_32wu2 Polaris-Stack--spacingNone_1b3d3">
                                    <div className="Polaris-Stack__Item_yiyol">
                                        <span className="Polaris-TextStyle--variationSubdued_1segu">
                                            {deliveryDaysAvg > 0 ? (
                                                <CurrencyFormat value={deliveryDaysAvg} displayType={'text'} thousandSeparator={true}  decimalScale={2} />
                                            ):
                                            (
                                                <span>
                                                    0
                                                </span>
                                            )
                                            } días promedio {textFilter}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        );}
}
export default DashboardCards;

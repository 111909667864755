import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import auth0Client from './../components/auth/Auth';
import  * as Util from './../data/General';
import * as Constants from './../store/index';

import { connect } from 'react-redux';

class Success extends Component {
  componentDidMount() {
    Util.addScrollBody();
    let param = new URLSearchParams(this.props.location.search);
    let access_token = param.get("access_token");
    let ouath = param.get("ouath");
    let name = param.get("name");
    let email = param.get("email");
    console.log(access_token);
    if (access_token){
        let shop = param.get("shop");
        let user_id  = param.get("user_id");
        let newShop = {
          myshopifyDomain: shop.replace(".myshopify.com", ''),
          shopifyApiKey: access_token
        };

        localStorage.setItem("Linkages", JSON.stringify(newShop)); 
        localStorage.removeItem("shop");
        let user = {
          "idToken": ouath,
          "profile": {
            "nickname": name,
            "name": name,
            "picture": "",
            "updated_at": "2021-04-06T11:53:29.204Z",
            "email": email,
            "email_verified": false,
            "iss": "https://dev-w0yx0x16.auth0.com/",
            "sub": user_id,
            "aud": "N4sMCVvt4UtvbeDpD3CjT1j9xpnNGodG",
            "iat": 1617710009,
            "exp": 1617746009,
            "at_hash": "RwwefVwkA3yHkGTa8eIOHA",
            "nonce": "fjys4SCTNc9h7X61g-fJqPXnw52gOq2u"
          },
          "expiresAt": 1617746009000,
          "data": {
            "id": "Wwx9lHgBWhn4k_vT0JS1",
            "authUserId": user_id,
            "email": email,
            "password": null,
            "firstName": name,
            "lastName": name,
            "createDate": "2021-04-02T15:29:36",
            "updateDate": null,
            "customerId": [
              "53387133125",
              "53387133125",
              "55715725464",
              "55715725464"
            ],
            "permissions": null
          },
          "accessToken": ouath
        };

        this.props.dispatch({
          type: Constants.types.LOGIN_SHOPIFY,
          user: user
        });

        setTimeout(function(){
          window.location.href = '/preferences';
        }, 200);
        
    }
  }

  render() {
    return (
      <div className="login">
          <h2 className="frame-1">Triciclo Go.</h2>
          <h2 className="frame-5">
            <span>Logistica</span>
            <span>&</span>
            <span>Envios</span>
          </h2>
          <div className="success">
            <h3 className="title"><i className="icofont-check-circled"></i> Tu integración con Shopify <i className="icofont-grocery"></i> ha sido un exito!</h3>
            <h3>Para finalizar el proceso, solo debes completar el login y podras disfrutar de todos los beneficios de Triciclo Go.</h3>
            <button className="btn btn-dark" onClick={auth0Client.signIn}>Finalizar el proceso</button>
          </div>  
          <img src="/img/shopify.png" alt="logo shopify" />
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WDZTKPH"
          height="0" width="0" style={{"display":"none","visibility":"hidden"}} title="iframe de google"></iframe>
  </div>
    );
  }
}
const mapStateToProps = (state) => ({
    user : state.user,
});
  
const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Success));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Constants from './../store/index';
import { Link  } from 'react-router-dom';
import GetShipping from '../components/html/GetShipping';
import axios from "axios";
import GetUser from '../components/html/GetUser';
import  * as Util from './../data/General';
import CurrencyFormat from 'react-currency-format';
import GetPackageShipping from '../components/widget/GetPackageShipping';
import SelectHtml from '../components/html/SelectHtml';


class MultipleOrdersList extends Component {

    state = {
        isSearch: false,
        isScroll: false,
        packageList:null,
        carrier: null,
        carrierKey: "",
        ordens: null,
        step: 0,
        totalQuotion: 0,
        shopAddresses: -1,
        shop: null,
        carriers: Util.carriersDefault,
    };

    componentDidMount() {
        const { ordens, packageListProps, config, shopState, user } = this.props;
        if (ordens && ordens.length > 0){
            document.getElementById('scroll-table').addEventListener("scroll", this.updateScroll);
        }
        let shop = shopState[0];
        
        ordens.list.map((order)=>{
            if (order.selected){
                order.cost = "";
                if (order.customerId !== 0){
                    shop = Util.getShop(shopState, order.customerId);
                }
            }
        });

        if(shop === null){
            shop = shopState[0]; 
        }
        
        this.setState({ packageList: packageListProps, ordens: ordens.list, shopAddresses: config.addressesDefault, shop: shop});
        setTimeout(function(){
            Util.loadDataUsers(this.props.dispatch, false);
        }.bind(this), 500);

        setTimeout(function(){
            this.loadDataCarriers(user.data.customerId[0]);
        }.bind(this), 300);
    }

    loadDataCarriers = (idShop) => {
        const url = Constants.URL_API+"shippings/carriers/carrier-customer/?customerId="+idShop;
        console.log(url);
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            //Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            const { carriers } = this.state;
            if (data.length > 0){
                for (var i=0; i < data.length; i++) {
                    var value= data[i];
                    carriers[value.carrierId] = value;
                }
                this.setState({carriers: carriers});
                console.log(carriers);
            }
        }); 
    }

    handleClickPackage = (pack) => {
        const { packageList, personalizad } = this.state;
        console.log(pack);
        let selected_package = null;
        if (pack === "0") {
            selected_package = personalizad;
        }else{
            for (var i in packageList.list) {
                let element = packageList.list[i];
                console.log(element.id === pack);
                if(element.id === pack){
                    selected_package = element;
                }
            }
        }
        console.log(selected_package);
        packageList.selected = selected_package;
        this.setState({packageList: packageList});
        this.props.dispatch({
            type: Constants.types.CLICK_PACKAGE,
            selected: selected_package
        });
        return false;  
    }
    handleClickCarriers = (sel) =>{
        let selected_package = null;
        let carrierKey=null;
        Object.entries(Util.CARRIERS_RATE).map(([key,value])=> {
            if(key === sel){
                selected_package = value;
                carrierKey = key;
            }
        });
        if(carrierKey){
            console.log(selected_package);
            console.log(carrierKey);
            this.setState({carrier: selected_package, carrierKey: carrierKey});
        }
    }

    updateScroll = () =>{
        const { isScroll } =this.state;
        if (document.getElementById('scroll-table').scrollLeft > 0){
            this.setState({ isScroll: true});
        }else if(isScroll){
            this.setState({ isScroll: false});
        }
    }
    shippings = () => {
        const { packageList, ordens } = this.state;
        
        ordens.map((order)=>{
            if (order.selected && !order.isError){
                order.urlPDF = "";
                this.getshippings(order, packageList.selected);
            }
        });
        this.setState({ ordens: ordens, step: 2 });
    }

    getshippings = (orden, packSelect) => {
        const { shopAddresses, shop } = this.state;
        const { user } = this.props;
        
        let orderId = orden.id;
        let orderName = orden.name;
        let email = orden.email;
        let phone = orden.shippingAddress.phone;
        
        Util.shippings(orden, shop, shopAddresses, user, orden.shippingAddress, packSelect, orderId, orderName, email, phone, 0, false, orden.rateId, this.handleOk, this.handleError, false, {}, "");
    }

    handleError = (error, orderId) => {
        const {  ordens } = this.state;
        ordens.map((order)=>{
            if (order.selected && order.id === orderId){
                order.error = error;
                order.isError = true;
            }
        });
        this.setState({ ordens: ordens });
    }
    handleOk = (data) => {
        const { ordens } = this.state;
        ordens.map((order) => {
            if (order.selected && order.id === data.orderId){
                order.urlPDF = data.guideUrl;
                order.shippingsData = data;
            }
        });
        this.setState({ ordens: ordens });
    }

    getQuotion = (key, carrierId, packSelect, deliveryType, shippingAddress, id) => {
        const { shopAddresses, shop } = this.state;
        const { shopState } = this.props;
        let quotationsPost={};
        let origin = {
            zipCode: Util.getDataShop(shop, shop.addresses, shopAddresses, "zip"),
            city: Util.getDataShop(shop, shop.addresses, shopAddresses, "city"),
        };
        let destination= {
            zipCode: shippingAddress.zip,
            city: shippingAddress.city,
        };

        packSelect.description = shop.titleGuide;
        
        quotationsPost = {
            package: packSelect,
            insuredAmount: 0,
            origin: origin,
            destination: destination
        }

        console.log(quotationsPost);
        
        const url = Constants.URL_API+"shippings/quotations/?carrierId="+ carrierId+"&deliveryType="+deliveryType;
        axios.post(url, quotationsPost, Constants.getHeaders()).then(response => response.data, error => {
            Constants.setErrors(error);
            let errorRetrun = [];
            errorRetrun.push({
                code: error.response.status,
                message: Constants.getMessage(error.response.data.message)
            });
            console.log(error);
            this.setOrderError(id, errorRetrun);
            return error;
        }).then((data) => {
            Constants.scrollToWindow();
            if (!data.message) {
                if(data.length > 0){
                    let {totalQuotion } = this.state;
                    totalQuotion = totalQuotion + data[0].total;
                    if (totalQuotion < shopState[0].quota){
                        this.setOrderCost(id, data[0].total, data[0].rateId);
                        this.setState({ totalQuotion: totalQuotion });
                    }else{
                        let errorRetrun = [];
                        errorRetrun.push({
                            code: "200",
                            message: "Sin saldo - No se genera la guia"
                        });
                        console.log(errorRetrun);
                        this.setOrderError(id, errorRetrun);
                    }
                }
            } 
        });
    }

    setOrderCost = (id, cost, rateId) => {
        const {  ordens } = this.state;
        ordens.map((order)=>{
            if (order.selected && order.id === id){
                order.cost = cost;
                order.rateId = rateId;
            }
        });
        this.setState({ ordens: ordens });
    }

    setOrderError = (id, error) => {
        const {  ordens } = this.state;
        ordens.map((order)=>{
            if (order.selected && order.id === id){
                order.error = error;
                order.isError = true;
            }
        });
        this.setState({ ordens: ordens });
    }

    quotations = () => {
        const { packageList, carrier, carrierKey, ordens } = this.state;
        
        ordens.map((order)=>{
            if (order.selected){
                order.cost = -1;
                this.getQuotion(carrierKey, carrier.carrierId, packageList.selected, carrier.deliveryType, order.shippingAddress, order.id);
            }
        });
        this.setState({ ordens: ordens, step: 1 });
    }

    handleChangeAddress  = (value, name) =>{
        
        this.setState({
            shopAddresses: value
        });
    }
  
    render() {
        const { packageListProps } = this.props;
        const { isSearch, isScroll, packageList, carrierKey, ordens, step, totalQuotion, shopAddresses, shop, carriers } = this.state;
        return (
            <div className="ordens">
                <h1>
                    Ordenes
                    
                </h1>
                <div className="content-widget">
                    
                {ordens && ordens.length > 0 ? (
                    <div className={`scroll-table ${isScroll ? "active" : ""}`} id="scroll-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Orden</th>
                                    <th>Cliente</th>
                                    <th>Destino</th>
                                    <th className="content-flex flex-end">
                                        {step === 0 ? (   
                                            <React.Fragment>
                                                {packageList && (
                                                    <select className="form-control" name="layout_id" value={packageListProps.selected.id} onChange={e => this.handleClickPackage(e.target.value)}>
                                                        <option value="">Seleccione empaque</option>
                                                        {packageList.list.length > 0 && packageList.list.map((pack) => (
                                                            <option value={pack.id} key={pack.id}>{pack.name}</option>
                                                        ))}
                                                    </select>
                                                )}
                                                
                                                <select className="form-control" name="layout_id" value={carrierKey} onChange={e => this.handleClickCarriers(e.target.value)}>
                                                    <option value="">Seleccione paqueteria</option>
                                                    {Object.entries(Util.CARRIERS_RATE).map(([key,value])=>(
                                                        <React.Fragment key={key}>
                                                            {carriers[value.carrierId].enabled && (
                                                                <option value={key}>{value.name}</option>
                                                            )}
                                                        </React.Fragment>
                                                    ))
                                                    }
                                                </select>
                                            </React.Fragment>
                                        ) : step === 1 ? (
                                            <span>Costo</span>
                                        ) : (
                                            <span>Guía</span>
                                        )}    
                                    </th>
                                </tr>    
                            </thead>
                            <tbody>
                                {ordens.map((orden) => (
                                    <React.Fragment>
                                    {orden.selected && (
                                        <tr key={orden.id} className={orden.isError ? "error-tr" : ""}>
                                            
                                            <td className="nowrap" onClick={() => this.handleClickOrden(orden)}>
                                                <i className="icofont-grocery shopify"></i>&nbsp;
                                                {orden.customer ? (
                                                    <Link to={`/order/${orden.id}`} >
                                                        {orden.name}
                                                    </Link>
                                                ) : (
                                                    <span>{orden.name}</span>
                                                )}
                                                <div className="destination orden-info">
                                                    <i className="icofont-calendar"></i> {Constants.getDate(orden.createdAt)}
                                                </div>
                                                <div className="destination orden-info">
                                                    <span dangerouslySetInnerHTML={Constants.status_order[orden.financialStatus]}></span>
                                                </div>
                                            </td>
                                            <td>
                                                <GetUser customer={orden.customer} />
                                            </td>
                                            <td>
                                                <GetShipping customer={orden.shippingAddress} />
                                            </td> 
                                            <td className="text-right">
                                                {step === 0 ? (
                                                    <span>
                                                        Sin calcular
                                                    </span>
                                                ) : step === 1 ? (
                                                    <React.Fragment>
                                                        {orden.isError ? (
                                                            <span>{orden.error[0].message}</span>
                                                        ) : orden.cost === -1 ? (
                                                            <div className="center">
                                                                <div className="loading content-flex flex-center" >
                                                                    <div id="preloader">
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div> 
                                                                    <span>Calculando <img src={`/img/${Util.CARRIERS_RATE[carrierKey].img}`} alt={Util.CARRIERS_RATE[carrierKey].name} /></span>
                                                                </div>    
                                                            </div>
                                                        ) : (
                                                            <span>
                                                                <CurrencyFormat value={orden.cost} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                            </span>
                                                        )} 
                                                    </React.Fragment>
                                                ) : step === 2 && (
                                                    <React.Fragment>
                                                        {orden.isError ? (
                                                            <span>{orden.error[0].message}</span>
                                                        ) : orden.shippingsData ? (
                                                            <div className="content-flex space-between">
                                                                <div className="align-left">
                                                                    <GetPackageShipping shipping={orden.shippingsData} />
                                                                </div>    
                                                                <a href={orden.shippingsData.guideUrl} target="_blank">   
                                                                    <i className="icofont-download"></i> Descargar
                                                                </a> 
                                                            </div>
                                                        ) : (
                                                            <div className="center">
                                                                <div className="loading content-flex flex-center" >
                                                                    <div id="preloader">
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div> 
                                                                    <span>Generando guía</span>
                                                                </div>    
                                                            </div>
                                                        )} 
                                                    </React.Fragment>   
                                                )} 
                                            </td>
                                        </tr>
                                    )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        <hr></hr> 
                        <div className="space content-flex flex-end container-multi-footer"> 
                            {step === 0 ? (   
                                <React.Fragment>
                                    <div className="content-flex">
                                        Dirección de origen: 
                                        <SelectHtml edit={true} options={shop.addresses} defaultText={`Principal: ${Util.getDataShop(shop, shop.addresses, shopAddresses, "street1")}, ${Util.getDataShop(shop, shop.addresses, shopAddresses, "city")}, ${Util.getDataShop(shop, shop.addresses, shopAddresses, "zip")}  `} defaultValue={-1} required={true} onChange={this.handleChangeAddress} name="shopAddresses" value={shopAddresses} keyValue={Constants.addressSelect}/> 
                                    </div>
                                    <button 
                                        className="btn triciclo top-middle" 
                                        onClick={() => this.quotations()} 
                                        disabled={(isSearch || carrierKey === "" || packageList.selected.id === 0) ? true : false}
                                        >
                                        Calcular costo envio
                                    </button>
                                </React.Fragment>
                            ) : step === 1 && (
                                <button 
                                    className="btn triciclo top-middle" 
                                    onClick={() => this.shippings()} 
                                    disabled={(isSearch || totalQuotion === 0 ) ? true : false}
                                    >
                                    Generar guias
                                </button>
                            )}
                            </div>
                    </div>
                ) : (!isSearch) ? (
                    <div className="no-data">
                        <div className="content-flex flex-center">
                            <i className="icofont-search-2"></i>
                        </div>    
                        <div className="content-flex flex-center">
                            <h2>No hay pedidos</h2>
                        </div>      
                    </div>
                ) : (
                    <div className="no-data">
                        <div className="content-flex flex-center">
                            <i className="icofont-gears"></i>
                        </div>    
                        <div className="content-flex flex-center">
                            <h2>Buscando</h2>
                        </div>      
                    </div>
                )
                }
                </div>
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    ordens: state.ordens,
    packageListProps: state.packageList,
    shopState: state.shop,
    config: state.config,
    user: state.user
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleOrdersList);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from './html/Avatar';

import * as Constants from './../store/index';

import auth0Client from './auth/Auth';

class Header extends Component {

  handleClickMenu = () => {
    this.props.dispatch({
      type: 'CHANGE_SHOW_MENU'
    });
    return false;
  }   
  signOut = () => {
    this.props.dispatch({
      type: Constants.types.LOGIN,
      idToken: null,
      profile: null,
      expiresAt: null,
  });
  auth0Client.signOut();
}

  render() {
    const { shop, user } = this.props;
    if (user && user.idToken){
      return (
        <div className="header">
          <div>
            <a href="#" className="" onClick={ ()=> this.handleClickMenu()}>&#9776;</a>
            <a href="#" className="logo">
            <svg width="40px" height="97px" viewBox="0 0 101 97" style={{"margin-right": "1rem"}}>
              <path d="M77.267,87.574 C62.981,97.276 47.820,96.235 43.469,95.872 C37.569,95.380 19.260,93.854 7.902,78.216 C-3.598,62.381 0.795,44.147 1.516,41.355 C1.919,38.670 5.422,17.799 25.238,6.376 C44.346,-4.637 63.506,1.846 67.779,3.438 C70.990,4.634 92.932,13.225 98.755,35.829 C104.224,57.54 92.95,77.504 77.267,87.574 ZM48.740,46.169 L35.565,46.169 L35.565,52.766 L42.53,52.766 L42.53,53.658 C42.53,57.676 39.208,59.908 35.565,59.908 C31.922,59.908 29.77,57.676 29.77,53.658 L29.77,43.639 C29.77,39.621 31.922,37.389 35.565,37.389 C38.759,37.389 41.354,39.76 41.953,42.250 L48.690,42.250 C48.142,35.356 43.400,30.792 35.565,30.792 C27.181,30.792 22.389,35.901 22.389,43.639 L22.389,53.658 C22.389,61.396 27.181,66.505 35.565,66.505 C43.949,66.505 48.740,61.396 48.740,53.658 L48.740,46.169 ZM77.988,43.639 C77.988,35.901 73.196,30.792 64.812,30.792 C56.428,30.792 51.637,35.901 51.637,43.639 L51.637,53.658 C51.637,61.396 56.428,66.505 64.812,66.505 C73.196,66.505 77.988,61.396 77.988,53.658 L77.988,43.639 ZM64.812,59.908 C61.169,59.908 58.324,57.676 58.324,53.658 L58.324,43.639 C58.324,39.621 61.169,37.389 64.812,37.389 C68.455,37.389 71.300,39.621 71.300,43.639 L71.300,53.658 C71.300,57.676 68.455,59.908 64.812,59.908 Z"/>
            </svg>  
            
            {user.type === "ADMIN" ? "Administrador" : (shop && shop.length > 0 && (shop[0].name))}
            </a>
          </div>  
          {/*<input type="search" className="border-0" />*/}
          <div>
            <a href="#contact" className=""><Avatar />{user.profile.nickname}</a>
            <a href="/" className="btn btn-dark" onClick={() => this.signOut()}>Salir</a>
          </div>  
        </div>
      );
    }else {
      return <span></span>;
    } 
  }
}

const mapStateToProps = (state) => ({
  shop: state.shop,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import { connect } from 'react-redux';
import auth0Client from './Auth';
import * as Constants from './../../store/index';

class AuthSopify extends Component {
  componentDidMount() {
    const { user, shop } =this.props;
    var idShop = window.location.pathname.replace("/auth-shopify/", "");
    
    
    if (user.idToken && user.idToken != null && shop && shop.length > 0 && idShop === shop[0].myshopifyDomain){
      window.location.href = '/orders';
    }else{
      localStorage.clear();
      this.props.dispatch({
        type: Constants.types.LOGIN,
        idToken: null,
        profile: null,
        expiresAt: null,
      });
      auth0Client.signOut();
      window.location.href = '/';
    }
      
  }  

  render() {
    return (
      <div className="pdf">
        <div className="center content-loading">
            <div className="loading">
                <div id="preloader">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div> 
                <span>Cargando Datos</span>
            </div>    
        </div>
    </div>
  );
  }
}
const mapStateToProps = (state) => ({
    user : state.user,
    shop: state.shop
  });
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthSopify));

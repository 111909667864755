import React, { Component } from 'react';



class CheckboxView extends Component {

    componentDidMount() {
        
    }

    onChange = () => {
        const { onChange } = this.props;
                
        if (onChange){
            onChange();
        }
    }

    render() {
        const { edit, textActive, selected, textDisabled, className="" } = this.props;
        return (
            <label className={`switchBtn ${className}`}>
                {edit ? (
                    <React.Fragment>
                        <input type="checkbox" checked={selected} onChange={() => this.onChange()} />
                        <div className="slide round">
                            <span className="active">{textActive}</span>
                            <span className="disabled">{textDisabled}</span>
                        </div>
                    </React.Fragment> 
                   ) : (
                    <div className="switchBtn-text">
                        {selected ? textActive : textDisabled}           
                    </div>     
                )}  
            </label>
        );
  }
}

export default CheckboxView;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputHtml from '../components/html/InputHtml';

import * as Constants from './../store/index';
import * as Util from './../data/General';
import Axios from 'axios';
import Alert from '../components/html/Alert';
import Dinamics from './Dinamics';
import UploadCSV from '../components/html/UploadCSV';

class Guides extends Component {

    state = { 
        errorText: "",
        successText: "",
        isSearch: false,

    };

    componentDidMount() {

    }

    upload = (data) => {
        this.setState({successText: "", errorText: ""});
        if (data == ""){
            this.setState({successText: "Archivo subido correctamente."});
        }else{
            this.setState({errorText: data});
        }
    }


    render() {
        const { errorText,  successText } = this.state;
        return (
            <div className="preferences orden">
                <div className="title">
                    <h1>
                        Subir archivos de costo
                    </h1>
                </div>
                <Alert errorText={errorText} successText={successText}/>
                
                <div className="content-flex">
                    <div className="content-widget preferences-detail">
                        <h3>Seleccione el CSV</h3>
                        <hr></hr>

                        <UploadCSV upload={this.upload} />
                        
                        
                    </div>   
                </div>
            </div>
            );
        }
    }

    
const mapStateToProps = (state) => ({
    packageList: state.packageList,
    shopState: state.shop,
    user: state.user,
    ordens: state.ordens,
    shippings: state.shippings,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Guides);
import React, { Component } from 'react';
import * as Constants from './../../store/index';


class Send extends Component {

    render() {
        const { fulfillmentStatus, fulfillments } = this.props;

        return (
            <React.Fragment>
                {fulfillmentStatus && fulfillmentStatus === "partial" ? (
                    <span className="send partial">
                        <i className="icofont-plus-square"></i>
                        Envio parcial
                    </span> 
                ) : fulfillmentStatus ? (
                    <span className="send ok">
                        <i className="icofont-check-circled"></i>
                        Enviada
                    </span>    
                ):(
                    <span className="send">
                        <i className="icofont-close-circled"></i>
                        Sin enviar
                    </span> 
                )}
                <div className="destination without-left">
                    {fulfillments && fulfillments.length > 0 && (
                        <span>
                            <i className="icofont-calendar"></i> {Constants.getDate(fulfillments[0].createdAt)}
                        </span>
                    )}
                </div>
                <div className="destination without-left">
                    {fulfillments && fulfillments.length > 0 && fulfillments[0].trackingCompany && 
                        (<span>{fulfillments[0].trackingCompany}</span>)}
                </div>
            </React.Fragment>     
        );
  }
}

export default Send;
import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
import * as Util from './../../data/General';
import * as Constants from './../../store/index';
import Tooltips from  './../html/Tooltips';
import Alert from '../html/Alert';

class RateSelector extends Component {

    state ={
        checkedCarrier:"",
        checkedCarrierID: null
    }

    cons = (ele) =>{
        console.log(ele);
    }

    isExtendedZone = (cp) =>{
        return window.zona_extendida.includes(cp);
    }

    isHappensForced = (cp) =>{
        return window.ocurre_forzoso.includes(cp);
    }

    handleClickCheck = (quotation) => {
        const { shop, handleClick, transporter } = this.props;
        let checkBalance = Util.isCheckBalanceTransporter(quotation.carrierId, transporter);
        if((!checkBalance || quotation.total < shop.quota) ){//&& !this.isNotRFC(quotation.carrier) 
            handleClick(quotation.rateId, quotation.carrier);
            this.setState({checkedCarrier:quotation.carrier, checkedCarrierID: quotation.carrierId});
        }
    }

    isNotRFC = (carrier) => {
        const { shop } = this.props;
        if(carrier ==="FEDEX" || carrier ==="ESTAFETA"){
            if (!shop.rfc || shop.rfc === ""){
                return true;
            }
            if (!shop.cartaPorteProductKey || shop.cartaPorteProductKey === ""){
                return true;
            }
        }
        return false;

    }

    compare = ( a, b ) => {
        if ( (a.rateId && b.isError) || (a.rateId && a.total < b.total) ){
          return -1;
        }
        if ( (b.rateId && a.isError) || (b.rateId && b.total < a.total)){
          return 1;
        }
        return 0;
      }

    render() {
        const { shop, quotations, isSearch, checked, shippings, step, cp, transporter, shippingAddress, orderEdit } = this.props;
        const { checkedCarrier, checkedCarrierID } = this.state;

        console.log(quotations);
        let quotationsCopy = {};
        try{
            let quotArray = [];

            Object.entries(quotations).map(([key,carrier]) => {
                if (carrier.length > 0 ){
                    carrier[0].id = key;
                    quotArray.push(carrier[0]);
                }
            });
            quotArray.sort( this.compare );
            for (let i =0; i<quotArray.length; i++){
                var element = quotArray[i];
                if (element["id"]){
                    quotationsCopy[element["id"]]= element;
                }
            }
        }catch (e){
            quotationsCopy =quotations;
            console.log(e);
        }
        return (
            <React.Fragment>
                <h4>Seleccione la tarifa</h4>
                {Util.isSeeBalance() && (
                    <h5>
                        Saldo: <i className="icofont-dollar-true"></i>
                        <CurrencyFormat value={shop.quota} displayType={'text'} thousandSeparator={true} />
                    </h5>
                )}
                <hr></hr>
                {/*<Alert errorMessage="El servicio de guías REDPACK se encuentra en mantenimiento" />*/}
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2">Transportadora</th>
                            <th>Tipo de envio</th>
                            <th className="center">Días de envio</th>
                            <th>Costo</th>
                        </tr>
                    </thead>
                    <tbody>
                    {quotationsCopy &&  Object.entries(quotationsCopy).map(([key,quotation]) => (
                        <React.Fragment key={key}>
                            
                                    {quotation && quotation.rateId ? (
                                        <tr key={quotation.carrierId} 
                                            className={`row-orden ${(checked === quotation.rateId) ? "active" : "click"}`}
                                            onClick={() => this.handleClickCheck(quotation) }>
                                            <td className="center big-font-size">
                                                {(checked === quotation.rateId) ? (
                                                    <i className="icofont-check-circled"></i>
                                                ) : (
                                                    <i className="icofont-close-circled"></i>
                                                )}
                                            </td>
                                            <td>
                                                {(Util.CARRIERS_RATE[quotation.deliveryType] && Util.CARRIERS_RATE[quotation.deliveryType].hasOwnProperty("carrierName")) ? Util.CARRIERS_RATE[quotation.deliveryType].carrierName : quotation.carrier}
                                            </td>
                                            <td>
                                                {(Util.CARRIERS_RATE[quotation.deliveryType] && Util.CARRIERS_RATE[quotation.deliveryType].hasOwnProperty("deliveryTypeName")) ? Util.CARRIERS_RATE[quotation.deliveryType].deliveryTypeName : quotation.deliveryType}
                                            </td>
                                            <td className="center">
                                                {quotation.deliveryDays && quotation.deliveryDays.replace(".0", "")}
                                            </td>
                                            <td>
                                            <CurrencyFormat value={quotation.total} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                {Util.isCheckBalanceTransporter(quotation.carrierId, transporter) && quotation.total > shop.quota && (
                                                    <div>
                                                        Saldo insuficiente
                                                    </div>
                                                )}
                                                <br></br>
                                                {Util.CARRIERS_RATE[key].discount && (
                                                    <small>
                                                        <strike>
                                                        <CurrencyFormat value={quotation.total * Util.CARRIERS_RATE[key].discount} displayType={'text'} thousandSeparator={true} prefix={'$'} />                                                        </strike>
                                                    </small>
                                                )}
                                                {quotation.carrier ==="ESTAFETA" &&  this.isExtendedZone(cp) && (
                                                    <div>
                                                        Zona extendida
                                                        <Tooltips text="Envío a poblado distante o de difícil acceso con frecuencia de entrega menor a 2 veces por semana." />
                                                    </div>
                                                )}

                                                {quotation.carrier ==="ESTAFETA" &&  this.isHappensForced(cp) && (
                                                    <div className="error">
                                                        Ocurre forzoso
                                                        <Tooltips text="El paquete se entregará en la oficina Estafeta más cercana al domicilio de tu cliente y contará con un plazo de 5 días hábiles para recoger su paquete." />
                                                    </div>
                                                )}

                                                {/*this.isNotRFC(quotation.carrier) && (
                                                    <div className="error">
                                                        Falta RFC y CLAVE de productos para Carta Porte. 
                                                        <div><a href="/preferences" title="Preferencias">Agregar en Preferencias</a></div>
                                                    </div>
                                                )*/}
                                                
                                            </td>
                                        </tr>
                                    ) : quotation && quotation.isError ? null /*(
                                        <tr >
                                            <td colSpan="5" className="alert-danger">
                                                <div className="center">
                                                    <div className="loading content-flex flex-center" >
                                                        <span>Lo sentimos, con <b>{Util.CARRIERS_RATE[key].name}</b> no pudimos obtener el costo de envio.<br></br> {Constants.getMessage(quotation.message)}</span>
                                                    </div>    
                                                </div>
                                            </td>
                                        </tr>
                                    )*/ : (
                                        <tr >
                                            <td colSpan="5" style={{"background" : `${Util.CARRIERS_RATE[key].color}`}}>
                                                <div className="center">
                                                    <div className="loading content-flex flex-center" >
                                                        <div id="preloader">
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                        </div> 
                                                        <span>Calculando <img src={`/img/${Util.CARRIERS_RATE[key].img}`} className="load-img-carriers" alt={Util.CARRIERS_RATE[key].name} /></span>
                                                    </div>    
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                           
                    ))}
                    </tbody>
                </table>
                {checkedCarrierID && Util.CARRIERS_INFO[checkedCarrierID] && Util.CARRIERS_INFO[checkedCarrierID].maxStreet && shippingAddress.address1.length > Util.CARRIERS_INFO[checkedCarrierID].maxStreet && (
                    <Alert errorText={Util.CARRIERS_INFO[checkedCarrierID].name+" solo permite enviar "+Util.CARRIERS_INFO[checkedCarrierID].maxStreet+" caracteres en la dirección, como la dirección del destinatario es mayor, la misma será cortada. Por favor verifique que la dirección sea correcta: '"+shippingAddress.address1.slice(0, (Util.CARRIERS_INFO[checkedCarrierID].maxStreet - 1))+"'."} />
                )}
                {!orderEdit && (
                    <div className="content-flex flex-end">
                        <button onClick={() => step(0)} className="btn btn-link">Volver</button>
                        {checkedCarrierID && Util.CARRIERS_INFO[checkedCarrierID] && Util.CARRIERS_INFO[checkedCarrierID].maxStreet && shippingAddress.address1.length > Util.CARRIERS_INFO[checkedCarrierID].maxStreet ? (
                            <button onClick={() => step(85)} className="btn triciclo top-middle">Editar</button>
                        ) : (
                            <button onClick={() => shippings()} className="btn triciclo top-middle" disabled={isSearch || (checked === 0) ? true : false}>Crear etiqueta</button>
                        )}
                    </div>    
                )}
            </React.Fragment>   
        );
  }
}

export default RateSelector;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Constants from './../store/index';
import axios from "axios";
import Alert from '../components/html/Alert';
import { Redirect } from 'react-router';
class Packing extends Component {

    state ={
            isSearch: true,
            newEmpaque: false,
            actualizar : false,
            errorText:"",
            successText:"",
            backToPackings: false,
            msjAlert:""
    };

    addBox = () => { 
        const width = document.getElementById('width').value;
        const name = document.getElementById('name').value;
        const height = document.getElementById('height').value;
        const large = document.getElementById('large').value;
        const weight = document.getElementById('weight').value;

        const url = Constants.URL_API+"shippings/layouts";
        Constants.scrollToWindow();

        if (!validForm()) {
            this.setState({ errorText: "Debe completar todos los campos"})
        }else {
            const data = {
                id: "",
                name: name,
                height: height,
                length: large,
                measureUnit: "CM",
                weight: weight,
                weightUnit: "KG", 
                width: width,
                volume : width * large * height
            }
            axios.post(url, data,Constants.getHeaders()).then(response => response.data,error => {
                Constants.setErrors(error);
                return error;
            }).then((data) => {
                this.setState({ errorText: ""})
                this.setState({msjAlert: "Se agrego el empaque " + name + " correctamente" })
                this.setState({backToPackings : true})
            }); 
        } 
    }

    updateBox = (id) => { 
        this.setState({ actualizar: true, errorText :""});
        const width = document.getElementById('width').value;
        const name = document.getElementById('name').value;
        const height = document.getElementById('height').value;
        const large = document.getElementById('large').value;
        const weight = document.getElementById('weight').value;
        const url =  Constants.URL_API+"shippings/layouts/"+id;
        Constants.scrollToWindow();
        if (!validForm()) {
            this.setState({ errorText: "Debe completar todos los campos"})
        }else {
            const data = {
                id: id,
                name: name,
                height: height,
                length: large,
                measureUnit: "CM",
                weight: weight,
                weightUnit: "KG", 
                width: width,
                volume : width * large * height
            }
            axios.put(url, data,Constants.getHeaders()).then(response => response.data,error => {
                Constants.setErrors(error);
                return error;
              }).then((data) => {
                this.setState({ errorText: ""})
                this.setState({msjAlert: "Se actualizó el empaque " + name + " correctamente" })
                this.setState({backToPackings : true})
            });  
        }
        
    }

    deleteBox = (id) => { 
        const url =  Constants.URL_API+"shippings/layouts/"+id;
        Constants.scrollToWindow();
        axios.delete(url,Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            this.setState({msjAlert: "Se eliminó el empaque correctamente"})
            this.setState({backToPackings : true})
        });
    }

    render() {
        const { empaque } = this.props;
        const {errorText, successText, backToPackings, msjAlert} = this.state
        console.log(empaque);
        debugger
        return (
            <div>
                {backToPackings == false  ? (
                <div className="empaque ">
                    <Alert errorText={errorText} successText={successText} />
                    <h1>Empaques</h1>
                    {empaque ? (
                        this.state.actualizar  ? (
                        <div className="empaque content-widget">
                            <h4>Actualizar {empaque.name.toUpperCase()}</h4>
                            <React.Fragment>
                            <div className="content-package">
                                <p className="specification"><span>Alto:</span> <input 
                                placeholder={empaque.height}
                                id= "height" 
                                type="number" /> CM</p>
                            </div>
                            <div className="content-flex flex-center cont-width">
                                <p className="specification"><span>Ancho:</span> <input 
                                placeholder={empaque.width}
                                id= "width" 
                                type="number" /> CM</p>
                                <p className="specification"><span>Largo:</span> <input 
                                placeholder={empaque["length"]}
                                id= "large" 
                                type="number" /> CM</p>
                            </div>
                            <div className="content-flex flex-center cont-weight">
                                <p className="specification"><span>Peso:</span> <input 
                                id= "weight"   
                                placeholder={empaque.weight} 
                                type="number" /> KG</p>
                            </div>
                            <div className= "imput-align-text spacing-medium">
                                <input className= "input-text"  
                                id= "name" 
                                placeholder={empaque.name} />
                            </div>
                            <div className="content-flex flex-end">
                                <button className="btn triciclo" onClick={() => this.updateBox(empaque.id)}> Guardar </button>     
                                <button className="btn btn-link" onClick={() => this.setState({actualizar:false, errorText : ""})} > Cancelar </button>     
                            </div>                                          
                            </React.Fragment>
                        </div>  
                    ) : (
                        <div className="content-widget ">
                            <React.Fragment>
                            <h4>Detalles de {empaque.name.toUpperCase()}</h4>
                            <div className="content-package">
                                <p className="specification"><span>Alto:</span> {empaque.height} CM</p>
                            </div>
                            <div className="content-flex flex-center cont-width">
                                <p className="specification"><span>Ancho:</span> {empaque.width} CM</p>
                                <p className="specification"><span>Largo:</span> {empaque.length} CM</p>
                            </div>
                            <div className="content-flex flex-center cont-weight">
                                <p className="specification"><span>Peso:</span> {empaque.weight} KG</p>
                            </div>
                            <div className = "content-flex flex-end">
                                <button className="btn triciclo" onClick={() => this.setState({actualizar : true})} > Actualizar  </button> 
                                <button className="btn btn-link" onClick={() => this.deleteBox(empaque.id)} > Eliminar </button>     
                            </div>  
                            </React.Fragment>
                        </div> 
                    )
                    ) : (
                        <div className="content-widget ">
                            <React.Fragment>
                        <h4>Detalles del nuevo empaque</h4>
                        <div className="content-package">
                            <p className="specification"><span>Alto:</span> <input id= "height" type="number" /> CM</p>
                        </div>
                        <div className="content-flex flex-center cont-width">
                            <p className="specification"><span>Ancho:</span> <input id= "width" type="number" /> CM</p>
                            <p className="specification"><span>Largo:</span> <input id= "large" type="number" /> CM</p>
                        </div>
                        <div className="content-flex flex-center cont-weight">
                            <p className="specification"><span>Peso:</span> <input id= "weight" type="number" /> KG</p>
                        </div>
                        <div className= "imput-align-text spacing-medium">
                            <input className= "input-text"  id= "name"  placeholder="Nombre del empaque" />
                        </div>
                        <div className="content-flex flex-end">
                            <button className="btn triciclo" onClick={() => this.addBox()} > Guardar </button>     
                        </div>                                          
                        </React.Fragment>
                        </div> )     
                    }
                </div>
                ):
                (
                    <div className ="spacing-small">
                        <Redirect push to={"/packings/?message=" + msjAlert } ></Redirect>
                            </div>
                )
                }
            </div>
        )
    }
};
    


function validForm() {
    
    const width = document.getElementById('width').value;
    const name = document.getElementById('name').value;
    const height = document.getElementById('height').value;
    const large = document.getElementById('large').value;
    const weight = document.getElementById('weight').value;
    
    if (name == "" || width == ""  || height == "" || large == "" || weight == "" ) {
        return false
        
    }else {
        return true
        
    }
};
const mapStateToProps = (state) => ({
    empaque: state.package,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
   export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Packing);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputHtml from '../components/html/InputHtml';

class Dinamics extends Component {

    state = { 
       valueLocal: {} 
    };

    componentDidMount() {
        const { values } = this.props;
        if (values){
            this.setState({valueLocal: values});
        }
    }

    handleChange = (value, key) => {
        const { onchange, keys } = this.props;
        const { valueLocal } = this.state;
        valueLocal[key] = value;
        this.setState({valueLocal: valueLocal});
        if (onchange) {
            onchange(valueLocal, keys);
        }
    }

    onChange =  (evt, key, key2) => {
        const { onchange, keys } = this.props;
        const { valueLocal } = this.state;
        let value = evt.target.value;
        console.log(key);
        console.log(key2);
        console.log(value);
        if (! (valueLocal[key])){
            valueLocal[key] = {};
        }
        valueLocal[key][key2] = value;
        this.setState({valueLocal: valueLocal});
        if (onchange) {
            onchange(valueLocal, keys);
        }
    }

    render() {
        const { listConfig, edit } = this.props;
        const { valueLocal } = this.state;
        return (
            <div className="dinamics">
                {Object.keys(listConfig).map((key, index) => (
                    <div key={index}>
                        {listConfig[key].type === "string" ? (
                            <div> 
                                <InputHtml className="content-input" pre={`${listConfig[key].definedText ? listConfig[key].definedText : key}: `}  placeholder={(listConfig[key].placeholder) ? listConfig[key].placeholder : ""}  type="text" edit={edit} onBlur={this.handleChange} name={key} value={valueLocal && valueLocal[key] ? valueLocal[key] : ""}/>
                            </div>
                        ) : listConfig[key].type === "json" && (
                            <div className="padding-left-min">
                                
                                <label>{key}:</label>
                                {Object.keys(listConfig[key].json).map((key2, index2) => (
                                    <div key={index2}>
                                        {listConfig[key].json[key2].type === "string" && (
                                            <div className="content-input">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">{`${key2}: `} </span>
                                                </div>
                                                <input 
                                                    className="input-form" 
                                                    type="text" 
                                                    value={(valueLocal && valueLocal[key] && valueLocal[key][key2]) ? valueLocal[key][key2] : ""}
                                                    onChange={e => this.onChange(e, key, key2)} 
                                                    placeholder={(listConfig[key].json[key2].placeholder) ? listConfig[key].json[key2].placeholder : ""}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dinamics);

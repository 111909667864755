import React, { Component } from 'react';

class Tooltips extends Component {

    state = {
        isVisibleTooltip: false
    };
    
    
    onMLeave = () => {
        this.setState({ isVisibleTooltip: false });
    };

    onMEnter = () => {
        this.setState({ isVisibleTooltip: true });
    };

    render() {
        const { isVisibleTooltip } = this.state;
        const { text } = this.props;

        return (
            <React.Fragment>
                &nbsp;<i className="icofont-info-circle" onMouseEnter={this.onMEnter} onMouseLeave={this.onMLeave} ></i>

                {isVisibleTooltip && (
                    <div className="tooltip-component">{text}</div>
                )}
            </React.Fragment>     
        );
  }
}

export default Tooltips;
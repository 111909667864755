import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from './../store/index';
import Page from '../components/html/Page';
import CurrencyFormat from 'react-currency-format';
import * as Util from './../data/General';

class Recharge extends Component {

    state = {
        isSearch: true,
        url: "",
        filter: {
            from: 0,
            size: 10,
        },
        listRecharge: {},
        isScroll: false,
        listAmount: [
            {value:"30335575949417:1", label: "$1,000"},
            {value:"30335577981033:1", label: "$2,000"},
            {value:"30335575949417:3", label: "$3,000"},
            {value:"30335577981033:2", label: "$4,000"},
            {value:"30335578865769:1", label: "$5,000"},
            {value:"30335577981033:4", label: "$8,000"},
            {value:"31669168406633:1", label: "$10,000"},
            {value:"30335578865769:3", label: "$15,000"},
            {value:"32331168546921:1", label: "$20,000"},
            {value:"31669168406633:3", label: "$30,000"},
            {value:"39491459547334:1", label: "$50,000"},
            {value:"32331168546921:4", label: "$80,000"},
            {value:"39491459547334:2", label: "$100,000"},
        ],
        urlCar: "https://triciclo.myshopify.com" 
    };

    changeAmount = (value) =>{
        this.setState({ url: value});
    };   
    
    componentDidMount() {
        const { filter } = this.state;
        this.loadData(filter);
        setTimeout(function(){
            Util.loadDataUsers(this.props.dispatch, false);
          }.bind(this), 500);

        if (window.url_server === "https://dev.triciclogo.com/api/"){
          this.setState({
            listAmount: [
                {value:"39607849812173:1", label: "$1,000"},
                {value:"39607850074317:1", label: "$2,000"},
                {value:"39607849812173:3", label: "$3,000"},
                {value:"39607850074317:2", label: "$4,000"},
                {value:"39607850369229:1", label: "$5,000"},
                {value:"39607850008781:1", label: "$10,000"},
                {value:"39607850369229:3", label: "$15,000"},
                {value:"39607850107085:1", label: "$20,000"},
                         ],
            urlCar: "https://triciclogo-pay.myshopify.com/" 
          })
        } 
    }

    goTo = (from) => {
        const { filter } = this.state;
        filter.from = from;
        this.loadData(filter);
    }

    handleChangePage = (page) => {
        const { filter } = this.state;
        filter.size = page;
        filter.from = 0;
        this.loadData(filter);
        return false;  
    }  

    loadData = (filter) => {
        this.setState({ isSearch: true});
        const { shop } = this.props;
        const url = Constants.URL_API+"customers/"+shop[0].id+"/quota"+Constants.getFilter(filter);
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            this.setState({ isSearch: false, listRecharge: data, filter: filter});
        }); 
    }

    donwload = () => {
        this.setState({ isSearch: true});
        const { shop } = this.props;
        if (shop && shop.length > 0 && shop[0].id){
            const url = Constants.URL_API+"customers/"+shop[0].id+"/quota/downloadcsv";
            axios.get(url, Constants.getHeaders({"Content-Type": "application/octet-stream", Accept: "application/json, text/plain, */*; charset=ISO-8859-1"})).then(response => response.data)
            .then((data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                var universalBOM = "\uFEFF";
                var a = window.document.createElement('a');
                a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+data));
                a.setAttribute('download', `export_recharge.csv`);
                window.document.body.appendChild(a);
                a.click();
                this.setState({ isSearch: false});
                
            }); 
        }
      }
      
  
    render() {
        const { shop } = this.props;
        const { listRecharge, filter, isScroll, isSearch } = this.state;
        // 500: 30335507923049
        // 1,000: 30335575949417
        // 2,000: 30335577981033
        // 5,000: 30335578865769
        // 10,000: 31669168406633
        // 20,000: 32331168546921
        return (
            <div className="recharge orden">
                <div className="title">
                    <h1>
                        Tienes <i className="icofont-dollar-true"></i><CurrencyFormat value={shop[0].quota} displayType={'text'} thousandSeparator={true} /> de saldo actualmente. 
                    </h1>
                </div>
                <div className="content-flex">
                    <div className="content-widget">
                        <div>
                            <div className="content-flex space-between">
                                <div className="content-flex flex-center-left">
                                    
                                </div>
                                <div className="content-flex space-between">
                                    <div>
                                        <a className="btn triciclo m-right" href="https://triciclogo.com/pages/genera-tu-factura" target="_blank" rel="noopener noreferrer">
                                            Facturar recarga <i className="icofont-dollar-true"></i>
                                        </a> 
                                    </div> 
                                
                                    <button 
                                        onClick={() => this.donwload()} 
                                        className="btn triciclo right"
                                    >
                                        <i className="icofont-download"></i> Descargar CSV
                                    </button>
                                </div>
                            </div>
                            {/*<hr></hr>
                            <p>Tu recarga se verá reflejada de <b>inmediato*</b>. Si necesitas soporte, puedes enviarnos un correo a go@triciclo.mx y con gusto te atenderemos.</p>        
                            /*/}
                            <hr></hr>
                            <h2>Historial</h2>
                            <div className={`scroll-table ${isScroll ? "active" : ""}`} id="scroll-table">
                                <table>
                                    {isSearch && ( 
                                        <div className="center content-loading">
                                            <div className="loading">
                                                <div id="preloader">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div> 
                                                <span>Cargando Recargas</span>
                                            </div>    
                                        </div>
                                    )}
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Usuario</th>
                                            <th>Número de orden</th>
                                            <th>Cantidad</th>
                                            <th>Saldo anterior</th>
                                            <th>Saldo actual</th>
                                            <th className="width-20">Concepto</th>
                                        </tr>    
                                    </thead>
                                    <tbody>
                                        {listRecharge && listRecharge.length > 0 && listRecharge.map((recharge, index) => (
                                            <tr key={index}>
                                                <td className="nowrap" >
                                                    <i className="icofont-calendar"></i>  {Constants.getDate(recharge.createDate)}
                                                </td>
                                                <td>
                                                    {recharge.userName}
                                                </td>
                                                <td>
                                                    {recharge.orderName}
                                                </td>
                                                <td>
                                                    {recharge.quota && (
                                                        <CurrencyFormat value={recharge.quota} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                    )}
                                                </td> 
                                                <td>
                                                    {recharge.oldQuota && (
                                                        <CurrencyFormat value={recharge.oldQuota} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                    )}
                                                </td>
                                                <td>
                                                    {recharge.currentQuota && (
                                                        <CurrencyFormat value={recharge.currentQuota} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                    )}
                                                </td>
                                                <td className="width-20">
                                                    {recharge.type === "MANUAL" ? (
                                                        <span>
                                                            {recharge.quota > 0 ? (
                                                                <span>Recarga por administrador</span>
                                                            ) :  (
                                                                <span>Descuento por administrador</span>
                                                            )}
                                                        </span>
                                                    ) : recharge.type ==="AUTOMATIC" ? (
                                                        <span>Recarga por pago</span>
                                                    ) : recharge.type ==="SHIPMENT_CANCEL" && (
                                                        <span>Devolución por cancelación de guía</span>
                                                    )}
                                                </td>   
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <hr></hr>
                            <Page from={filter.from} size={filter.size ? filter.size : 0} elementSize={listRecharge.length} handleClick={this.goTo} handleChangePage={this.handleChangePage} />
                        </div>
                    </div>
                </div>    
            </div>
        );
        }
    }
const mapStateToProps = (state) => ({
    shop: state.shop,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Recharge);

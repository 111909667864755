import React, { Component } from 'react';



class CheckboxUnit extends Component {

    componentDidMount() {
        
    }

    onChange = () => {
        const { onBlur, id, selected, onBlurExtra, extra } = this.props;
                
        if (onBlur){
            onBlur(!selected, id);
        }else if (onBlurExtra){
            onBlurExtra(!selected, id, extra);
        }
    }

    render() {
        const { edit, name, label, id, selected, disabled } = this.props;
        return (
            <div>
                {edit ? (
                    <label className="form-check-conteiner">{label && label}
                        <input type="checkbox" name={name} id={id} onChange={() => this.onChange()} 
                            value={name} checked={selected} disabled={disabled}
                        /> 
                        <span className="form-check-span"></span>
                    </label>
                   ) : (
                    <div>
                        {label}           
                    </div>     
                )}  
            </div>
        );
  }
}

export default CheckboxUnit;
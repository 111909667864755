import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { connect } from 'react-redux';
import App from './../../App';
import Login from './../../pages/Login';


class DefaultLayout extends Component {
  render() {
    const { component: ReceivedComponent, user } = this.props;
    if (this.props.location.pathname === '/callback' || (user && user.idToken)){
      return (
          <Route>
            <App>
              <ReceivedComponent {...this.props}/>
            </App>
          </Route>
      );
    }else{
      return (
        <Login />
      );
    }
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayout);
import React, { Component } from 'react';
import Send from './Send';


class ShippingInformation extends Component {

    render() {
        const { fulfillmentStatus, shippingLines, fulfillments } = this.props;
        
        return (
            <React.Fragment>
                <Send fulfillmentStatus={fulfillmentStatus} fulfillments={fulfillments} />
                <div className="destination without-left">
                    {shippingLines && shippingLines.length > 0 && shippingLines[0].title && 
                        (<span>{shippingLines[0].title}</span>)}
                </div>
                <div className="destination without-left">
                    {shippingLines && shippingLines.length > 0 && shippingLines[0].price && (
                        <span>{shippingLines[0].price === "0.00" ? "Sin costo" : `$ ${shippingLines[0].price}`}</span>
                    )}
                </div>
            </React.Fragment>     
        );
  }
}

export default ShippingInformation;
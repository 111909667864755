import React, { Component } from 'react';

class GetPackage extends Component {

    render() {
        const { packageGet } = this.props;

        return (
            <React.Fragment>
                <i className="icofont-box"></i> {packageGet.name}{" "}
                {packageGet.weight}{packageGet.weightUnit === "K" && "KG. "}
                ({packageGet.length} * {packageGet.width} * {packageGet.height} {packageGet.measureUnit === "C" && "CM. "})
            </React.Fragment>     
        );
  }
}

export default GetPackage;
import React, { Component } from 'react';

class SelectSearch extends Component {

    state = {
        search: "",
        isVisible: false,
        list: [],
        select: ""
    }

    componentDidMount() {
        const { rows, value } = this.props;
        let search = "";
        for (var i=0; i < rows.length; i++) {
            var row = rows[i];
            if (row.id === value){
                search = row.name;
            }
        }

        this.setState({list: rows, select: value, search: search});
    }

    onChange = (evt) => {
        const { rows } = this.props;
        let value = evt.target.value;
        console.log(value);
        if (value === ""){
            this.setState({search: "", isVisible: true, list: rows});
        }else{
            value = value.toLowerCase();
            const list = rows.filter(row => {
                return row.name.toLowerCase().includes(value);
            });
        
            this.setState({search: value, isVisible: true, list: list});
        }
    }

    onClick = (id, value) => {
        const { onChange } = this.props;
        this.setState({search: value, select: id, isVisible: false});
        if(onChange){
            onChange(id);
        }
    }

    open = () => {
        const { rows } = this.props;
        this.setState({isVisible: true, search: "", list: rows});
    }

    onKeyUpValue(event) {
        console.log(event.target.value);
        
    }

    render() {
        const { search, isVisible, list } = this.state;
        const { value } = this.props;
        

        return (
            <React.Fragment>
                {isVisible && (
                    <div className="icon-search-icon">
                        <i className="icofont-search"></i>
                    </div>
                )}
                <input type="text" placeholder="Buscar..." value={search} onClick={() => this.open()} className={`click form-control ${isVisible ? 'active' : ''}`} onChange={e => this.onChange(e)} onKeyUp={this.onKeyUpValue.bind(this)} />
                <div className={`icon-search-text ${isVisible ? 'active' : ''}`} onClick={() => this.setState({isVisible: !isVisible})}>
                    <i className="icofont-thin-down"></i>
                </div>
                {isVisible && (
                    <div className="search-text">
                        <ul>
                            {list.length > 0 && list.map((pack) => (
                                <li className={`${value === pack.id ? 'active' : ''}`} onClick={() => this.onClick(pack.id, pack.name)} key={pack.id}>
                                    {pack.name}
                                    {value === pack.id && (
                                        <div className="icon-search-option">
                                            <i className="icofont-check-alt"></i>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </React.Fragment>     
        );
  }
}

export default SelectSearch;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from './../store/index';
import GetPackageShipping from '../components/widget/GetPackageShipping';
import { Link  } from 'react-router-dom';
import Page from '../components/html/Page';
import * as Util from './../data/General';

class ListPickups extends Component {

    state = {
        isSearch: false,
        isScroll: false,
        shopsCustomerId: null,
    };

    componentDidMount() {
        const { pickups, shop } = this.props;
        let filter = Constants.filterPickups;
        this.loadData(filter);
        if (pickups && pickups.list && pickups.list.length > 0){
            document.getElementById('scroll-table').addEventListener("scroll", this.updateScroll);
        }
        this.setState({shopsCustomerId: Util.getListCustomerId(shop)}); 
    }
    updateScroll = () =>{
        const { isScroll } =this.state;
        if (document.getElementById('scroll-table').scrollLeft > 0){
            this.setState({ isScroll: true});
        }else if(isScroll){
            this.setState({ isScroll: false});
        }
    }
    loadData = (filter) => {
        this.setState({ isSearch: true});
        const { config } = this.props;
        filter.size = config["sizePickups"];
        const url = Constants.URL_API+"shippings/pickups"+Constants.getFilter(filter);
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            this.props.dispatch({
                type: 'CHANGE_LIST_PICKUPS',
                list: data,
                filter: filter
            });
            this.setState({ isSearch: false});
        }); 
    }
    changePage = (page) => {
        this.loadData(page);
    }
    
    setText = (e) => {
        const { pickups } = this.props;
        let value = e.target.value;
        pickups.filter.text = value;
        pickups.filter.from = 0;
        this.loadData(pickups.filter);
    }
    setSortOrder = (sort) => {
        const { pickups } = this.props;
        pickups.filter.sortOrder = sort;
        pickups.filter.from = 0;
        this.loadData(pickups.filter);
    }

    handleChangePage = (page) => {

        const { pickups, config } = this.props;
        config["sizePickups"] = page;
        
        this.props.dispatch({
            type: 'CHANGE_CONFIG',
            config: config
        });

        pickups.filter.size = page;
        pickups.filter.from = 0;
        this.loadData(pickups.filter);

        return false;  
    } 
    
    goToPaidOrder = () => {
        window.location.href = "/new-pickup";
    }


    handleClickpickup = (pickup) => {
        /*this.props.dispatch({
            type: Constants.types.CHANGE_SHIPPING,
            pickup: pickup
        });*/
        console.log(pickup);
        this.setState({ isSearch: true});
        const url = Constants.URL_API+"shippings/"+pickup.shipmentId;
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
            }).then((data) => {
            console.log(data);
            if (data){
                let shipping = data;
                this.props.dispatch({
                    type: Constants.types.CHANGE_SHIPPING,
                    shipping: shipping
                });              
                window.location.href = "/shipping-tg";      
            }else{
                this.setState({ isSearch: false});
            }
        }); 
        return false;  
    }  

    setFulfillmentStatus = (status) => {
        const { pickups } = this.props;
        pickups.filter.status = status;
        pickups.filter.from = 0;
        this.loadData(pickups.filter);
    }
    setSortBy = (sort) => {
        const { pickups } = this.props;
        pickups.filter.from = 0;
        if (sort !== ""){
            let by = sort.split("*");
            pickups.filter.sortBy = by[0];
            pickups.filter.sortOrder = by[1];
            this.loadData(pickups.filter);
        }else{
            pickups.filter.sortBy = "createdAt";
            pickups.filter.sortOrder = "desc";
            this.loadData(pickups.filter);
        }
    }

    setDate = (sort) => {
        const { pickups } = this.props;
        pickups.filter.sortOrder = sort;
        pickups.filter.from = 0;
        this.loadData(pickups.filter);
    }

    goTo = (from) => {
        const { pickups } = this.props;
        pickups.filter.from = from;
        this.loadData(pickups.filter);
    }
  
    render() {
        const { pickups, config } = this.props;
        const { isSearch, isScroll, shopsCustomerId } = this.state;
        return (
            <div className="shippings pickups">
                <h1>Recolecciones</h1>
                <div className="content-widget">
                    <h4>
                     <button onClick={() => this.goToPaidOrder()} className="btn triciclo right" disabled={isSearch ? true : false}>Nueva</button>
                    </h4>
                    <hr></hr>
                    
                {pickups && pickups.list && pickups.list.length > 0 && (
                    <div className={`scroll-table ${isScroll ? "active" : ""}`} id="scroll-table">
                        <table>
                            {isSearch && ( 
                                <div className="center content-loading">
                                    <div className="loading">
                                        <div id="preloader">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div> 
                                        <span>Cargando Envios</span>
                                    </div>    
                                </div>
                            )}
                            <thead>
                                <tr>
                                    <th>Orden</th>
                                    <th>Fecha de recolección</th>
                                    <th>Transportadora</th>
                                    <th>Número de reporte</th>
                                    <th>Estado</th>
                                </tr>    
                            </thead>
                            <tbody>
                                {pickups.list.map((pickup) => (
                                    <tr key={pickup.id}>
                                        <td className="nowrap">
                                            {pickup.orderId && pickup.orderId !== "" ? (
                                                <React.Fragment>
                                                    <i className="icofont-grocery shopify"></i>&nbsp; 
                                                    <Link to={`/order/${pickup.orderId}`} >
                                                        {pickup.orderName}
                                                    </Link>
                                                </React.Fragment>    
                                            ) : (
                                                <React.Fragment>
                                                    <i className="icofont-map-pins"></i>&nbsp;
                                                    <Link to="#" onClick={() => this.handleClickpickup(pickup)} >
                                                        Triciclo GO envio
                                                    </Link>
                                                </React.Fragment>    
                                            )}  

                                            <div className="destination orden-info">
                                                <i className="icofont-calendar"></i>  {Constants.getDate(pickup.createDate)}
                                            </div>
                                            {shopsCustomerId && shopsCustomerId[pickup.customerId] && shopsCustomerId[pickup.customerId].shopifyDomain && pickup.orderId && (
                                                <div className="destination orden-info">
                                                    <a href={`https://${shopsCustomerId[pickup.customerId].shopifyDomain}/admin/orders/${pickup.orderId}`}  className="ver-shopify-btn" target="_blank">
                                                        Ver en <i className="icofont-grocery"></i>
                                                    </a>
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            {pickup.date}{" a las "}{pickup.readyByTime}
                                        </td>
                                        <td>
                                            <GetPackageShipping shipping={pickup} />
                                        </td> 
                                        <td>
                                            {pickup.pickupCode}
                                        </td>
                                        <td>
                                            <span>Recolección pautada</span>
                                        </td>   
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <hr></hr>
                    <Page from={pickups.filter.from} size={config["sizePickups"] ? config["sizePickups"] : 0} elementSize={pickups.list.length} handleClick={this.goTo} handleChangePage={this.handleChangePage}/>
                </div>
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    pickups: state.pickups,
    today: state.today, 
    yesterday: state.yesterday, 
    lastWeek: state.lastWeek, 
    lastMonth: state.lastMonth,
    shop: state.shop,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

// `connect` returns a new function that accepts the component to wrap:

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListPickups);

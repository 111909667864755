import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from './../store/index';
import Send from '../components/html/Send';
import GetName from '../components/html/GetName';
import InputHtml from '../components/html/InputHtml';
import CurrencyFormat from 'react-currency-format';

import  * as Util from './../data/General';
import Alert from '../components/html/Alert';
import SelectHtml from '../components/html/SelectHtml';
import 'react-dates/initialize';
import 'moment/locale/es';
import ShippingOrder from '../components/html/ShippingOrder';

class Return extends Component {

    state = {
        isSearch: false,
        quotations:null, 
        checked: 0,
        checkedName: "",
        carrierId: 0,
        errorText:"",
        alertText: "",
        successText: "",
        shippingAddress: {},
        shippingOrign: null,
        orderEdit: false,
        textSearch: "Buscando datos de la orden",
        urlPDF: "",
        returnOrder: false,
        packageList:null,
        type: "order",
        insuredAmount: 0,
        totalAmount: 0,
        step: 0,
        order: null
    };

    componentDidMount() {
        //https://triciclogo-dev-guias.s3.amazonaws.com/dhl/0026285066.pdf
        const { orden_props } = this.props;
        Constants.scrollToWindow();

        this.setState({orden: orden_props});
        this.calculaTotal(orden_props);
        
    }

    loadPLS = (partialListShipped, shipping) => {
        if (shipping.items){
            Object.keys(shipping.items).map((key)=>{
                if (!partialListShipped){
                    partialListShipped = [];
                }
                let value = (shipping.items[key]);
                partialListShipped[value.id] = value;
                partialListShipped[value.id]["tracker"] = shipping.tracker;
                partialListShipped[value.id]["trackerUrl"] = shipping.trackerUrl;
                partialListShipped[value.id]["createDate"] = shipping.createDate;
                partialListShipped[value.id]["guideUrl"] = shipping.guideUrl;
                partialListShipped[value.id]["status"] = shipping.status;
            });
        }
        return partialListShipped;
    }

    loadPartialListShipped = (shipping) => {
        let partialListShipped = null;
        console.log("loadPartialListShipped");
        if (shipping && shipping.items){   
            partialListShipped = this.loadPLS(partialListShipped, shipping);
        }else{
            //alert("entro3");
            Object.keys(shipping).map((key)=>{
                let ship = (shipping[key]);
                partialListShipped = this.loadPLS(partialListShipped, ship);
            });
        }
        if (partialListShipped){
            this.setState({partialListShipped : partialListShipped, partial: true});
        }

    }

    loadDataCarriers = (idShop) => {
        const url = Constants.URL_API+"shippings/carriers/carrier-customer/?customerId="+idShop;
        console.log(url);
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            const { carriers } = this.state;
            if (data.length > 0){
                for (var i=0; i < data.length; i++) {
                    var value= data[i];
                    carriers[value.carrierId] = value;
                }
                this.setState({carriers: carriers});
                console.log(carriers);
            }
        }); 
    }

    calculaTotal = (orden) =>{
        let amount=0;
        if(orden && orden.lineItems){
            Object.entries(orden.lineItems).map(([key,value])=>{
                amount = amount + value.price; 
            });
            this.setState({
                totalAmount: amount,
            });
        }
    }

   
    setUrlPDF = (idOrder) => {
        const { shipping } = this.props;
        let shippingNew = shipping;
        if (shipping.length > 1){
            shippingNew=shipping[shipping.length-1];
        }
        if (shippingNew && shippingNew.orderId === idOrder){
            let url = "";
            if (shippingNew.carrierId !== "LALAMOVE"){
                if (shippingNew.carrierId !== "PAQUETEXPRESS"){
                    url = shippingNew.guideUrl;
                }else{
                    url = "https://www.autososcar.com.ar/paquetexpress/?tracking="+shippingNew.tracker;
                }    
            }else{
                url = shippingNew.trackerUrl;
            }
            
            this.setState({ urlPDF: url});
        }
    }

    handleClickOrden = (idOrden) => {
        this.setState({ isSearch: true, step: -1});
        const url = Constants.URL_API+"orders/"+idOrden;
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            this.props.dispatch({
                type: Constants.types.CLICK_ORDEN,
                orden: data
            });
            const { shopState } = this.props;
            let shop = Util.getShop(shopState, data.customerId);
            if(data && data.shippingAddress){
                this.setState({ shippingAddress: data.shippingAddress, isSearch: false, shop: shop});
            }else{
                this.setState({ isSearch: false, shop: shop});
            }
            this.calculateWeight();
        }); 
        return false;
    }


    handleClickPackage = (pack) => {
        const { packageList, personalizad } = this.state;
        console.log(pack);
        let selected_package = null;
        if (pack === "0") {
            selected_package = personalizad;
        }else{
            for (var i in packageList.list) {
                let element = packageList.list[i];
                console.log(element.id === pack);
                if(element.id === pack){
                    selected_package = element;
                }
            }
        }
        console.log(selected_package);
        packageList.selected = selected_package;
        console.log(packageList);
        console.log("2");
        this.setState({packageList: packageList});
        this.props.dispatch({
            type: Constants.types.CLICK_PACKAGE,
            selected: selected_package
        });
        return false;  
    }

    validateText = () => {
        const { shippingAddress } = this.state;
        const { orden } = this.props;
        //let isMax = Util.validateMax(shippingAddress, 35);
        let alertText = "";
        
        /*if (isMax){
            alertText = "Hay campos de la dirección que son muy largos, por favor tenga en cuenta que si continúa sin editar estos campos serán cortados. ";
        }*/
        if(shippingAddress && orden && orden.customer){
            /*if (shippingAddress.email === null ||  shippingAddress.email === "" || orden.customer.email === null || orden.customer.email === "" || orden.email === null || orden.email === ""){
                //alertText+="El correo del cliente esta vacio. "
            }*/
            if (shippingAddress.phone === null || shippingAddress.phone === ""){
                alertText+="El telefono del cliente esta vacio. "
            }
        }
            
        this.setState({ errorText: alertText}); 

    }

   

    changeCheckPartial = () =>{
        const { partial } = this.state;

        this.setState({partial: !partial});
    }

    selectedPartial = (selected, id, extra) => {
        const { partialList } = this.state;

        if (selected){
            partialList[id] = {
                "id": id,
                "quantity": extra,
            };
        }else{
            partialList[id] = null;
        }
        this.setState({partialList: partialList});
        console.log(partialList);
    }

    handleChange = (value, name) =>{
        const { orden } = this.state;
        orden.returnsData[parseInt(name)].status = value;
        this.setState({
            orden: orden
        });
    }

    handleshopReason = (value, index) =>{
        const { orden } = this.state;
        orden.returnsData[index]["shopReason"] = value;
        this.setState({
            orden: orden
        });
    }

    save = (indexReturn) =>{
        const { orden } = this.state;
        this.setState({alertText: "", errorText: "", successText: ""});

        const url =  Constants.URL_API+"orders/"+orden.id+"/returns/"+orden.returnsData[indexReturn].id;
        Constants.scrollToWindow();

        const data = {
            "email": orden.email,
            "clientReason": orden.returnsData[indexReturn]["clientReason"],
            "shopReason": orden.returnsData[indexReturn]["shopReason"],
            "description": orden.returnsData[indexReturn].description,
            "refundType": orden.returnsData[indexReturn].refundType,
            "status": orden.returnsData[indexReturn].status,
            "lineItems": orden.returnsData[indexReturn].lineItems
        }
        axios.put(url, data,Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            if (data.id){
                this.setState({successText: "Devolución actualizada con exito"});
                this.props.dispatch({
                    type: Constants.types.CHANGE_LIST_RETURNS_SELECTED,
                    selected: data
                });
            }
        });  
    }
  
    render() {
        const { shipping } = this.props;
        const { isSearch, orden, shippingOrign, shop, step, type, shippingTG, successText, totalAmount,  alertText, errorText, shippingAddress, orderEdit, textSearch, newShipping, shopAddresses } = this.state;
        console.log(orden);
        return (
            <div className="orden">
                {isSearch && ( 
                    <div className="center content-loading">
                        <div className="loading">
                            <div id="preloader">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div> 
                            <span>{textSearch}</span>
                        </div>    
                    </div>
                )}
                {orden && (
                    <div className=" content-flex space-between-start">
                        <div className="title">
                                <React.Fragment>
                                    <h1>
                                        {orden.name} 
                                    </h1>
                                    <div className="sub-title">
                                        <p>{orden.createdAt}</p>
                                    </div>
                                    <Send fulfillmentStatus={orden.fulfillmentStatus} />&nbsp;
                                </React.Fragment>    
                        </div> 
                    </div>
                )}
                <div className="content-flex">
                    <div className="content-widget">
                        <Alert errorText={errorText} warningText={alertText} successText={successText} />
                        {orden && orden.returnsData.length > 0 && orden.returnsData.map((returnData, indexReturn) => (
                            <React.Fragment key={indexReturn}>
                                <div>
                                    <label>
                                        Razon de la devolución: <b>{returnData.clientReason}</b>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Descripción: <b>{returnData.description}</b>
                                    </label>
                                </div>
                                <hr></hr>
                                <h2>Productos devueltos</h2>
                                <p></p>
                                {orden && returnData.lineItems && returnData.lineItems.map((item, index) => (
                                    <div key={index}>
                                        <div>
                                            <label><b>{item.name}</b></label>
                                        </div>
                                        {item.images && item.images.length > 0 && (
                                            <div>
                                                <img className="img-return" src={item.images[0]} />
                                                <p><small>(*) Imagen facilitada por el cliente</small></p>
                                            </div>
                                        )}
                                        <p></p>
                                        <div>
                                            <label>
                                                Dinero a devolver: <b><CurrencyFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /> de <CurrencyFormat value={totalAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b>
                                            </label>
                                        </div>
                                        
                                    </div>           
                                ))}
                                <div className="label">Comentario: <InputHtml type="text" required={true} edit={true} onBlur={this.handleshopReason} name={indexReturn} value={returnData.shopReason}/></div>
                                    <div className="label">Estado: 
                                        {("PENDING" === returnData.status || "REJECTED" === returnData.status ) ? (
                                            <SelectHtml edit={true} options={Util.STATE_RETURNS} required={true} onChange={this.handleChange} name={indexReturn} value={returnData.status}/>
                                        ) : (
                                            <SelectHtml edit={true} options={Util.STATE_RETURNSRECEIVED} required={true} onChange={this.handleChange} name={indexReturn} value={returnData.status}/>
                                        )}    
                                    </div>
                                    <div className="content-flex flex-end">
                                    <button onClick={() => this.save(indexReturn)} className="btn btn-secondary">Guardar</button>
                                </div> 
                                
                                
                            </React.Fragment>
                        ))}


                          
                    </div>
                        {((orden && orden.customer) || newShipping || shippingTG) ? (
                            <div className="content-widget client">
                                <h5>Dirección de origen</h5>
                                <hr></hr>
                                {shop && shippingOrign === null ? (
                                    <SelectHtml edit={orderEdit} options={shop.addresses} required={true} onChange={this.handleChangeAddress} name="shopAddresses" defaultText={`Principal: ${Util.getDataShop(shop, shop.addresses, shopAddresses, "street1")}, ${Util.getDataShop(shop, shop.addresses, shopAddresses, "city")}, ${Util.getDataShop(shop, shop.addresses, shopAddresses, "zip")}  `} defaultValue={-1} value={shopAddresses} keyValue={Constants.addressSelect}/> 
                                ) : shop && shippingOrign && (
                                    <label class="mode-edit">{shippingOrign.street1}, {shippingOrign.street2}, {shippingOrign.city}, {shippingOrign.zipCode} </label>
                                )}
                                <div className="address-order">
                                    <h5>Cliente</h5>
                                    <hr></hr>
                                    {(!newShipping && !shippingTG && type !== "pickup-local") && (
                                        <React.Fragment>
                                            <p><GetName customer={orden.customer} /></p>
                                            <p>{orden.customer.email}</p>
                                        </React.Fragment>    
                                    )}
                                    {(shippingTG || type === "pickup-local")  && (
                                        <React.Fragment>
                                            <p><GetName customer={shippingAddress} /></p>
                                            <p>{shippingAddress.email}</p>
                                        </React.Fragment>    
                                    )}
                                    {newShipping && (
                                        <React.Fragment>                                            
                                            <div className="label">{orderEdit && "Nombre:"} <InputHtml required={true} type="text" edit={orderEdit} onBlur={this.handleChange} name="firstName" value={shippingAddress.firstName}/></div>
                                            <div className="label">{orderEdit && "Apellido:"} <InputHtml type="text" required={true} edit={orderEdit} onBlur={this.handleChange} name="lastName" value={shippingAddress.lastName}/></div>
                                            <div className="label">{orderEdit && "Email:"} <InputHtml type="text" required={true} edit={orderEdit} onBlur={this.handleChange} name="email" value={shippingAddress.email}/></div>
                                        </React.Fragment>
                                    )}
                                </div>
                                {(!newShipping && !shippingTG) && shipping && shipping.orderId === orden.id ? (
                                    <div className="state-shipping">
                                        <hr></hr>
                                        <h5>Estado del envio</h5>
                                        <hr></hr>
                                        <ShippingOrder shipping={shipping} Constants={Constants} />
                                    </div>
                                ) : (shipping && shipping.length > 0 && orden.fulfillmentStatus) && (
                                    <div className="state-shipping">
                                        <hr></hr>
                                        <h5>Estado del envio</h5>
                                        <hr></hr>
                                        {Object.keys(shipping).map((key)=>(
                                            <React.Fragment key={key}>
                                                <ShippingOrder shipping={shipping[key]} Constants={Constants} />
                                                <hr></hr>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                )}
                                {shippingAddress && (
                                    <div>
                                        <hr></hr>
                                        <h5>Dirección de envio</h5>
                                        <hr></hr>
                                        {!newShipping && <p><GetName customer={shippingAddress} /></p>}
                                        <p>{orden.shippingLines && orden.shippingLines[0] && orden.shippingLines[0].title && (
                                            <span>{orden.shippingLines[0].title}</span>
                                            )}
                                        </p> 
                                        {((shippingAddress && shippingAddress.address1)  || orderEdit) && (
                                        <React.Fragment>
                                            <div className="label">{orderEdit && "Teléfono:"} <InputHtml type="text" required={true} edit={orderEdit} onBlur={this.handleChange} name="phone" value={shippingAddress.phone}/></div>
                                            <div className="label">{orderEdit && "Dirección:"} <InputHtml type="text" required={true} maxlength="35" edit={orderEdit} onBlur={this.handleChange} name="address1" value={shippingAddress.address1}/></div>
                                            <div className="label">{orderEdit && "Colonia:"} <InputHtml type="text"maxlength="35" edit={orderEdit} onBlur={this.handleChange} name="address2" value={shippingAddress.address2}/></div>
                                            <div className="label">{orderEdit && "Ciudad:"} <InputHtml type="text" required={true} edit={orderEdit} onBlur={this.handleChange} name="city" value={shippingAddress.city}/></div>
                                            <div className="label">{orderEdit && "Código postal:"} <InputHtml type="text" required={true} edit={orderEdit} onBlur={this.handleChange} name="zip" value={shippingAddress.zip}/></div>
                                            <div className="label">{orderEdit && "Estado:"} <InputHtml type="text" edit={orderEdit} required={true} onBlur={this.handleChange} name="province" value={shippingAddress.province}/></div>
                                            <div className="label">{orderEdit && "País:"} <SelectHtml edit={orderEdit} options={Util.COUNTRY} required={true} onChange={this.handleChange} name="countryCode" value={shippingAddress.countryCode}/></div>
                                        </React.Fragment>
                                        )}
                                        
                                          
                                    </div>
                                )}  
                            </div>
                        ) : orden && !orden.customer && (
                            <div className="content-widget client">
                                <h5>Cliente</h5>
                                <hr></hr>
                                <p>Ningún cliente</p>
                            </div>
                        )}
                        
                    </div>
                    {orden && orden.lineItems && (
                        <div className="content-flex">
                            <div className="content-widget not-margin-right">
                                <h5>Productos por un valor total de <CurrencyFormat value={totalAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <hr></hr> 
                                <table>
                                        <thead>
                                            <tr>
                                                <th className="width-20">Nombre</th>
                                                <th className="center">Peso</th>
                                                <th>SKU</th>
                                                <th>Precio unitario</th>
                                                <th className="center">Cantidad</th>
                                                <th>Precio total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {orden && orden.lineItems && orden.lineItems.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.title}</td>
                                                <td className="center">{item.grams}</td>
                                                <td>
                                                    {shop && (
                                                        <a href={`https://${shop.shopifyDomain}/search?q=${item.sku}`} target="_blank">
                                                            {item.sku}
                                                        </a>
                                                    )}
                                                </td>
                                                <td><CurrencyFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                                <td className="center">{item.quantity}</td>
                                                <td><CurrencyFormat value={item.price * item.quantity} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                            </div>            
                        </div>
                    )}

                </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    orden_props: state.returns.selected,
    packageListProps: state.packageList,
    shopState: state.shop,
    user: state.user,
    shipping: state.shipping,
    today: state.today,
    tomorrow: state.tomorrow,
    pickup: state.pickups,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Return);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Constants from './../store/index';
import * as Util from './../data/General';
import { Link  } from 'react-router-dom';
import Alert from '../components/html/Alert';



class ListPackings extends Component {

    state = {
        isSearch: false,
        message: ""
        // newEmpaque: false
    };

    componentDidMount() {
        this.setState ({newEmpaque: false}) 
        const urlMsg = window.location.search;
        const urlParams = new URLSearchParams(urlMsg);
        const message = urlParams.get('message');
        this.setState({ message: message});
        const { empaques } = this.props;
        this.loadData(empaques.filter);
    }
    loadData = (filter) => {
        this.setState({ isSearch: true});
        Util.loadLayouts(this.props.dispatch, Constants.getFilter(filter), this.loadDataFinish);
    }

    loadDataFinish = () => {
        this.setState({ isSearch: false});
    }

    handleClickEmpaque = (empaque) => {
        this.props.dispatch({
            type: Constants.types.CLICK_EMPAQUE,
            empaque: empaque
        });
        return false;  
    }  
    handleClickNewEmpaque = () => {
        this.props.dispatch({
            type: Constants.types.CLICK_EMPAQUE,
            
        });
        return false;  
    }  

    goTo = (from) => {
        const { empaques } = this.props;
        empaques.filter.from = from;
        this.loadData(empaques.filter);
    }
    handleChangePage = (page) => {

        const { empaques } = this.props;
        empaques.filter["size"] = page;
        empaques.filter["from"] = 0;
        
        this.props.dispatch({
            type: 'CHANGE_LIST_EMPAQUES_CONFIG',
            filter: empaques.filter
        });
        this.loadData(empaques.filter);

        return false;  
    }
    render() {
        const { empaques } = this.props;
        const { isSearch, message } = this.state;
        return (
            <div className="mpaques">
                <h1>Empaques</h1>
                <Alert  successText={message} />
                <h6>Guarda los paquetes que usas más seguido para acelerar el proceso de compra de etiquetas.</h6>

                <div className="content-widget">
                <Link to={`/packing/new-empaque`} >
                <div className ="spacing-small">
                     <button className="btn triciclo" onClick={() => this.handleClickNewEmpaque()} >  Nuevo empaque</button>
                </div>
                </Link>
                {empaques && empaques.list && empaques.list.length > 0 ? (
                    <div>
                    <table>
                        {isSearch && ( 
                            <div className="center content-loading">
                                <div className="loading">
                                    <div id="preloader">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div> 
                                    <span>Cargando Empaques</span>
                                </div>    
                            </div>
                        )}
                        <thead>
                                <tr>
                                    <th>
                                        Nombre
                                    </th>
                                    <th>
                                      Largo (Cm.)
                                    </th>
                                    <th>
                                        Ancho (Cm.)
                                    </th>
                                    <th>
                                        Alto (Cm.)
                                    </th>
                                    <th>
                                     Peso (Kg.)
                                    </th>
                                </tr>    
                            </thead>
                            <tbody>
                                {empaques.list.map((empaque) => (
                                    
                                    <tr key={empaque.id}>
                                        <td className="nowrap" onClick={() => this.handleClickEmpaque(empaque)}>
                                        <Link to ={`/packing/${empaque.id}`}>
                                        <i className="icofont-box"></i>&nbsp; {empaque.name.toUpperCase() } 
                                        </Link>
                                        </td>
                                        <td> 
                                           {empaque["length"]}
                                        </td>
                                        <td> 
                                           {empaque.width} 
                                        </td>
                                        <td> 
                                           {empaque.height} 
                                        </td>
                                        
                                        
                                        <td> 
                                         {empaque.weight} 
                                        </td>
                                        <td>
                                            <div className="spacing">
                                            <Link to ={`/packing/${empaque.id}`}>
                                            <div className= "btn-icon" onClick={() => this.handleClickEmpaque(empaque)}><i className="icofont-edit"></i></div>
                                        </Link>
                                            </div>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                    </table>
                    {/*<hr></hr>
                    <Page from={empaques.filter.from} size={empaques.filter.size} elementSize={empaques.list.length} handleClick={this.goTo} handleChangePage={this.handleChangePage} />*/}
                    </div> 
                ): (
                    <div className="no-data">
                        <div className="content-flex flex-center">
                            <i className="icofont-search-2"></i>
                        </div>    
                        <div className="content-flex flex-center">
                            <h2>No hay empaques</h2>
                        </div>      
                    </div>
                )}

            </div>
        </div>
        );
    }
}
        

const mapStateToProps = (state) => ({
    empaques: state.packageList,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

// `connect` returns a new function that accepts the component to wrap:

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListPackings);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputHtml from '../components/html/InputHtml';

import * as Constants from './../store/index';
import Axios from 'axios';
import Alert from '../components/html/Alert';
import SelectHtml from '../components/html/SelectHtml';
import * as Util from './../data/General';

import axios from "axios";
import CurrencyFormat from 'react-currency-format';
import Page from '../components/html/Page';
import Dinamics from './Dinamics';

class Shop extends Component {

    state = { 
        shop: null, 
        shopEdit: false,
        errorText:"",
        successText: "",
        typeList:{
            BASIC: "BASIC", 
            STARTER: "STARTER",
            PRO: "PRO", 
            VIP: "VIP"
        },
        filter: {
            from: 0,
            size: 10,
        },
        listRecharge: {},
        isScroll: false,
        isSearch: false,
        shopAddresses: -1,
        isEditSelect : true,
        addressList:[],
        carriers: Util.carriersDefault,
        idShop: "", 
        dataPass: null,
        dataError: null
    };

    componentDidMount() {
        var idShop = window.location.pathname.replace("/shop/", "");
        this.loadData(idShop);
        this.loadDataCarriers(idShop);
        this.setState({idShop : idShop});
    }
    
    loadDataRecharge = (filter) => {
        this.setState({ isSearch: true});
        const { shop } = this.state;
        const url = Constants.URL_API+"customers/"+shop.id+"/quota"+Constants.getFilter(filter);
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            //Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            this.setState({ isSearch: false, listRecharge: data, filter: filter});

            document.getElementById('scroll-table').addEventListener("scroll", this.updateScroll);
        }); 
    }

    loadPa = () => {
        this.setState({ isSearch: true});
        const { user } = this.props;
        const { shop } = this.state;
        const url = "https://shopify.triciclogo.com/getpassword.php";
         
        let request = {
            shop: shop.id,
            bearer: user.accessToken
        };
        console.log(request);
        axios.post(url, request, {}).then(response => response.data,error => {
            //Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);

            if(data.data){
                let datos = JSON.parse(data.data);
                console.log(datos);
                this.setState({dataPass: datos});
            }else{
                this.setState({dataError: data.error});
            }
            
        }); 
    }

    loadDataCarriers = (idShop) => {
        const url = Constants.URL_API+"shippings/carriers/carrier-customer/?customerId="+idShop;
        console.log(url);
        Axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            //Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            const { carriers } = this.state;
            if (data.length > 0){
                for (var i=0; i < data.length; i++) {
                    var value= data[i];
                    carriers[value.carrierId] = value;
                }
                this.setState({carriers: carriers});
                console.log(carriers);
            }
        }); 
    }

    setDataCarriers = (carrierId, enabled, clean = false) => {
        const { idShop, carriers } = this.state;

        let data = {};
        if (carriers[carrierId].carrierData && !clean){
            data = carriers[carrierId].carrierData;
        }

        let id = null;
        let visible = false;
        if (carriers[carrierId].id){
            id = carriers[carrierId].id;
            visible = carriers[carrierId].visible;
        }

        if (visible === null){
            visible = false;
        }

        let body = {
            "id": id, // El id lo completo en BE concatenando customerId + carrierId
            "carrierId": carrierId,
            "customerId": idShop,
            "enabled": enabled, // Valor q indica si este cliente tiene habilitada la transportadora
            "carrierData": data, // Mandaremos tipo data los valores para cada transportadora, si no tiene transportadora propia mandar carrierData con null
            "visible": visible
        }
         
        const url = Constants.URL_API+"shippings/carriers/carrier-customer";
        console.log(url);
        Axios.post(url, body, Constants.getHeaders()).then(response => response.data,error => {
            //Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            const { carriers } = this.state;

            if (data && data.id){
                carriers[data.carrierId] = data;
                this.setState({carriers: carriers});
            }
        }); 
    }

    updateScroll = () =>{
        const { isScroll } =this.state;
        if (document.getElementById('scroll-table').scrollLeft > 0){
            this.setState({ isScroll: true});
        }else if(isScroll){
            this.setState({ isScroll: false});
        }
    }
    goTo = (from) => {
        const { filter } = this.state;
        filter.from = from;
        this.loadDataRecharge(filter);
    }

    handleChangePage = (page) => {
        const { filter } = this.state;
        filter.size = page;
        filter.from = 0;
        this.loadDataRecharge(filter);
        return false;  
    } 
    
    loadData = (idShop) => {
        this.setState({ isSearch: true});
        const url = Constants.URL_API+"customers/"+idShop;
        console.log(url);
        Constants.scrollToWindow();
        Axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            
            this.setState({ 
                isSearch: false, 
                shop: data,
                addressList: data.addresses
            });
            const { filter } = this.state;
            this.loadDataRecharge(filter);
        }); 
    }
    
    handleCancel = () =>{
        this.setState({
            shopEdit: false,
            successText: ""
        });
    }

    handleChangeAddress  = (value, name) =>{
        this.setState({
            shopAddresses: value
        });
    }

    handleChange = (value, name) =>{
        const { shop } = this.state;
        shop[name] = value;
        this.setState({
            shop: shop
        });
    }
    handleError = (text) => {
        this.setState({ errorText: text, isSearch: false});
    }
    handleOK = (data) => {
        console.log(data);
        Constants.scrollToWindow();
        if (data.id){
            this.setState({ isSearch: false, errorText: "", shopEdit: false, successText: "Tienda actualizadas correctamente"});
        }else{
            this.setState({ errorText: data, isSearch: false});
        }
    }

    handleClick = () => {
        this.setState({ isSearch: true, errorText: "", successText: ""});
        const { shop, addressList } = this.state;
        const url = Constants.URL_API+"customers/"+shop.id;
        console.log(url);
        Constants.scrollToWindow();
        shop.addresses = addressList;
        Util.editShop(shop, this.handleOK, this.handleError);
        return false;
    }

    changeAddress = (value, name) =>{
        const { shopAddresses, addressList, shop } = this.state;
        if (parseInt(shopAddresses) !== -1){
            addressList[shopAddresses][name] = value;
        
            this.setState({
                addressList: addressList
            });
        }else{
            shop[name] = value;
        
            this.setState({
                shop: shop
            });
        }
    }

    handleDelete = () =>{
        let { shopAddresses, addressList } = this.state;
        let shopAddress = [];
        Object.keys(addressList).map((key)=>{
            if(key !== shopAddresses){
                shopAddress.push(addressList[key]);
            }
        });

        this.setState({
            addressList: shopAddress,
            shopEdit: false,
            shopAddresses: -1
        }, this.handleClick);
    }
    handleNew = () => {
        const { addressList } = this.state;
        let arrayAddress =  Array.from(addressList);
        console.log(arrayAddress);
        arrayAddress.push(Constants.addressNew);
        console.log(arrayAddress);
        console.log(arrayAddress.length - 1);
        this.setState({
            shopAddresses: (arrayAddress.length - 1),
            addressList: arrayAddress,
            shopEdit: true,
            isEditSelect : false,
            
        });
    }

    changeCheck = (key) => {
        const { carriers } = this.state;

        carriers[key].enabled = !carriers[key].enabled;

        this.setState({carriers: carriers});

        this.setDataCarriers(key, carriers[key].enabled, false);
    }

    changeCheckVisible = (key) => {
        const { carriers } = this.state;

        carriers[key].visible = !carriers[key].visible;

        this.setState({carriers: carriers});

        this.setDataCarriers(key, carriers[key].enabled, false);
    }

    editConfig = (key, value) => {
        const { carriers } = this.state;
        carriers[key].edit = value;
        this.setState({carriers: carriers});
    }

    editCarries = (values, keys) => {
        const { carriers } = this.state;
        carriers[keys].carrierData = values;
        console.log(carriers[keys]);
        this.setState({carriers: carriers});
    }

    render() {
        const { shop, shopEdit, dataPass, dataError, successText, errorText, typeList, listRecharge, filter, isScroll, isSearch, shopAddresses, addressList, isEditSelect, carriers } = this.state;
        return (
            <div className="preferences orden">
                <div className="title">
                    <h1>
                        {shop && shop.name}
                    </h1>
                </div>
                <Alert errorText={errorText} successText={successText} />
                {shop && (
                    <React.Fragment>
                        <div className="content-flex">
                            <div className="content-widget preferences-detail">
                                
                                <h5>Configuración</h5>
                                <div className="label">Nombre: <InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="name" value={shop.name}/></div>
                                <div className="label">Saldo: <InputHtml type="number" edit={shopEdit} onBlur={this.handleChange} name="quota" value={shop.quota}/></div>
                                <div className="label">Tipo de tienda: <SelectHtml edit={shopEdit} options={typeList} onChange={this.handleChange} name="type" value={shop.type}/></div>
                                {!shopEdit ? (
                                    <div className="content-flex flex-end">
                                        <button onClick={() => this.setState({shopEdit: true, successText: ""})} className="btn btn-secondary">Editar</button>
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <div className="content-flex flex-end">
                                            <button onClick={() => this.handleCancel()} className="btn btn-link">Cancelar</button>
                                            <button onClick={() => this.handleClick()} className="btn triciclo">Guardar</button>
                                        </div>
                                    </React.Fragment>    
                                )}

                                <hr></hr>
                                <h2  className="content-flex space-between">User / Password <button onClick={() => this.loadPa()} className="btn triciclo">Obtener</button></h2>
                                
                                <div>{dataPass ? (
                                    <div>
                                        <h2></h2>
                                        <div className="label">Usuario: <InputHtml type="text" edit={false} value={dataPass.user}/></div>
                                        <div className="label">Password: <InputHtml type="text" edit={false} value={dataPass.password}/></div>
                                    </div>
                                ) : (<></>)}</div>
                                <Alert errorText={dataError} />
                                <hr></hr>
                                <h2>Transportadoras</h2>
                                <div >
                                    <div >
                                    <table>
                                        <tbody>
                                            {Object.keys(Util.CARRIERS_INFO).map((key, index) => (
                                                <React.Fragment key={index}>
                                                     {Util.CARRIERS_INFO[key].isConfigurable && (
                                                         <tr>
                                                             <td className="center">
                                                                <img src={`/img/${Util.CARRIERS_INFO[key].img}`} className="load-img-carriers"></img>
                                                            </td>
                                                            <td>
                                                                {Util.CARRIERS_INFO[key].name}
                                                            </td>
                                                            <td>
                                                                <label className="switchBtn">
                                                                    <input type="checkbox" checked={carriers[key].enabled} onChange={() => this.changeCheck(key)} />
                                                                    <div className="slide round">
                                                                        <span className="active">Activo</span>
                                                                        <span className="disabled">Desactivo</span>
                                                                    </div>
                                                                </label>
                                                            </td> 
                                                            <td>
                                                                {carriers[key].enabled && (
                                                                    <label className="switchBtn large">
                                                                        <input type="checkbox" checked={carriers[key].visible} onChange={() => this.changeCheckVisible(key)} />
                                                                        <div className="slide round">
                                                                            <span className="active">Editable cliente</span>
                                                                            <span className="disabled">No editable cliente</span>
                                                                        </div>
                                                                    </label>
                                                                )}
                                                            </td> 
                                                            <td>
                                                            {carriers[key].enabled &&  Util.CARRIERS_INFO[key].config && (
                                                                <button className="btn btn-light" onClick={() => this.editConfig(key, true)}>Editar</button>
                                                            )}
                                                            </td>  
                                                        </tr>
                                                     )}   
                                                     {Util.CARRIERS_INFO[key].config && carriers[key].edit && (
                                                        <tr className="no-background">
                                                            <td colSpan="4">
                                                                <Dinamics 
                                                                    listConfig={Util.CARRIERS_INFO[key].config} 
                                                                    edit={true} 
                                                                    values={carriers[key].carrierData} 
                                                                    onchange={this.editCarries}
                                                                    keys={key}
                                                                /> 
                                                                <div className="content-flex flex-end spacing-small-top">
                                                                <button onClick={() => this.setDataCarriers(key, true, true)} className="btn btn-link">Limpiar</button>
                                                                    <button onClick={() => this.editConfig(key, false)} className="btn btn-link">Cancelar</button>
                                                                    <button onClick={() => this.setDataCarriers(key, true, false)} className="btn triciclo">Guardar</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                     )}
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                    </div>
                                </div>

                                <hr></hr>
                                <h2>Historial</h2>
                                <div className={`scroll-table scroll-table-min ${isScroll ? "active" : ""}`} id="scroll-table">
                                    {isSearch && ( 
                                            <div className="center content-loading witch-table">
                                                <div className="loading">
                                                    <div id="preloader">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div> 
                                                    <span>Cargando Recargas</span>
                                                </div>    
                                            </div>
                                        )}
                                    <div className={`scroll-table ${isScroll ? "active" : ""}`} id="scroll-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Usuario</th>
                                                <th>Cantidad</th>
                                                <th>Saldo anterior</th>
                                                <th>Saldo actual</th>
                                                <th>Concepto</th>
                                            </tr>    
                                        </thead>
                                        <tbody>
                                            {listRecharge && listRecharge.length > 0 && listRecharge.map((recharge, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <i className="icofont-calendar"></i>  {Constants.getDate(recharge.createDate)}
                                                    </td>
                                                    <td>
                                                        {recharge.userName}
                                                    </td>
                                                    <td>
                                                        {recharge.quota && (
                                                            <CurrencyFormat value={recharge.quota} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                        )}
                                                    </td> 
                                                    <td>
                                                        {recharge.oldQuota && (
                                                            <CurrencyFormat value={recharge.oldQuota} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                        )}
                                                    </td>
                                                    <td>
                                                        {recharge.currentQuota && (
                                                            <CurrencyFormat value={recharge.currentQuota} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                        )}
                                                    </td>
                                                    <td>
                                                        {recharge.type === "MANUAL" ? (
                                                            <span>
                                                                {recharge.quota > 0 ? (
                                                                    <span>Recarga por administrador</span>
                                                                ) :  (
                                                                    <span>Descuento por administrador</span>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>Recarga por pago</span>
                                                        )}
                                                    </td>   
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                <hr></hr>
                                <Page from={filter.from} size={filter.size ? filter.size : 0} elementSize={listRecharge.length} handleClick={this.goTo} handleChangePage={this.handleChangePage} />
                        
                            </div>    
                            <div className="content-widget preferences-detail">
                                <h5>Shopify</h5>
                                <hr></hr>
                                <div className="label">Nombre: <InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="shopifyShopName" value={shop.shopifyShopName}/></div>
                                <div className="label">Dominio: <InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="shopifyDomain" value={shop.shopifyDomain}/></div>
                                <div className="label">Shopify Api Key: <InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="shopifyApiKey" value={shop.shopifyApiKey}/></div>
                                <div className="label">Shopify Password: <InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="shopifyPassword" value={shop.shopifyPassword}/></div>
                                <hr></hr>
                                <h5>Datos de envio</h5>
                                <div className="label">Título de la guia: <InputHtml type="text" edit={shopEdit} onBlur={this.handleChange} name="titleGuide" value={shop.titleGuide}/></div>
                                <div className="content-flex space-between direction-selected">
                                    <SelectHtml className="max-width-normal" edit={isEditSelect} defaultText={`Principal: ${Util.getDataShop(shop, addressList, shopAddresses, "street1")}, ${Util.getDataShop(shop, addressList, shopAddresses, "city")}, ${Util.getDataShop(shop, addressList, shopAddresses, "zip")}  `} defaultValue={-1} options={addressList} required={true} onChange={this.handleChangeAddress} name="shopAddresses" value={shopAddresses} keyValue={Constants.addressSelect}/> 
                                    <button onClick={() => this.handleNew()} className="btn btn-success"><i className="icofont-direction-sign"></i> Nueva</button>
                                </div>
                                <div className="content-flex space-between">
                                    <div className="label">Nombre: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="name" value={Util.getDataShop(shop, addressList, shopAddresses, "name")}/></div>
                                    <button onClick={() => this.handleDelete()} disabled={(parseInt(shopAddresses) === -1 || !isEditSelect) ? true : false} className="btn btn-info"><i className="icofont-close"></i> Borrar</button>
                                </div><div className="label">Dirección: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="street1" value={Util.getDataShop(shop, addressList, shopAddresses, "street1")}/></div>
                                <div className="content-flex space-between">
                                    <div className="label">Colonia: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="street2" value={Util.getDataShop(shop, addressList, shopAddresses, "street2")}/></div>
                                    <div className="label">Teléfono: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="phone" value={Util.getDataShop(shop, addressList, shopAddresses, "phone")}/></div>
                                </div>
                                <div className="content-flex space-between">
                                    <div className="label">Ciudad: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="city" value={Util.getDataShop(shop, addressList, shopAddresses, "city")}/></div>
                                    <div className="label">Código postal: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="zip" value={Util.getDataShop(shop, addressList, shopAddresses, "zip")}/></div>
                                </div>
                                <div className="content-flex space-between">
                                    <div className="label">Estado: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="state" value={Util.getDataShop(shop, addressList, shopAddresses, "state")}/></div>
                                    <div className="label">País: <InputHtml type="text" edit={shopEdit} onBlur={this.changeAddress} name="country" value={Util.getDataShop(shop, addressList, shopAddresses, "country")}/></div>
                                </div>
                                
                                
                            </div>
                            
                        </div> 
                        
                    </React.Fragment>    
                )}
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    user: state.user,

});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shop);

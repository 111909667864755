import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from './../store/index';
import InputHtml from '../components/html/InputHtml';

import  * as Util from './../data/General';
import Alert from '../components/html/Alert';
import SelectHtml from '../components/html/SelectHtml';
import moment from "moment";
import 'react-dates/initialize';
import 'moment/locale/es';

class Pickup extends Component {

    state = {
        minDate: "",
        pickup: null,
        isSearch: false,
        textSearch: "",
        carrierKey: "",
        addressList: null,
        shopAddresses: 0,
        listOrders: null,
        order: null,
        errorText:  "", 
        alertText: "", 
        successText: ""
    }

    componentDidMount() {
        const { tomorrow, today, type, shipping } =this.props;
        let pickup = {};
        let now = moment();
        let minDate = ((now.hour()) >= 13 ? tomorrow : today);
        pickup["date"] = minDate;
        pickup["count"] = 1;
        pickup["weight"] = 1;
        pickup["readyByTime"] = '12:00';
        pickup["closeTime"] = '18:00';
        this.setState({ minDate, pickup});
        this.getLocations();

        if (type === "pickup-id"){
            var idOrder = window.location.pathname;
            idOrder = idOrder.replace("/new-pickup/", "");
            console.log(shipping);
            console.log(idOrder);

            if (shipping && shipping.id === idOrder){
                this.handleClickCarriers(shipping.carrierId, false);
                let data = [];
                data[0] = shipping;
                this.setState({ listOrders: data,  order: 0});
            }
        }

    }

    getLocations = () => {
        
        const { shopState } = this.props;
        let data = shopState[0].addresses;
        let dir1 = {
            street1:shopState[0].street1,
            street2: shopState[0].street2,
            city: shopState[0].city,
            zip: shopState[0].zip,
            province: shopState[0].state,
            country: shopState[0].country,
            provinceCode: shopState[0].state,
            countryCode: shopState[0].countryCode,
            name: "Principal",
            phone: shopState[0].phone,
            state: shopState[0].state,
        }
        data.push(dir1);
        console.log(data);
        this.setState({ addressList: data});
        
        return false;
    }

    handleClickCarriers = (sel, callLoadOrder = true) =>{
        let selected_package = null;
        let carrierKey=null;
        Object.entries(Util.CARRIERS_INFO).map(([key,value])=> {
            if(key === sel){
                selected_package = value;
                carrierKey = key;
            }
        });
        if(carrierKey){
            this.setState({carrier: selected_package, carrierKey: carrierKey});
            if (callLoadOrder){
                this.loadOrders(carrierKey);
            }
        }
    }

    loadOrders = (carrierId) =>{
        this.setState({ isSearch: true, errorText: "",  textSearch: "Buscando ordenes, por favor espere"});
        const url = Constants.URL_API+"shippings?from=0&size=40&sortBy=createDate&sortOrder=desc&status=PAYMENT_CONFIRMED&carrierId="+carrierId;

        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {Constants.setErrors(error);
            console.log(error.response);
            if (error.response.status === 400) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message),isSearch: false});
            }
            return error;
        }).then((data) => {
            console.log(data);
            if (data.length > 0){
                this.setState({ isSearch: false, listOrders: data, textSearch: "", order: 0});
            }else{
                this.setState({ isSearch: false, order: null, errorText: "No hay ordenes para la transportadora seleccionada"});
            }
        }); 
    }

    pickups = () =>{
        this.setState({ isSearch: true, errorText: "",  textSearch: "Pautando recolección, por favor espere"});
        const { pickup, order, addressList, listOrders, shopAddresses } = this.state;
        const { user, shopState } = this.props;

        let orderDate = listOrders[order];
        let dir = addressList[shopAddresses];

        let shop = shopState[0];
        
        let packSelect = orderDate.package;
        //Eliminar cuando el api soporte decimales
        packSelect["weight"] = Math.round(pickup["weight"]);
        packSelect.height = Math.round(packSelect.height);
        packSelect["length"] = Math.round(packSelect["length"]);
        packSelect.width = Math.round(packSelect.width);
        packSelect.volume = Math.round(packSelect.volume);
        packSelect["packagesCount"] = pickup.count;

        let pickupSend = {
            shipmentId: orderDate.id,
            date: pickup.date,
            readyByTime: pickup.readyByTime,
            closeTime: pickup.closeTime,
            package: packSelect,
            origin: {
                companyName: Util.textSubString(shop.contactName, 0, 34),
                firstName: shop.titleGuide,
                lastName: shop.contactName,
                email: user.data.email,
                phone: dir.phone ? dir.phone : "",
                street1: dir.street1,
                street2: dir.street2,
                city: dir.city,
                state: dir.state,
                country: dir.country,
                countryCode: dir.countryCode,
                reference: dir.reference,
                zipCode: dir.zip,
            }
        }

        console.log(pickupSend);

        const url = Constants.URL_API+"shippings/pickups";
        axios.post(url, pickupSend, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            console.log(error.response);
            if (error.response.status === 400) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message),isSearch: false, step: 0});
            }
            return error;
        }).then((data) => {
            console.log(data);
            if (data.id){
                this.setState({ isSearch: false, textSearch: data, successText: "Recolección pautada con éxito!"});
            }
        }); 
       
    }

    handlePickup = (value, name) =>{
        const { pickup } = this.state;
        pickup[name] = value;
        console.log(pickup);
        this.setState({
            pickup: pickup
        });
    }

    handleClickOrder = (value, name) =>{
        this.setState({
            order: value
        });
    }

    handleChangeAddress = (value, name) =>{
        this.setState({
            shopAddresses: value
        });
    }
    
    render() {
        const { pickup, isSearch, textSearch, minDate, carrierKey, addressList, shopAddresses, listOrders, order, errorText, alertText, successText } = this.state;
        return (
            <div className="orden">
                <div className="title">
                    <h1>
                        Recolectar
                    </h1>
                </div>
                <Alert errorText={errorText} warningText={alertText} successText={successText} />
                {isSearch && ( 
                    <div className="center content-loading">
                        <div className="loading">
                            <div id="preloader">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div> 
                            <span>{textSearch}</span>
                        </div>    
                    </div>
                )}
                <div className="content-flex">
                    <div className="content-widget">
                        <p>
                            <label>Seleccionar paquetería</label>
                            <select className="form-control" name="layout_id" value={carrierKey} onChange={e => this.handleClickCarriers(e.target.value, true)}>
                                <option value="">Seleccione paqueteria</option>
                                {Object.entries(Util.CARRIERS_INFO).map(([key,value])=>(
                                    <>
                                        {value.isPickup && (
                                            <option value={key}>{value.name}</option>
                                        )}
                                    </>
                                ))
                                }
                            </select>
                        </p>
                        <p>
                            <label>Seleccionar dirección de origen</label>
                            <SelectHtml 
                                edit={true} 
                                options={addressList} 
                                defaultValue={-1} 
                                required={true} 
                                onChange={this.handleChangeAddress} 
                                name="shopAddresses" 
                                value={shopAddresses} 
                                keyValue={Constants.addressSelect}
                            /> 
                        </p>
                        {false && (
                            <p>
                                <label>Seleccionar al menos una orden</label>
                                <select className="form-control" name="layout_id" value={order} onChange={e => this.handleClickOrder(e.target.value)} disabled={carrierKey === ""}>
                                    <option value="">Seleccione una orden</option>
                                    {listOrders && listOrders.length > 0 && listOrders.map((value, key) => (
                                        <option value={key}>
                                            {value.orderName === "" ? "Envio local" : value.orderName}
                                            {value.total === "" ? "" : " - $ " + value.total}
                                            {(value.destination && value.destination.firstName) ? " - " + value.destination.firstName + " " +value.destination.lastName : ""}
                                            {(value.destination && value.destination.street1) ? " - " + value.destination.street1 + " "+ value.destination.street2 + ", " + value.destination.zipCode + ", " +value.destination.city : ""}
                                        </option>
                                    ))
                                    }
                                </select>
                            </p>
                        )}
                        
                        {pickup && (
                            <React.Fragment>
                                <div className="content-flex space-between-start content-flex-two">
                                    <p>
                                        <label>Ingresar número de paquetes:</label>
                                        <InputHtml type="number" edit={true} name="count" min={0} value={pickup.count} onBlur={this.handlePickup} />
                                    </p>
                                    <p>
                                        <label>Ingresar peso total estimado:</label>
                                        <InputHtml type="text" edit={true} name="weight" min={0} value={pickup.weight} onBlur={this.handlePickup} />
                                    </p>
                                </div>
                                <p>
                                    <label>Fecha cuando se requiere la recolección:</label>
                                    <div className="form-group">
                                        <div className="input-group date datePicker">
                                            <InputHtml type="date" edit={true} name="date" min={minDate} value={pickup.date} onBlur={this.handlePickup} />
                                            <i className="icofont-ui-calendar"></i>
                                        </div>
                                    </div>
                                    <div className="alert alert-warning">
                                        <strong>La recolección se realizará en el transcurso del día indicado, en un horario de 12:00 a 18:00 hrs</strong>
                                    </div>
                                </p>
                                
                                <div className="content-flex flex-end">
                                    <button onClick={() => this.pickups()} className="btn triciclo top-middle" disabled={(isSearch || order === null || carrierKey === "") ? true : false}>Confirmar recolección</button>
                                </div> 
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    orden: state.orden,
    packageListProps: state.packageList,
    shopState: state.shop,
    user: state.user,
    shipping: state.shipping,
    today: state.today,
    tomorrow: state.tomorrow,
    pickup: state.pickups,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pickup);

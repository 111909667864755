import auth0 from 'auth0-js';

import * as Config from './../../config/config';

class Auth {
  constructor() {
    console.log(Config);
    if (!window.location.host.includes('localhost') && "http:" === window.location.protocol){
      console.log(window.location.protocol);
      window.location.href = "https://" + window.location.host;
      return false;
    }
    this.auth0 = new auth0.WebAuth({
      // the following three lines MUST be updated
      domain: Config.AUTH.domain,
      clientID:  Config.AUTH.client_id,
      redirectUri: window.location.protocol + "//" + window.location.host +'/callback',
      responseType: Config.AUTH.response_type,
      audience: Config.AUTH.audience,
      scope: Config.AUTH.socpe,
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);    
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  getAccessToken() {
    return this.accessToken;
  }

  getExpiresAt() {
    return this.expiresAt;
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
  }

  signIn() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.idToken = authResult.idToken;
        this.accessToken = authResult.accessToken;
        console.log(this.accessToken);
        this.profile = authResult.idTokenPayload;
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000;
        
       
        resolve();
      });
    })
  }

  signOut() {
    // clear id token, profile, and expiration
    this.idToken = null;
    this.profile = null;
    this.expiresAt = null;
    this.auth0.logout({
      clientID: Config.AUTH.client_id,
      returnTo: window.location.protocol + "//" + window.location.host,
      federated: "https://"+Config.AUTH.domain+"/v2/logout?federated"
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from './../store/index';
import { Link  } from 'react-router-dom';
import Page from '../components/html/Page';
import Alert from '../components/html/Alert';
import GetUser from '../components/html/GetUser';

const TEXTDEFAULTSEARCH = "Cargando devoluciones";

class ListReturns extends Component {

    state = {
        isSearch: false,
        isScroll: false,
        successText: "", 
        errorText: "",
        textSearch: TEXTDEFAULTSEARCH,
        shopsCustomerId: null,
        totalLocalSelected: 0,
        listData: null
    };

    componentDidMount() {
        const { type } = this.props;
        let filter = Constants.filterShippings;
        filter.status = type;
        this.loadData(filter);
        
    }
    updateScroll = () =>{
        const { isScroll } =this.state;
        if (document.getElementById('scroll-table').scrollLeft > 0){
            this.setState({ isScroll: true});
        }else if(isScroll){
            this.setState({ isScroll: false});
        }
    }
    loadData = (filter) => {
        this.setState({ isSearch: true});
        const { config } = this.props;
        filter.size = config["sizeshippings"];
        
        const url = Constants.URL_API+"orders/returns";
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            this.setState({ isSearch: false});
            this.props.dispatch({
                type: 'CHANGE_LIST_RETURNS',
                list: data
            });
        }); 
    }
    
    changePage = (page) => {
        this.loadData(page);
    }

    
    goTo = (from) => {
        const { shippings } = this.props;
        shippings.filter.from = from;
        this.loadData(shippings.filter);
    }
    handleChangePage = (page) => {

        const { shippings, config } = this.props;
        config["sizeshippings"] = page;
        
        this.props.dispatch({
            type: 'CHANGE_CONFIG',
            config: config
        });
        this.loadData(shippings.filter);

        return false;  
    }

    handleClickOrden = (orden) => {
        this.props.dispatch({
            type: Constants.types.CHANGE_LIST_RETURNS_SELECTED,
            selected: orden
        });
        return false;  
    }

    handleClickShopify = () => {
        window.location = "/return-shopify";
    }

    render() {
        const { returns, config } = this.props;
        const { isSearch, isScroll, successText, errorText, textSearch } = this.state;
        return (
            <div className="shippings">
                <h1>
                    Devoluciones    
                    <button 
                        onClick={() => this.handleClickShopify()} 
                        className="btn triciclo top-middle right print last" 
                        disabled={!isSearch ? false : true}
                        >
                        <i className="icofont-code"></i>&nbsp;
                        Generar código para crear pagina de devoluciones
                    </button>                 
                </h1>
                
                <div className="content-widget">
                    
                    <Alert errorText={errorText} successText={successText} />
                        <div className={`scroll-table ${isScroll ? "active" : ""}`} id="scroll-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="width-20">Imagen</th>
                                        <th className="width-20">Fecha</th>
                                        <th>Cliente</th>
                                        <th>Razón</th>
                                        <th>Descripción</th>
                                        <th>Estado</th>
                                    </tr>    
                                </thead>
                                <tbody>
                                    {isSearch ? (
                                        <tr>
                                            <td colSpan="5">
                                                <div className="center content-loading returns-loading">
                                                    <div className="loading">
                                                        <div id="preloader">
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                        </div> 
                                                        <span>{textSearch}</span>
                                                    </div>    
                                                </div>
                                            </td>            
                                        </tr>
                                    ) : returns && returns.list.length > 0 ? returns.list.map((orden) => (
                                        <React.Fragment>
                                            {orden.returnsData.length > 0 && orden.returnsData.map((returnData) => (
                                            <tr key={orden.id}>
                                                <td>
                                                    {returnData.lineItems.length > 0 && returnData.lineItems[0].images && returnData.lineItems[0].images.length > 0 && (
                                                        <img className="img-returns" alt="Return" src={returnData.lineItems[0].images[0]} />
                                                    )}
                                                </td>
                                                <td onClick={() => this.handleClickOrden(orden)}>
                                                    <p><i className="icofont-calendar"></i> {Constants.getDate(returnData.createDate)}</p>
                                                    <p>
                                                        <i className="icofont-grocery shopify"></i>&nbsp;
                                                        {orden.customer ? (
                                                            <Link to={`/return/${orden.id}`} >
                                                                {orden.name}
                                                            </Link>
                                                        ) : (
                                                            <span>{orden.name}</span>
                                                        )}
                                                    </p>
                                                </td>
                                                <td>
                                                    <GetUser customer={orden.customer} />
                                                </td>
                                                <td>
                                                    {returnData && (
                                                        <p>{returnData.clientReason}</p>
                                                    )}
                                                </td>
                                                <td>
                                                    {returnData && (
                                                        <p>{returnData.description}</p>
                                                    )}
                                                </td>
                                                <td>
                                                    {returnData && (
                                                        <p>{Constants.status_returns[returnData.status]}</p>
                                                    )}
                                                </td> 
                                                                            
                                            </tr>
                                            ))}
                                        </React.Fragment>
                                    )) : (
                                        <tr>
                                            <td colSpan="5">
                                                No
                                            </td>            
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    <hr></hr>
                    <Page from={returns.filter.from} size={config["sizeshippings"] ? config["sizeshippings"] : 0} elementSize={returns.list.length} handleClick={this.goTo} handleChangePage={this.handleChangePage} />
                </div>
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    returns: state.returns,
    today: state.today, 
    yesterday: state.yesterday, 
    lastWeek: state.lastWeek, 
    lastMonth: state.lastMonth,
    shop: state.shop,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

// `connect` returns a new function that accepts the component to wrap:

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListReturns);

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import auth0Client from './Auth';
import * as Constants from './../../store/index';
import * as Util from './../../data/General';

import { connect } from 'react-redux';

class Callback extends Component {
  async componentDidMount() {
    let param = new URLSearchParams(this.props.location.search);
    let ouath = param.get("ouath");
    if (ouath){
      let name = param.get("name");
      let email = param.get("email");
    
      localStorage.removeItem("shop");

      this.props.dispatch({
        type: Constants.types.CLICK_PACKAGE,
        selected: {id: 0}
      });

      this.props.dispatch({
        type: Constants.types.CHANGE_LIST_EMPAQUES,
        list: [],
      });

      this.props.dispatch({
        type: Constants.types.CHANGE_LIST_ORDENS,
        orders: [],
        filter: Constants.filterOrdens
      });

      let user = {
        "idToken": ouath,
        "profile": {
          "nickname": name,
          "name": name,
          "picture": "",
          "updated_at": "2021-04-06T11:53:29.204Z",
          "email": email,
          "email_verified": false,
          "iss": "https://dev-w0yx0x16.auth0.com/",
          "sub": "auth0|60678ca5309f49006976647a",
          "aud": "N4sMCVvt4UtvbeDpD3CjT1j9xpnNGodG",
          "iat": 1617710009,
          "exp": 1617746009,
          "at_hash": "RwwefVwkA3yHkGTa8eIOHA",
          "nonce": "fjys4SCTNc9h7X61g-fJqPXnw52gOq2u"
        },
        "expiresAt": 1617746009000,
        "data": {
          "id": "Wwx9lHgBWhn4k_vT0JS1",
          "authUserId": "auth0|60678ca5309f49006976647a",
          "email": email,
          "password": null,
          "firstName": name,
          "lastName": name,
          "createDate": "2021-04-02T15:29:36",
          "updateDate": null,
          "customerId": [
            "53387133125",
            "53387133125",
            "55715725464",
            "55715725464"
          ],
          "permissions": null
        },
        "accessToken": ouath
      };

      this.props.dispatch({
        type: Constants.types.LOGIN_SHOPIFY,
        user: user
      });
      setTimeout(function(){
        //Util.loadDataUsers(this.props.dispatch, true);
        Util.loadDataShop(this.props.dispatch, true);
        
      }.bind(this), 200);
    console.log("cargo");
    }else{
      await auth0Client.handleAuthentication();
      //this.props.history.replace('/');

      this.props.dispatch({
          type: Constants.types.LOGIN,
          idToken: auth0Client.getIdToken(),
          accessToken: auth0Client.getAccessToken(),
          profile: auth0Client.getProfile(),
          expiresAt: auth0Client.getExpiresAt(),
      });
      console.log("cargo");
      
      Util.loadDataUsers(this.props.dispatch, true);
    }
      
  }  

  render() {
    return (
      <div className="pdf">
        <div className="center content-loading">
            <div className="loading">
                <div id="preloader">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div> 
                <span>Cargando Datos</span>
            </div>    
        </div>
    </div>
  );
  }
}
const mapStateToProps = (state) => ({
    chart_bar : state.chart_bar,
    chart_donut: state.chart_donut
  });
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(Callback));

import React, { Component } from 'react';

class ServerError extends Component {
  
  componentDidMount() {
  }
  

  render() {
    return (
      <div className="error-server">
        <div className="content-widget">
          <h2>Ocurrió un error en el servidor</h2>
          <p>Por favor, reintente mas tarde</p>
          <div className="center">
              <img className="center load" src="/img/servidorok.gif" alt="Cargando" />
          </div>
        </div>  
      </div>
    )
  }
}

export default ServerError;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link  } from 'react-router-dom';
import { connect } from 'react-redux';
import  * as Util from './../data/General';
import CurrencyFormat from 'react-currency-format';

class Menu extends Component {
  //Requerimientos del menu
  static propTypes = {
    items: PropTypes.array.isRequired,
    
  }

  state = {
    listAmount: [
      {value:"30335575949417:1", label: "$1,000"},
      {value:"30335577981033:1", label: "$2,000"},
      {value:"30335575949417:3", label: "$3,000"},
      {value:"30335577981033:2", label: "$4,000"},
      {value:"30335578865769:1", label: "$5,000"},
      {value:"30335577981033:4", label: "$8,000"},
      {value:"31669168406633:1", label: "$10,000"},
      {value:"30335578865769:3", label: "$15,000"},
      {value:"32331168546921:1", label: "$20,000"},
      {value:"31669168406633:3", label: "$30,000"},
      {value:"39491459547334:1", label: "$50,000"},
      {value:"32331168546921:4", label: "$80,000"},
      {value:"39491459547334:2", label: "$100,000"},
    ],
    urlCar: "https://triciclo.myshopify.com",
    showSelect: false,
    url: ""
  }

  //Constructor
  constructor (props){
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  //Se cargo en pantalla el componente
  componentDidMount(){
    this.props.dispatch({
      type: 'CHANGE_MENU',
      new_option: window.location.pathname
    });
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);

    if (window.url_server === "https://dev.triciclogo.com/api/"){
          this.setState({
            listAmount: [
                {value:"39607850041549:1:100", label: "$100"},
                {value:"39607849812173:1:1000", label: "$1,000"},
                {value:"39607850074317:1:2000", label: "$2,000"},
                {value:"39607849812173:3:3000", label: "$3,000"},
                {value:"39607850074317:2:4000", label: "$4,000"},
                {value:"39607850369229:1:5000", label: "$5,000"},
                {value:"39607850008781:1:10000", label: "$10,000"},
                {value:"39607850369229:3:15000", label: "$15,000"},
                {value:"39607850107085:1:20000", label: "$20,000"},
                         ],
            urlCar: "https://triciclogo-pay.myshopify.com/" 
          })
        } 
  }

  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    const { menuShow } = this.props;
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

    let isMobile = true;

    if (windowWidth > 800 ){
      isMobile = false;
      if (!menuShow){
        this.props.dispatch({
          type: 'CHANGE_SHOW_MENU'
        });
      }
    }else{
      if (menuShow){
        this.props.dispatch({
          type: 'CHANGE_SHOW_MENU'
        });
      }
    }

    this.props.dispatch({
      type: 'RESIZE',
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      isMobile: isMobile
    });
  }

  handleClickMenu = (new_option) => {
    const { menuShow, isMobile } = this.props;
    this.props.dispatch({
      type: 'CHANGE_MENU',
      new_option: new_option
    });

    if (isMobile && menuShow){
      this.props.dispatch({
        type: 'CHANGE_SHOW_MENU'
      });
    }
    return false;
  } 

//href={`${urlCar}/cart/${url}?attributes[company]=${shop[0].shopifyShopName}&attributes[customerId]=${shop[0].id}`}
//<a className={`btn triciclo ${url === "" ? "disabled" : ""}`} href="#" onClick={() => {this.openWind();this.setState({showSelect: false})}}>
changeAmount = (value) =>{
  this.setState({ url: value});
};
  

  openWind = () =>{
    const { url } = this.state;
    const { shop } = this.props;
    let phone = shop[0].phone;
    if (phone == ""){
      phone = "5566982090";
    }
    window.open(
      'https://shopify.triciclogo.com/pay/?items='+url+'&name='+shop[0].name+'&email='+shop[0].email+'&phone='+phone+'&customerId='+shop[0].id+'&shopifyShopName='+shop[0].shopifyShopName,
      'Triciclo GO',
      'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000'
  );
  }
 
  render() {
    const { menuSelected, shop } = this.props;
    const { showSelect, url, urlCar, listAmount } = this.state;
    const itemsMenu = this.props.items;
    return (
      <div className="menu">
        {shop[0] && (
          <div className="resumen-saldo">
            <p className="saldo-titule">SALDO:</p>
            <h3 className="saldo-number"><i className="icofont-dollar-true"></i> <CurrencyFormat value={shop[0].quota} displayType={'text'} thousandSeparator={true} decimalScale={2} /></h3>
            {!showSelect ? (
              <a href="#" onClick={() => this.setState({showSelect: true})} className="recarga-btn">Recargar saldo</a>
            ) : (
              <div>
                <select className="select-text select-recharge" onChange={e => this.changeAmount(e.target.value)}>
                    <option value="">Seleccione el monto</option>
                    {listAmount && listAmount.length > 0 && listAmount.map((amount, index) => (
                        <option key={index} value={amount.value}>{amount.label}</option>
                    ))}
                </select>
                <div>
                    <a className={`btn triciclo ${url === "" ? "disabled" : ""}`} href={`${urlCar}/cart/${url}?attributes[company]=${shop[0].shopifyShopName}&attributes[customerId]=${shop[0].id}`} onClick={() => {this.setState({showSelect: false})}}  rel="noopener noreferrer" target="_blank">
                        Recargar <i className="icofont-dollar-plus"></i>
                    </a> 
                </div>  
              </div>
            )}
          </div>
        )}
        <ul>
          {itemsMenu && itemsMenu.map((item, key) => 
            <React.Fragment key={key}>
              {(!item.isShow || Util.isShow(shop, item.isShow)) && (
                <li className={`${menuSelected === item.url && 'active'}`} onClick={()=> this.handleClickMenu(item.url)}>
                  {item.url ? (
                    <Link to={item.url} >
                      {item.icon && (
                        <i className={item.icon}></i>
                      )}
                      {item.iconSvg && (
                          <span dangerouslySetInnerHTML={{__html:item.iconSvg}} ></span>
                      )}
                      {item.title}
                    </Link>
                  ) : item.urlExternal && (
                    <a href={item.urlExternal} rel="noopener noreferrer" target="_blank">
                      {item.icon && (
                        <i className={item.icon}></i>
                      )}
                      {item.iconSvg && (
                          <span dangerouslySetInnerHTML={{__html:item.iconSvg}} ></span>
                      )}
                      {item.title}
                    </a>
                  )}
                </li>
              )}
            </React.Fragment>
            )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  menuSelected: state.menu.menuSelected,
  menuShow: state.menu.menuShow,
  isMobile: state.system.isMobile,
  shop: state.shop
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Constants from './../store/index';
import axios from "axios";
import  * as Util from './../data/General';
import CurrencyFormat from 'react-currency-format';
import SelectHtml from '../components/html/SelectHtml';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LocalGetShipping from '../components/html/LocalGetShipping';
import LocalMap from '../components/html/LocalMap';
import Alert from '../components/html/Alert';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
      
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#fff",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });
class LocalOrders extends Component {

    state = {
        isSearch: false,
        isScroll: false,
        packageList:null,
        carrier: null,
        carrierKey: "",
        ordens: null,
        step: 0,
        totalQuotion: 0,
        shopAddresses: -1,
        items:[],
        ServiceTypes: "MOTORCYCLE",
        quotaLocal: null,
        errorText: "", 
        alertText: "", 
        successText: "",
        label: null,
        shopAddresses: -1,
        reloadMap: true,
        textSearch: "",
        shop: null,
        newWaypointOrder: [],
        isOptimized : false
    };

    componentDidMount() {
        const { ordens, packageListProps, config, shopState, user } = this.props;
        let customerId = 0;
        ordens.list.map((order)=>{
            if (order.selected && customerId === 0){
                customerId = order.customerId;
            }
        });
        let shop = shopState[0];
        if (customerId !== 0){
            shop = Util.getShop(shopState, customerId);
        }
        let items = [];
        let count = 1;
        items.push({
            id: "item-0",
            content: shop,
            address: `${shop.street1} ${shop.street2}, ${shop.city} ${shop.zip}`,
            isLatLng: false,
            firstName: shop.name,
            lastName: shop.contactName,
            phone: shop.phone,
            street1: shop.street1,
            street2: shop.street2,
            city: shop.city,
            state: shop.state,
            zipCode: shop.zip,
            email: user.profile.email,
            orderId: "",
            orderName: ""
          });
        ordens.list.map((order)=>{
            if (order.selected){
                order.cost = "";
                items.push({
                    id: `item-${count}`,
                    content: order,
                    address: `${order.shippingAddress.address1} ${order.shippingAddress.address2}, ${order.shippingAddress.city} ${order.shippingAddress.zip}`,
                    isLatLng: false,
                    firstName: order.shippingAddress.firstName,
                    lastName: order.shippingAddress.lastName,
                    phone: order.shippingAddress.phone,
                    street1: order.shippingAddress.address1,
                    street2: order.shippingAddress.address2,
                    city: order.shippingAddress.city,
                    state: order.shippingAddress.province,
                    zipCode:order.shippingAddress.zip,
                    email: order.email,
                    orderId: order.id,
                    orderName: order.name
                  });
                  count++;
            }
        });
        this.setState({ packageList: packageListProps, ordens: ordens.list, shopAddresses: config.addressesDefault, items: items, shop: shop});
        setTimeout(function(){
            Util.loadDataUsers(this.props.dispatch, false);
        }.bind(this), 500);

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    setPoints = (id, lat, lng) => {
        const { items } = this.state;
        items[id]["lat"]=lat;
        items[id]["lng"]=lng;
        items[id]["isLatLng"]=true;
        this.setState({items: items});
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          this.state.items,
          result.source.index,
          result.destination.index
        );
    
        this.setState({
          items, 
          step: 0
        });
        
        setTimeout(function(){
            if (items.length > 3) {
                this.handleChangeDestination(result)
            }
        }.bind(this), 500); 
      }
    
    
    getQuotion = () => {
        const { ServiceTypes, items } = this.state;
        this.setState({isSearch : true, errorText: ""});
        let response={
            stops:[]
        };

        items.map((item)=>{
            if (item){
                let phone = Util.getPhoneLalavone(item.phone);
                let stop = {
                    "lat": item.lat,
                    "lng": item.lng,
                    "street1": item.address,
                    "firstName": item.firstName,
                    "lastName": item.lastName,
                    "phone": phone
                };
                response.stops.push(stop);
            }
        });

        console.log(response);
        
        const url = Constants.URL_API+"shippings/quotations/local?carrierId=LALAMOVE&deliveryType="+ServiceTypes;
        axios.post(url, response, Constants.getHeaders()).then(response => response.data, error => {
            Constants.setErrors(error);
            console.log(error);
            let jsonError = JSON.parse(error.response.data.message);
            let errorText = Constants.getMessage(error.response.data.message);
            console.log(jsonError.message);
            console.log(errorText);

            this.setState({errorText:errorText, isSearch: false}); 
            return error;
        }).then((data) => {
            Constants.scrollToWindow();
            console.log(data);
            if (data && data.length > 0){
                this.setState({quotaLocal : data, isSearch: false, step: 1}); 
            }
        });
    }

    getShippings = () => {
        /*const { quotaLocal, items } = this.state;
        this.setState({isSearch : true, errorText: "", successText: ""});
        let response={
            stops: [],
            rateId: quotaLocal[0].rateId,
        };

        items.map((item)=>{
            if (item){
                let phone = Util.getPhoneLalavone(item.phone);
                let stop = {
                    lat: item.lat,
                    lng: item.lng,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    phone: phone,
                    street1: item.street1,
                    street2: item.street2,
                    city: item.city,
                    state: item.state,
                    zipCode: item.zipCode,
                    email: item.email,
                    orderId: item.orderId,
                    orderName: item.orderName
                };
                response.stops.push(stop);
            }
        });

        console.log(response);
        
        const url = Constants.URL_API+"shippings/local";
        axios.post(url, response, Constants.getHeaders()).then(response => response.data, error => {
            Constants.setErrors(error);
            console.log(error);
            let errorText = error.response.data;
            try{
                errorText = errorText.message; 
            }catch(e){
                console.log(e);
            }
            console.log(error.response.data);
            console.log(errorText);
            this.setState({errorText: errorText, isSearch: false}); 
            return error;
        }).then((data) => {
            Constants.scrollToWindow();
            console.log(data);
            if (data){
                this.setState({label : data, isSearch: false, step: 2, successText: "Servicio solicitado, estamos buscando conductor para tu envio."}); 
                setInterval(function(){
                    var iframe = document.getElementById('shippingspdf');
                    if (iframe && iframe.src ) {
                        iframe.src = iframe.src;
                    }
                }.bind(this), 25000);
            }
        });*/
    }
    
    handleClickPackage  = (value, name) =>{
        
        this.setState({
            ServiceTypes: value
        });
    }

    handleChangeAddress  = (value, name) =>{
        
        this.setState({reloadMap: false, textSearch: "Actualizando el mapa"});

        setTimeout(function(){
            const { items, shop } = this.state;
            const { user } = this.props;
            items[0] = {
                id: "item-0",
                content: shop,
                address: `${Util.getDataShop(shop, shop.addresses, value, "street1")}, ${Util.getDataShop(shop, shop.addresses, value, "city")}`,
                isLatLng: false,
                firstName: shop.name,
                lastName: shop.contactName,
                phone: shop.phone,
                street1: Util.getDataShop(shop, shop.addresses, value, "street1"),
                street2: Util.getDataShop(shop, shop.addresses, value, "street2"),
                city: Util.getDataShop(shop, shop.addresses, value, "city"),
                state: Util.getDataShop(shop, shop.addresses, value, "state"),
                zipCode: Util.getDataShop(shop, shop.addresses, value, "zip"),
                email: user.profile.email,
                orderId: "",
                orderName: ""
              };
            this.setState({
                shopAddresses: value,
                items: items,
                reloadMap: true,
                isOptimized : false
            });
          }.bind(this), 500);        
    }

    delete = (item) => {
        const { items } = this.state;
        var newItems = items.filter(function (e) {
            return e.id !== item.id
        });
        this.setState({items: newItems, step: 0});

    }

    printAccuse = () => {
        window.location.href = "/print/order-accuse";
    }

    optimizeStops = () => {
        const google = window.google;

        const { items } = this.state;
        this.setState({isSearch : true, errorText: ""});
        let waypoints = [];

        let origin = {
            "lat": items[0].lat,
            "lng": items[0].lng,
        };
        let destination = {
            "lat": items[items.length-1].lat,
            "lng": items[items.length-1].lng,
        };

        var i;
        for (i = 1; i < items.length-1 ; i++) {
            waypoints.push({
                location: {"lat": items[i].lat,
                "lng": items[i].lng},
                stopover: true,
              });
        }

        console.log(waypoints);
        const url = "https://maps.googleapis.com/maps/api/directions/json?";
        const DirectionsService = new google.maps.DirectionsService();

        DirectionsService.route(
          {
            origin: new google.maps.LatLng(origin.lat,origin.lng),
            destination: new google.maps.LatLng(destination.lat, destination.lng),
            travelMode: google.maps.TravelMode.DRIVING,
            waypoints : waypoints,
            optimizeWaypoints:true
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              this.setState({
                newWaypointOrder: result.routes[0].waypoint_order
              });
              this.orderItems();
            } else {
                let errorText = status;
                this.setState({errorText:errorText, isSearch: false}); 
            }
          }
        );
    }
    //the Directions Api returns an array of indexes where waypoints were before the optimization newWaypointsOrder  
    orderItems = () => {
        let items = JSON.parse(JSON.stringify(this.state.items)); 
        var finalItems = JSON.parse(JSON.stringify(this.state.items)); 
        var i;
        for (i = 1; i < items.length-1 ; i++) {
            finalItems[i] = items[this.state.newWaypointOrder[i-1]+1]
        }
        this.setState({
            items : finalItems,
            isSearch: false,
            isOptimized : true
        })
    };

    handleChangeDestination(result) {
        const items = this.state.items
        if ((result.source.index == items.length-1) || (result.destination.index == items.length-1) ) {
                this.optimizeStops()
        }else {
            this.setState ({
                isOptimized: false
            })
        };
    };

    render() {
        const { isSearch, shop, errorText, alertText, textSearch, successText, reloadMap, shopAddresses, step, quotaLocal, ServiceTypes, items, label, newWaypointOrder, isOptimized } = this.state;
        const { shopState } = this.props;

        console.log(newWaypointOrder);
        console.log(items);
        return (

            <div className="ordens local-orders">
                <h1>
                    Ordenes locales

                    <button 
                        onClick={() => this.printAccuse()} 
                        className="btn triciclo top-middle right print" 
                        disabled={(items && items.length > 0 && step === 2 && label.length && reloadMap) > 0 ? false : true}
                        >
                        <i className="icofont-printer"></i>&nbsp;
                        Imprimir acuse de recibido
                    </button>
                </h1>
                <div className="content-widget list-orders">
                    <div className="content-flex space-between-start container-map">
                        <div className="items">
                            <div className="content-flex flex-center-left spacing-small-left height-70">
                                <label>Origen:&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                {shop && (
                                    <SelectHtml edit={step === 0} options={shop.addresses} required={true} onChange={this.handleChangeAddress} name="shopAddresses" defaultText={`Principal: ${Util.getDataShop(shop, shop.addresses, shopAddresses, "street1")}, ${Util.getDataShop(shop, shop.addresses, shopAddresses, "city")}, ${Util.getDataShop(shop, shop.addresses, shopAddresses, "zip")}  `} defaultValue={-1} value={shopAddresses} keyValue={Constants.addressSelect}/>
                                )}
                            </div>
                            <div className="content-flex flex-center-left spacing-small-left height-70">
                                <label>Vehículo:&nbsp;</label>
                                <SelectHtml edit={step === 0} options={Util.LALAMOVE} onChange={this.handleClickPackage} name="layout_id" value={ServiceTypes}/>
                            </div>
                            <div className="items-dd">
                            <Alert  infoText={"Para optimizar tu ruta de entrega, selecciona el destino final arrastrando hacia abajo la orden correspondiente."}link={"http://www.triciclo.mx/lalamove"}linkText={"¿Como funciona?"} />
                                <div className="item-order fix">
                                    <div className="number">
                                        1
                                    </div>
                                    {items.length > 0 && (
                                        <div className="address">
                                            <LocalGetShipping address={items[0].street1} address2={items[0].street2} name={items[0].firstName + " " +items[0].lastName} phone={items[0].phone} city={items[0].city} zip={items[0].zipCode} />
                                        </div>
                                    )}

                                </div>
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        title="Arrastra para reordenar"
                                        className="item-dd"
                                        >
                                        {items.map((item, index) => (
                                            <React.Fragment>
                                                {index > 0 && index < items.length-1 && (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                        
                                                        >
                                                            <div className="item-order">
                                                                <div className="number">
                                                                    {index+1}
                                                                </div>
                                                                <div className="address">
                                                                    <LocalGetShipping address={item.street1} address2={item.street2} name={item.firstName + " " +item.lastName} phone={item.phone} city={item.city}  zip={item.zipCode}  />
                                                                </div>
                                                                <div className="icon-delete" title="Eliminar" onClick={() => this.delete(item)}>
                                                                    <i className="icofont-close-squared-alt"></i>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )}
                                                    </Draggable>
                                                )}
                                                {index > 0 && index == items.length-1 && (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                        
                                                        >
                                                            <div className="item-order fix">
                                                                <div className="number">
                                                                    {index+1}
                                                                </div>
                                                                <div className="address">
                                                                    <LocalGetShipping address={item.street1} address2={item.street2} name={item.firstName + " " +item.lastName} phone={item.phone} city={item.city}  zip={item.zipCode}  />
                                                                </div>
                                                                <div className="icon-delete" title="Eliminar" onClick={() => this.delete(item)}>
                                                                    <i className="icofont-close-squared-alt"></i>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )}
                                                    </Draggable>
                                                )}

                                            </React.Fragment>
                                        ))}
                                        {provided.placeholder}
                                        </div>
                                    )}
                                    </Droppable>
                                </DragDropContext>
                            </div> 
                            <div className="cost">
                                <div>
                                    Subtotal:&nbsp;
                                    {isSearch ? (
                                        <span><small>Calculando</small></span>
                                    ) : (quotaLocal && quotaLocal.length > 0 && quotaLocal[0].total) ? (
                                        <CurrencyFormat value={(quotaLocal[0].total  - Util.CARRIERS_INFO["LALAMOVE"]["fixedCommission"])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    ) : (
                                        <span>--</span>
                                    )}
                                </div>
                                <div>
                                    Cargo por Servicio:&nbsp;
                                    {isSearch ? (
                                        <span><small>Calculando</small></span>
                                    ) : (quotaLocal && quotaLocal.length > 0 && quotaLocal[0].total) ? (
                                        <CurrencyFormat value={Util.CARRIERS_INFO["LALAMOVE"]["fixedCommission"]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    ) : (
                                        <span>--</span>
                                    )}
                                </div>
                                <div>
                                    <b>Total:&nbsp;
                                    {isSearch ? (
                                        <span><small>Calculando</small></span>
                                    ) : (quotaLocal && quotaLocal.length > 0 && quotaLocal[0].total) ? (
                                        <CurrencyFormat value={quotaLocal[0].total} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    ) : (
                                        <span>--</span>
                                    )}</b>
                                </div>
                                <div>
                                    <small>
                                        Costo promedio por parada:&nbsp;
                                        {isSearch ? (
                                            <span><small>Calculando</small></span>
                                        ) : (quotaLocal && quotaLocal.length > 0 && quotaLocal[0].total) ? (
                                            <CurrencyFormat value={(quotaLocal[0].total / (items.length-1))} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        ) : (
                                            <span>--</span>
                                        )}
                                    </small>
                                </div>
                            </div>
                            <div className="content-flex flex-end">
                                {step === 0 ? (
                                    <button 
                                        className="btn triciclo top-middle" 
                                        disabled={(ServiceTypes === "" || isSearch || items.length <= 1) ? true : false} 
                                        onClick={() => this.getQuotion()}
                                        >
                                            Calcular costo
                                    </button>
                                ) : step === 1 && (
                                    <React.Fragment>
                                        <button 
                                            className="btn btn-link" 
                                            onClick={() => this.setState({step: 0})}
                                        >
                                            Volver
                                        </button>
                                        
                                        {quotaLocal && quotaLocal.length > 0 && quotaLocal[0].total >= shopState[0].quota ? ( 
                                            <Alert errorText="Saldo insuficiente" />
                                        ) : (
                                            <button 
                                                className="btn triciclo top-middle" 
                                                disabled={(!(quotaLocal && quotaLocal.length > 0) || isSearch) ? true : true} 
                                                onClick={() => this.getShippings()}
                                                >
                                                    Generar Guía
                                            </button>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="content-alert">
                                <Alert errorText={errorText} warningText={alertText} successText={successText} /> 
                            </div>
                        {(items && items.length > 0 && step !== 2 && reloadMap) ? (
                            <LocalMap points={items} setPoints={this.setPoints}/>
                        ) : (items && items.length > 0 && step === 2 && label.length && reloadMap) > 0 ? (
                            <iframe id="shippingspdf" src={`${label[0].trackerUrl}#view=FitH`} style={{width:"100%", height: "70vh"}}/>   
                        ) : !reloadMap && (
                            <div className="center container-loading">
                                <div className="loading content-flex flex-center">
                                    <div id="preloader">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div> 
                                    <span>{textSearch}</span>
                                </div>    
                            </div>
                        )}
                        </div>
                    </div>
                </div>            
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    ordens: state.ordens,
    packageListProps: state.packageList,
    shopState: state.shop,
    config: state.config,
    user: state.user
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalOrders);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import  * as Util from './../data/General';
import axios from "axios";
import * as Constants from './../store/index';


class Accuse extends Component {

    state = {
        isSearch: false,
        isScroll: false,
        packageList:null,
        carrier: null,
        carrierKey: "",
        list_shippings: null,
        step: 0,
        totalQuotion: 0,
        shopAddresses: -1,
        shop: null,
        processed: true,
        count_processed: false,
    };

    componentDidMount() {
        const { shippings, shopState, ordens, type } = this.props;
        
        let shop = shopState[0];
        let list_shippings= [];
        if (type !== "special"){ 
            if (type === "shipping"){
                shippings.list.map((order)=>{
                    if (order.selected){
                        if (order.customerId !== 0){
                            shop = Util.getShop(shopState, order.customerId);
                        }
                        list_shippings.push(order);
                    }
                });
            }else{
                ordens.list.map((order)=>{
                    if (order.selected){
                        if (order.customerId !== 0){
                            shop = Util.getShop(shopState, order.customerId);
                        }
                        list_shippings.push(order);
                    }
                });
            }

            if(shop === null){
                shop = shopState[0]; 
            }
        
            this.setState({ list_shippings: list_shippings, shop: shop});
            Util.printPage();
        }else{
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const tracker = urlParams.get('id');
            const url = Constants.URL_API+"shippings?tracker=" + tracker+"&from=0&size=150";
            console.log(url);
            axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
                Constants.setErrors(error);
                return error;
            }).then((data) => {
                console.log(data);
                shop = shopState[0];
                this.setState({ list_shippings: data, shop: shop});
                Util.printPage();
            }); 
        }
    }

  
   
    render() {
        const { today } = this.props;
        const { isSearch, isScroll, packageList, carrierKey, list_shippings, step, totalQuotion, processed, shop } = this.state;
        return (
            
            <div className="print-page">
                    
                    <div >
                    {list_shippings && list_shippings.length > 0 && processed ? (
                        <div >
                            <div className="header-print">
                                <div><b>{shop.name}</b></div>
                                <div>{shop.country} {shop.state} {shop.zip}</div>
                                <div>{shop.myshopifyDomain}</div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th className="center">#pedido</th>
                                        <th>Nombre</th>
                                        <th className="max-width-15">Dirección</th>
                                        <th>Telefono</th>
                                        <th>Transportista</th>
                                        <th>#Guía</th>
                                        <th className="center">Firma</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list_shippings.map((shipping, index) => (
                                        <tr key={index}>
                                            <td>{today}</td>
                                            <td className="center">{shipping.orderName}</td>
                                            {shipping.destination ? (
                                                <React.Fragment>
                                                    <td>
                                                        {shipping.destination.firstName} <br></br>{shipping.destination.lastName}
                                                    </td>
                                                    <td className="max-width-15">
                                                        {shipping.destination.street1} {shipping.destination.street2}{shipping.destination.city}
                                                    </td>
                                                    <td>
                                                        {shipping.destination.phone}
                                                    </td>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <td>
                                                        {shipping.shippingAddress.firstName} <br></br>{shipping.shippingAddress.lastName}
                                                    </td>
                                                    <td className="max-width-15">
                                                        {shipping.shippingAddress.address1} {shipping.shippingAddress.address2}{shipping.shippingAddress.city}
                                                    </td>
                                                    <td>
                                                        {shipping.shippingAddress.phone}
                                                    </td>
                                                </React.Fragment>
                                            )}
                                            <td>
                                                {shipping.carrierId && Util.CARRIERS_INFO[shipping.carrierId].name}
                                            </td>
                                            <td>
                                                {shipping.tracker}
                                            </td>
                                            <td>
                                                <div className="signature"></div>
                                            </td>
                                        </tr>
                                                    
                                    ))}
                                </tbody>
                            </table>
                         </div>   
                    ) : (!processed) ? (
                        <div className="center content-loading">
                            <div className="loading">
                                <div id="preloader">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div> 
                                <span>Procesando</span>
                            </div>    
                        </div>
                    ) : (
                        <div className="no-data">
                            <div className="content-flex flex-center">
                                <i className="icofont-gears"></i>
                            </div>    
                            <div className="content-flex flex-center">
                                <h2>Buscando</h2>
                            </div>      
                        </div>
                    )
                }
                </div>
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    shippings: state.shippings,
    shopState: state.shop,
    config: state.config,
    today: state.today,
    ordens: state.ordens,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accuse);

import React, { Component } from 'react';
import GetName from './GetName';


class GetUser extends Component {

    render() {
        const { customer } = this.props;

        return (
            <React.Fragment>
                <i className="icofont-user"></i> {!customer && "Ningún cliente"}<GetName customer={customer} />
                <div className="destination orden-info">
                    {customer && customer.email && customer.email}
                </div>
                <div className="destination orden-info">
                    {customer && customer.phone && customer.phone}
                </div>
            </React.Fragment>     
        );
  }
}

export default GetUser;